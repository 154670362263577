<template>
    <div style="min-height: 90vh" class="pa-2">
      <v-flex class="pa-2" xs12>
        <v-text-field :hint="`${dados.length} dense encontrados.`" v-model="pesquisa" dense outlined="" label="Faça uma busca" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
    <v-flex xs12>
        <v-simple-table v-if="dados.length > 0" >
            <template v-slot:default>
                <thead>
                    <tr>
                        <th><v-icon :color="$primaria">receipt</v-icon></th>
                        <th>Data</th>
                        <th>Link</th>
                        <!-- <th>Excluir</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in dados" :key="index">
                        <td style="width: 20px" > <v-icon :color="$primaria" >receipt</v-icon> </td>
                        <td class="font-weight-bold fonteCorpo">{{ item.documento }}</td>
                        <td class="font-weight-bold fonteCorpo"> <a :href="item.url" target="_blank"> Ver </a> </td>
                        <!-- <td class="font-weight-bold fonteCorpo"> <a @click="removeDoc(item._id, false)"> Excluir </a> </td> -->
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <Empty v-else />
        </v-flex>
    </div>
</template>

<script>
export default {
    props: ['cliente_id'],
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'add',
                nome: 'Nova O.S',
                action: () => this.$router.push('/home/criar-ordem')    
            }
        ],
        dados: [],
        dadosbkp: []
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  methods: {
    async get() {
      const data = await this.$list('cliente_documento/'+this.cliente_id)
      this.dados = data
      this.dadosbkp = data
    },
    async remover(id) {
      await this.$del(`ordem/${id}`)
      await this.get()
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.cliente.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    }
  },
  async created() {
      await this.get()
  }
}
</script>