<template>
  <v-app style="background: #fff;">
    <SnackbarSuccess />
    <SnackbarError />
    <SnackbarWarning />
    <SnackbarInfo />
    <SnackbarCharging />
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  methods: {
    async accept() {
      this.showUpgradeUI = false
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  }
}
</script>

<style>
  .bg-table {
    background: #fdfafa;
  }
  .item-table {
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .semQuebra {
    flex-wrap: nowrap
  }

  .coluna {
    flex-direction: column
  }

  .expande-horizontal {
    display: flex;
    width: 100%;
  }

  .expande-total {
    display: flex;
    width: 100%;
    min-height: 100vh
  }
  
  .centraliza {
    justify-content: center;
    align-items: center;
  }

 .content {
  display: flex;
  width: 100%;
  justify-content: center;
}

.bg {
  display: flex;
  flex-direction: column;
  background-image: rgb(173, 52, 52);
}

.bg-selected {
  background: #333;
}

.comquebra {
  flex-wrap: wrap;
}

.menuAtivo {
  background: linear-gradient(to right, #fc4a1a, #f7b733);
  /*background: linear-gradient(45deg, #b31217 30%, #e52d27 90%); */
  border-radius: 3px;
  color: white;
  height: 48px;
  box-shadow: 0 3px 5px 2px rgba(28, 29, 28, 0.1);
}

.menuAtivoHome {
   background: linear-gradient(to right, #fc4a1a, #f7b733);
  /*background: linear-gradient(45deg, #b31217 30%, #e52d27 90%); */
  border-radius: 3px;
  color: white;
  height: 48px;
  box-shadow: 0 3px 5px 2px rgba(14, 13, 13, 0.1);
}

.menuAtivoHomeCliente {
   background: linear-gradient(to right, #2f80ed, #56ccf2);
  /*background: linear-gradient(45deg, #b31217 30%, #e52d27 90%); */
  border-radius: 3px;
  color: white;
  height: 48px;
  box-shadow: 0 3px 5px 2px rgba(14, 13, 13, 0.1);
}

.fonteAtiva {
  color: #fff
}

.router-anim-enter-active {
  animation: coming 0.4s;
  animation-delay: 0.6s;
  opacity: 0;
}

.router-anim-leave-active {
  animation: going 0.4s;
}

@keyframes going {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
.HomePublic {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(48, 48, 48);
}
.fonteCorpo {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
}
.fonteLogin {
  font-family: nasalization, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.fonteTitulo {
  font-family: 'Poiret One', cursive;
  font-weight: bold;
  font-size: 20pt
}
.fonteCash {
  font-family: 'Signika', sans-serif;
  font-size: 28pt;
}

.topbar-chart { padding: 10px 0px; background: #e1e1e1; }
.topbar-chart .ccc-header-v3 { background: none !important; }
.topbar-chart .ccc-header-v3 .ccc-header-v3-price-value, .topbar-chart .ccc-header-v3 .ccc-header-v3-price-name { color: #111 !important; }
.topbar-chart.iq-chart{ margin-top: -6px; }
</style>