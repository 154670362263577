<template>
    <v-dialog persistent width="300" v-model="abrir">
        <v-card light class="pa-3" style="display: flex; flex-direction: column; width:100%;">
            <div style="display: flex; flex-direction: column; align-items: center; width:100%;">
                <v-form ref="form">
                    <v-text-field :color="$primaria" type="number" outlined label="Quantidade" v-model="quantidade"></v-text-field>
                    <v-autocomplete
                        class="mr-2 ml-2"
                        :color="$primaria"
                        outlined
                        v-model="dados"
                        :items="options"
                        label="Matéria prima"
                        return-object
                        placeholder="ex: Placa solar"
                        item-text="nome"
                        :rules="[v => !!v || 'Escolha um material']"
                    />
                </v-form>
                <div style="display: flex; width: 100%; justify-content: center; flex-wrap: nowrap">
                    <v-btn text class=" mr-2 white--text" @click="() => this.fechar()" color="red">Cancelar</v-btn>
                    <v-btn class="white--text" @click="add" color="green">Adicionar</v-btn>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['abrir', 'fechar', 'id'],
    data() {
        return {
            dados: {},
            quantidade: 0,
            options: []
        }
    },
    watch: {
        async abrir(val) {
            if(!!val) {
                this.get()
            }
        }
    },
    methods: {
        async get() {
            this.options = await this.$list('material')
        },
        async add() {
            if(this.$refs.form.validate()) {
                const dados = {
                    material: this.dados,
                    material_id: this.dados._id,
                    ordem_id: this.id,
                    quantidade_solicitada: this.quantidade
                }
                //id ordem
                await this.$create(`ordem_material/${this.id}`, dados)
                this.fechar()
            }
        }
    }
}
</script>