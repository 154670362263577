import Criar from '../../../components/home/financiamento/Criar'
import Listar  from '../../../components/home/financiamento/Listar.vue'
import Visualizar from '../../../components/home/financiamento/Visualizar.vue'

export const financiamento_home = [
    {
        path: '/home/criar-financiamento',
        component: Criar
    },
    {
        path: '/home/listar-financiamento',
        component: Listar
    },
    {
        path: '/home/visualizar-financiamento/:id',
        component: Visualizar,
        props: true
    },
]

export default {}