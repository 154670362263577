export const actionsPagamento = {
  abre_pagamento({ commit }) {
    commit('abre_pagamento');
  },

  fecha_pagamento({ commit }) {
    commit('fecha_pagamento');
  }
};

export default {};
