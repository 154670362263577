export const getters = {
  snackbar_success(state) {
    return state.snackbar_success;
  },
  snackbar_error(state) {
    return state.snackbar_error;
  },
  snackbar_warning(state) {
    return state.snackbar_warning;
  },
  snackbar_info(state) {
    return state.snackbar_info;
  },
  charging(state) {
    return state.charging.mostrar;
  },
  drawer(state) {
    return state.drawer
  }
};

export default {};
