<template>
    <div style="min-height: 90vh">
      <v-simple-table v-if="dados.length > 0" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon :color="$primaria">edit</v-icon></th>
              <th>Data</th>
              <th>Mensagem</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dados" :key="item._id">
              <td style="width: 20px"> <v-icon :color="$primaria">edit</v-icon> </td>
              <td style="width: 20px" class="font-weight-bold fonteCorpo grey--text">{{ $moment(item.created_at).format('DD/MM/YYYY') }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.mensagem }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-else ></Empty>
    </div>
</template>

<script>
export default {
  props: ['dados'],
  methods: {
    async remove(val) { await this.$del(`ordem_diario_bordo/${val._id}`) }
  }
}
</script>