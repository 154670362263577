// import Criar from '../../../components/cliente/perfil/Criar'
import Listar  from '../../../components/cliente/perfil/Listar.vue'
import Visualizar from '../../../components/cliente/perfil/Visualizar.vue'

export const perfil_cliente = [
    // {
    //     path: '/cliente/criar-perfil',
    //     component: Criar
    // },
    {
        path: '/cliente/listar-perfil/:id',
        component: Listar,
        props: true
    },
    {
        path: '/cliente/visualizar-perfil/:id',
        component: Visualizar,
        props: true
    },
]

export default {}