<template>
    <div style="min-height: 90vh">
      <v-simple-table v-if="dados.length > 0" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon :color="$primaria">build</v-icon></th>
              <th>NOME</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dados" :key="item._id">
              <td style="width: 20px"> <v-icon :color="$primaria">build</v-icon> </td>
              <td class="font-weight-bold fonteCorpo">{{ item.equipamento.nome }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-else ></Empty>
    </div>
</template>

<script>
export default {
  props: ['dados'],
  methods: {
    async remove(val) { await this.$del(`ordem_equipamento/${val._id}`) }
  }
}
</script>