<template>
    <div>
        <Molde title="Visualizar Cliente"  :options="options">
            <v-tabs :background-color="$primaria" dark v-model="tab">
                <v-tab>Dados Pessoais</v-tab>
                <v-tab>Ordens do Cliente</v-tab>
                <v-tab>Documentos Recebidos</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <div class="expande-horizontal">
                        <v-flex xs12 class="pa-3 ">
                            <v-form ref="form">
                                <v-flex xs12>
                                    <v-text-field 
                                        dense 
                                        :rules="[v => !!v || 'Preencha este campo']" 
                                        :color="$primaria" 
                                        class="mr-2 ml-2" 
                                        v-model="dados.nome" 
                                        outlined 
                                        required
                                        placeholder="ex: Diego Sarges"
                                        label="NOME"></v-text-field>
                                    
                                    <div class="expande-horizontal semquebra">

                                        <v-text-field
                                            dense
                                            :rules="[v => !!v || 'Preencha este campo']" 
                                            :color="$primaria" 
                                            class="mr-2 ml-2" v-model="dados.endereco" 
                                            outlined 
                                            required  
                                            label="ENDEREÇO COMPLETO" ></v-text-field>

                                    </div>

                                    <div class="expande-horizontal semquebra comquebra">
                                        <v-flex xs12 md3>
                                            <v-text-field 
                                            dense 
                                            :color="$primaria" 
                                            v-mask="['############']" 
                                            class="mr-2 ml-2" 
                                            v-model="dados.rg" 
                                            outlined 
                                            required  
                                            label="IDENTIDADE"></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 md4>
                                            <v-text-field 
                                            dense 
                                            :rules="[v => !!v || 'Preencha este campo']" 
                                            :color="$primaria" 
                                            v-mask="['###.###.###-##', '##.###.###/####-##']" 
                                            class="mr-2 ml-2" 
                                            v-model="dados.cpf_cnpj" 
                                            outlined 
                                            required  
                                            label="CPF ou CNPJ"></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 md5>
                                            <v-text-field 
                                            dense 
                                            :rules="[v => !!v || 'Preencha este campo']" 
                                            :color="$primaria"
                                            class="mr-2 ml-2" 
                                            v-model="dados.email" 
                                            outlined 
                                            required 
                                            prefix="@"
                                            label="EMAIL"></v-text-field>
                                        </v-flex>
                                    </div>
                                    
                                    <div class="expande-horizontal semquebra comquebra">
                                        <v-flex xs12 md4>
                                            <v-text-field 
                                            dense 
                                            :color="$primaria" 
                                            v-mask="['(##) # ####-####']" 
                                            class="mr-2 ml-2" 
                                            v-model="dados.telefone" 
                                            outlined 
                                            required  
                                            label="TELEFONE"></v-text-field>
                                        </v-flex>

                                        <v-flex xs12 md4 class="pr-1 pl-1">
                                    <money 
                                        style="width: 100%; height: 40px; border: 1px solid #999; border-radius: 3px; padding: 10px;"
                                        v-model="dados.faturamento_mensal"
                                        v-bind="money">
                                    </money>
                                    <!-- <v-text-field 
                                    dense 
                                    prefix="R$"
                                    :rules="[v => !!v || 'Preencha este campo']" 
                                    :color="$primaria" 
                                    v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##', '######,##', '#######,##', '########,##', '###.###.###,##', '#.###.###.###,##']" 
                                    class="mr-2 ml-2"
                                    v-model="dados.faturamento_mensal" 
                                    outlined 
                                    required  
                                    label="FATURAMENTO MENSAL"></v-text-field> -->
                                </v-flex>
                                
                                <v-flex xs12 md4 class="pr-1 pl-1">
                                    <money 
                                        style="width: 100%; height: 40px; border: 1px solid #999; border-radius: 3px; padding: 10px;"
                                        v-model="dados.patrimonio"
                                        v-bind="money2">
                                    </money>
                                    <!-- <v-text-field 
                                    dense 
                                    prefix="R$"
                                    :rules="[v => !!v || 'Preencha este campo']" 
                                    :color="$primaria" 
                                    v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##', '######,##', '#######,##', '########,##', '###.###.###,##', '#.###.###.###,##']" 
                                    class="mr-2 ml-2"
                                    v-model="dados.patrimonio" 
                                    outlined 
                                    required  
                                    label="PATRIMÔNIO"></v-text-field> -->
                                </v-flex>
                                    </div>
                                </v-flex>

                                <v-flex xs12>
                                    <div class="expande-horizontal coluna">
                                        <h3 class="text-center my-3">ESCANEAR DOCUMENTOS</h3>

                                        <div class="expande-horizontal centraliza comquebra">
                                            <v-flex xs12 md6 class="pa-2">
                                                <v-card :color="$primaria" class="pa-6">
                                                    <div class="expande-horizontal coluna centraliza">
                                                        <h3 class="white--text"> RG/FRENTE </h3>

                                                        <div class="arquivo_com_borda">
                                                            <v-img :src="dados.foto_rg_frente">
                                                                <a v-if="dados.foto_rg_frente" :href="dados.foto_rg_frente" target="_blank">
                                                                    <v-icon color="white">open_in_new</v-icon>
                                                                </a>
                                                            </v-img>
                                                        </div>

                                                        <v-btn @click="abre_envio_arquivo('foto_rg_frente')" block text outlined color="white" dark>Carregar arquivo</v-btn>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                            
                                            <v-flex xs12 md6 class="pa-2">
                                                <v-card :color="$primaria" class="pa-6">
                                                    <div class="expande-horizontal coluna centraliza">
                                                        <h3 class="white--text"> RG/VERSO </h3>
                                                        
                                                        <div class="arquivo_com_borda">
                                                            <v-img :src="dados.foto_rg_verso">
                                                                <a v-if="dados.foto_rg_verso" :href="dados.foto_rg_verso" target="_blank">
                                                                    <v-icon color="white">open_in_new</v-icon>
                                                                </a>
                                                            </v-img>
                                                        </div>

                                                        <v-btn @click="abre_envio_arquivo('foto_rg_verso')" block text outlined color="white" dark>Carregar arquivo</v-btn>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                        </div>
                                        
                                        <div class="expande-horizontal centraliza comquebra">
                                            <v-flex xs12 md6 class="pa-2">
                                                <v-card :color="$primaria" class="pa-6">
                                                    <div class="expande-horizontal coluna centraliza">
                                                        <h3 class="white--text"> DOC. EMPRESA </h3>
                                                        
                                                        <div class="arquivo_com_borda">
                                                            <v-img :src="dados.foto_doc_empresa">
                                                                <a v-if="dados.foto_doc_empresa" :href="dados.foto_doc_empresa" target="_blank">
                                                                    <v-icon color="white">open_in_new</v-icon>
                                                                </a>
                                                            </v-img>
                                                        </div>

                                                        <v-btn
                                                            @click="abre_envio_arquivo('foto_doc_empresa')" 
                                                            block 
                                                            text 
                                                            outlined 
                                                            color="white" 
                                                            dark>Carregar arquivo</v-btn>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                            
                                            <v-flex xs12 md6 class="pa-2">
                                                <v-card :color="$primaria" class="pa-6">
                                                    <div class="expande-horizontal coluna centraliza">
                                                        <h3 class="white--text"> CNPJ </h3>
                                                        
                                                        <div class="arquivo_com_borda">
                                                            <v-img :src="dados.foto_cnpj">
                                                                <a v-if="dados.foto_cnpj" :href="dados.foto_cnpj" target="_blank">
                                                                    <v-icon color="white">open_in_new</v-icon>
                                                                </a>
                                                            </v-img>
                                                        </div>

                                                        <v-btn @click="abre_envio_arquivo('foto_cnpj')" block text outlined color="white" dark>Carregar arquivo</v-btn>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                        </div>
                                    </div>
                                </v-flex>

                                <div class="expande-horizontal centraliza mt-6">
                                    <v-btn
                                        @click="save" 
                                        class=" white--text ml-2" 
                                        color="green">
                                            <span>Concluir Cadastro</span>
                                    </v-btn>
                                </div>


                            </v-form>
                        </v-flex>
                    </div>  
                </v-tab-item>

                <v-tab-item>
                    <Ordens :cliente_id="this.id" />
                </v-tab-item>
                
                <v-tab-item>
                    <Documento :cliente_id="this.id" />
                </v-tab-item>
            </v-tabs-items>
        </Molde>
        <EnvioArquivo :fechar="() => this.fechar_envio_arquivo()" :link_arquivo="(link) => recebe_link_arquivo(link)" :abrir="abrir_envio_arquivo" />

    </div>
</template>

<script>
import Ordens from './tab/Ordens'
import Documento from './tab/Documentos'
export default {
    props: ['id'],
    
    components: {
        Ordens,
        Documento
    },
    data() {
        return {
            tab: 0,
            abrir_envio_arquivo: false,
            documento_em_envio: false,
            options: [
                {
                    icon: "save",
                    nome: "Salvar Alterações",
                    action: () => this.save()
                }
            ],
            dados: {},
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'Fat. Mensal R$ ',
                precision: 2,
                masked: false
            },
            money2: {
                decimal: ',',
                thousands: '.',
                prefix: 'Patrimônio R$ ',
                precision: 2,
                masked: false
            }
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`cliente/${this.id}`, this.dados)
                this.$router.go(-1)
            }
        },
        abre_envio_arquivo(doc) {
            this.documento_em_envio = doc
            this.abrir_envio_arquivo = true
        },
        fechar_envio_arquivo() {
            this.documento_em_envio = false
            this.abrir_envio_arquivo = false
            this.$store.dispatch("down_charging")
        },
        recebe_link_arquivo(link_imagem) {
            this.dados[this.documento_em_envio] = link_imagem
            this.fechar_envio_arquivo()
        },
    },
    async created() {
        this.dados = await this.$show(`cliente/${this.id}`)
    }
}
</script>

