<template>
    <div>
        <Molde title="Resultado pós venda" icon="lock" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <v-flex xs12>
                            <v-textarea :rules="[v => !!v || 'Descreva aqui o resultado do pós venda']" :color="$primaria" class="mr-2 ml-2" v-model="dados.resultado" outlined required label="Resultado pós venda" />
                        </v-flex>
                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Finalizar pós venda <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    props: ['id'],
    directives: {
        mask
    },
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir",
                    action: () => this.save()
                }
            ],
            dados: {},
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`pos_venda/${this.id}`, this.dados)
                this.$router.go(-1)
            }
        }
    },
    async created() {
        this.dados = await this.$show(`pos_venda/${this.id}`)
    }
}
</script>

