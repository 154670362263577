<template>
    <div>
        <Molde title="Orçamento" icon="check" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <div v-if="false" class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.modalidade_tarifaria" outlined required label="Modalidade Tarifária" />
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-autocomplete item-text="nome" return-object v-model="cliente_selecionado" dense class="mr-2 ml-2" outlined :items="clientes" label="Cliente"></v-autocomplete>
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.cidade" outlined required label="Cidade" />
                        </div>
                       
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.irradiacao_solar" outlined required label="Irradiação Solar Anual" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.potencia_da_placa_em_watts" outlined required label="Potência da Placa em Watts" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-select v-model="dados.padrao_entrada" dense class="mr-2 ml-2" outlined :items="['Monofásico', 'Bifásico', 'Trifásico']" label="Padrão de entrada"></v-select>
                        </div>

                        <v-flex xs12>
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.unidade_consumidora" outlined required label="Unidade Consumidora" />
                        </v-flex>

                        <v-flex xs12>
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.descricao_unidade_consumidora" outlined required label="Descrição da Unidade Consumidora" />
                        </v-flex>
                        
                        <v-flex xs12>
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.consumo_mensal_kwh_da_casa" outlined required label="Consumo mensal em kw/h" />
                        </v-flex>
                        
                        <v-flex xs12>
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" prefix="R$" v-model="dados.preco_kwh" outlined required label="Preço do kw/h" />
                        </v-flex>

                        <div class="expande-horizontal semquebra">
                            <v-select dense class="mr-2 ml-2" outlined v-model="dados.tipo_de_instalacao" :items="['Residencial', 'Industrial', 'Rural']" label="Tipo de instalação"></v-select>
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-select dense class="mr-2 ml-2" outlined v-model="dados.chance_de_venda" :items="['Alta', 'Baixa', 'Não sei']" label="Qual a chance de fechar o negócio?"></v-select>
                        </div>
                        <div class="expande-horizontal semquebra">
                            <v-select dense class="mr-2 ml-2" outlined v-model="dados.tipo_de_estrutura" :items="['Cerâmico', 'Fibrocimento', 'Metálico', 'Laje', 'Solo']" label="Tipo de estrutura"></v-select>
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-text-field
                                dense 
                                :color="$primaria" 
                                class="mr-2 ml-2" 
                                v-model="dados.potencia_instalada_em_kwp" 
                                placeholder="" 
                                outlined 
                                required 
                                label="Potência Instalada em KWP" 
                            />
                        </div>


                        <!-- <div class="expande-horizontal semquebra">
                            <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.data" outlined required label="Dia que a ordem será executada" />
                            <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.hora" outlined required label="Hora" />
                            <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.contato" outlined required label="Contato do cliente" />
                        </div> -->

                        <div class="expande-horizontal semquebra">
                            <v-textarea dense :color="$primaria" class="mr-2 ml-2" v-model="dados.descricao" outlined required label="Mais detalhes do negócio" />
                        </div>

                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Calcular <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>
            <div class="pa-2" id="impressao" v-if="show_printer">
                <h1 class="ma-2">ORÇAMENTO</h1>
                <h3 class="ma-2 grey--text">{{ $moment().format('LLLL') }}</h3>

                <div style="margin-top:40px;"></div>

                <div>
                    <h1>{{ relatorio.quantidade_de_placas }} placas serão necessárias.</h1>
                </div>

                <h3 class="ma-2">DADOS DE ENTRADA</h3>
                <div>
                    <strong>CLIENTE:</strong>
                    <span> {{ dados.cliente.nome }} </span>
                </div>
                <div>
                    <strong>CIDADE:</strong>
                    <span> {{ dados.cidade }} </span>
                </div>

                <h3 class="ma-2">DETALHES DO ORÇAMENTO</h3>

                <div>
                    <strong>GERAÇÃO ANUAL DE :</strong>
                    <span> {{ dados.geracao_anual }} </span>
                </div>
                <div>
                    <strong>SISTEMA FOTOVOLTAICO COM UMA POTÊNCIA DE :</strong>
                    <span> {{ dados.potencia_instalada_em_kwp }} </span>
                </div>
                <div>
                    <strong>TIPO DE SUPERFÍCIE :</strong>
                    <span> {{ dados.tipo_de_estrutura }} </span>
                </div>
                <div>
                    <strong>QUANTIDADE DE PLACAS NECESSÁRIAS:</strong>
                    <span> {{ dados.quantidade_de_placas }} </span>
                </div>
                <div>
                    <strong>TIPO DE INSTALAÇÃO:</strong>
                    <span> {{ dados.tipo_de_instalacao }} </span>
                </div>
                <div>
                    <strong>UNIDADE CONSUMIDORA:</strong>
                    <span> {{ dados.unidade_consumidora }} </span>
                </div>
                <span style="margin-top: 10px;">Mais Detalhes: </span>
                <span>{{ dados.descricao }}</span>
            </div>
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import Printd from 'printd'

export default {
    props: ['id'],
    directives: {
        mask
    },
    data() {
        return {
            d: null,
            relatorio_ok: false,
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {
                cliente: {
                    nome: ''
                }
            },
            relatorio: {
                quantidade_de_placas: 0
            },
            clientes: [],
            cliente_selecionado: '',
            funcionario: '',
            responsavel: '',
            css: '',
            show_printer: false,
        }
    },
    watch: {
        cliente_selecionado(val) {
            this.dados.cliente = val
            this.dados.cliente_id = val._id
        },
        responsavel(val) {
            this.dados.responsavel = val
            this.dados.responsavel_id = val._id
        },
    },
    methods: {
        async save() {
            this.calc_relatorio()
            if(this.$refs.form.validate()) {
                await this.$update('orcamento', this.dados)
                this.$router.go(-1)
            }
        },
        calc_relatorio() {
            //resultados esperados
            let relatorio = {
                //dados de entrada
                geracao_anual: this.dados.geracao_anual,
                potencia_da_placa_em_watts: this.dados.potencia_da_placa_em_watts,
                preco_kwh: this.dados.preco_kwh,
                //processamento
                quantidade_de_placas: Math.ceil(parseFloat(this.dados.potencia_instalada_em_kwp)*1000/(parseFloat(this.dados.potencia_da_placa_em_watts))),
                superficie_necessaria_se_teto_inclinado: parseFloat(this.dados.potencia_da_placa_em_watts)*8
            }
            this.dados.relatorio = relatorio
            this.relatorio = relatorio
            this.imprime('sem')
        },
        imprime(id_origem) {
            this.show_printer = true
            setTimeout(() => this.envia_impressao(id_origem), 100)
        },
        envia_impressao(id_origem) {
            const self = this
            this.d.print( document.getElementById('impressao'), [this.css], [ '(()=> console.log(\'Hello world from IFrame!\'))()' ], ({ launchPrint }) => { launchPrint() })
            this.show_printer = false
        },
    },
    mounted() {
        this.d = new Printd()

        const { contentWindow } = this.d.getIFrame()

        contentWindow.addEventListener(
            'beforeprint', () => console.log('before print event!')
        )
        contentWindow.addEventListener(
            'afterprint', () => console.log('after print event!')
        )
    },
    async created() {
        this.clientes = await this.$list('cliente')
        this.funcionario = await this.$list('funcionario')
        this.dados = await this.$show(`orcamento/${this.id}`)
    }
}
</script>

