<template>
  <Molde title="Criar Ticket" icon="arrow_back" :options="options">
    <div class="pa-2">

        <div class="semQuebra expande-horizontal" >
            <v-flex class="pa-2" xs12>
                <v-text-field :color="$primaria" outlined label="Assunto" v-model="form.assunto"></v-text-field>
            </v-flex>
        </div>

        <div class="semQuebra expande-horizontal" >
            <v-flex  class="pa-2" xs12>
                <v-textarea :color="$primaria" outlined label="Conte-nos mais sobre sua dúvida ou problema..." v-model="form.mensagem"></v-textarea>
            </v-flex>
        </div>

        <div class="semQuebra expande-horizontal" >
            <v-flex  class="pa-2" xs12>
                <v-btn outlined color="green" @click="post">Enviar Ticket
                    <v-icon class="ml-1"> send </v-icon>
                </v-btn>
            </v-flex>
        </div>
    </div>
  </Molde>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					icon: 'send',
					nome: 'Enviar',
					action: () => this.post()  
				},
			],
			form: {
                assunto: '',
                mensagem: ''
			}
		}
	},
	methods: {
		post() {
			this.$store.dispatch('up_charging')
			this.$axios.post(`/ticket`, this.form)
				.then(() => {
                    this.$store.dispatch('down_charging')
                    this.$store.dispatch("snackbar_success", 'Ticket Enviado, confira no seu menu de tickets')
                    this.$router.push('/home/listar-ticket')
                })
				.catch(e => {
					this.$store.dispatch('snackbar_error', e.response.data.message)
				 	this.$store.dispatch('down_charging')
				 })
		}
	}
}
</script>