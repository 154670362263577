<template>
    <div>
        <Molde title="Nova Despesa" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <div class="expande-horizontal centraliza">
                            <v-flex xs12 md5>
                                <h3 class="fonteCorpo text-center">PREENCHA OS DADOS DA NOVA DESPESA</h3>
                                <div class="expande-horizontal centraliza item-table comquebra pa-3">
                                    <v-flex xs12 md12 class="px-1">
                                        <v-checkbox
                                            v-model="dados.mensal" 
                                            outlined 
                                            label="Esta despesa é mensal? (recorrente)"></v-checkbox>
                                    </v-flex>

                                    <!-- <v-flex v-if="dados.mensal" xs12>
                                        <v-text-field
                                            v-model="dados.numero_de_parcelas"
                                            type="number"
                                            prefix="x"
                                            outlined
                                            dense
                                            label="Informe o número de parcelas caso necessário"></v-text-field>
                                    </v-flex> -->

                                    <v-flex class="pa-1" xs12 md12>
                                        <v-select
                                            dense
                                            :color="$primaria"
                                            :items="tipo_de_despesa"
                                            v-model="categoria_selecionada"
                                            item-text="nome"
                                            :return-object="true"
                                            :rules="[v => !!v || 'Selecione um tipo']"
                                            outlined
                                            label="Tipo de Despesa"
                                        />
                                    </v-flex>
                                    
                                    <v-flex class="pa-1" xs12 md12>
                                        <v-text-field
                                            dense
                                            :color="$primaria"
                                            v-model="dados.nome"
                                            :rules="[v => !!v || 'Preencha aqui']"
                                            outlined
                                            label="Nome da despesa"
                                        />
                                    </v-flex>
                                    
                                    <v-flex class="pa-1" xs12 md12>
                                        <v-textarea
                                            dense
                                            :color="$primaria"
                                            v-model="dados.descricao"
                                            outlined
                                            label="Descrição"
                                        />
                                    </v-flex>

                                    <v-flex xs12 md6 class="pa-2 centraliza">
                                        <v-text-field
                                            type="Number"
                                            dense 
                                            :rules="[v => !!v || 'Preencha este campo']" 
                                            :color="$primaria" 
                                            v-model="dados.dia_do_vencimento" 
                                            outlined 
                                            required
                                            placeholder="ex: 1"
                                            label="Dia do vencimento"></v-text-field>
                                    </v-flex>
                                </div>
                            </v-flex>
                        </div>

                        <div class="expande-horizontal centraliza mt-6">
                            <v-btn
                                @click="save" 
                                class=" white--text ml-2" 
                                color="green">
                                    <span>Salvar Alterações</span>
                            </v-btn>
                        </div>
                    </v-form>
                </v-flex>
            </div>  
        </Molde>

    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {
                mensal: false
            },
            tipo_de_despesa: [],
            categoria_selecionada: {}
        }
    },
    watch: {
        categoria_selecionada(val) {
            this.dados.categoria = val
            this.dados.categoria_id = val._id
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$create('despesavariavel', this.dados)
                this.$router.push("/home/listar-despesa-variavel")
            }
        }
    },
    async created() {
        this.tipo_de_despesa = await this.$list("despesa-categoria")
    }
}
</script>

