<template lang="pug">
    Molde(
        title="Cadastrar Kit"
        :options="options"
        icon
    )
        div.expande-horizontal.centraliza
            v-flex.pa-3(xs12)
                v-form(ref="form")
                    v-flex(xs12)
                        div.expande-horizontal.coluna
                            v-flex(xs12 md4).px-1
                                v-text-field(
                                    v-model="dados.potencia_kwp"
                                    label="POTÊNCIA DO GERADOR"
                                    prefix="KWP: "
                                    type="Number"
                                    :color="$primaria"
                                    outlined
                                    dense
                                )
                            v-flex(xs12 md4).px-1
                                v-select(
                                    v-model="dados.estrutura_do_telhado"
                                    :items="['COLONIAL', 'FIBROCIMENTO (MADEIRA)', 'FIBROCIMENTO (METÁLICA)', 'ZINCO (METÁLICA)', 'SOLO']"
                                    label="Estrutura do telhado"
                                    :color="$primaria"
                                    outlined
                                    dense
                                )
                        
                        div.expande-horizontal.coluna.mt-3
                            v-flex(xs12 md4).px-1
                                v-text-field(
                                    v-model="dados.preco"
                                    label="PREÇO"
                                    prefix="R$"
                                    v-mask="['#,#', '##,##', '##,#', '###,##', '####,##', '#####,##', '######,##', '#######,##', '########,##']"
                                    :color="$primaria"
                                    :hint="`${$helper.formataSaldo(dados.preco)}`"
                                    persistent-hint
                                    outlined
                                    dense
                                )
                        
                        //- div.expande-horizontal.comquebra.mt-3
                        //-     v-flex(xs12 md4).px-1
                        //-         v-text-field(
                        //-             v-model="dados.comissao"
                        //-             label="COMISSÃO"
                        //-             prefix="R$"
                        //-             v-mask="['#,#', '##,##', '##,#', '###,##', '####,##', '#####,##', '######,##', '#######,##', '########,##']"
                        //-             :color="$primaria"
                        //-             :hint="`${$helper.formataSaldo(dados.comissao)}`"
                        //-             persistent-hint
                        //-             outlined
                        //-             dense
                        //-         )
                        div.expande-horizontal.coluna.comquebra.px-1.mb-3
                            h3.pa-2 ITENS
                            v-simple-table(dense class="item-table" style="min-width: 400px")
                                template(slot:default)
                                    thead
                                        tr
                                            th DESCRIÇÃO
                                            th QUANTIDADE
                                            th REMOVER
                                    tbody
                                        tr(v-for="(item, index) in dados.itens" :key="index" :class="index % 2 === 0 ? '' : 'bg-table'")
                                            td {{ item.descricao }}
                                            td {{ item.quantidade }}
                                            td
                                                v-btn(icon small @click="remover_item(index)" color="red")
                                                    v-icon delete
                        div.expande-horizontal.comquebra
                            v-form(ref="novo")
                                div.expande-horizontal.comquebra
                                    v-flex(xs12 md5).pa-1
                                        v-text-field(
                                            v-model="novo_item.descricao"
                                            :rules="[v => !!v || 'Preencha aqui']"
                                            label="Descrição"
                                            outlined
                                        )
                                    v-flex(xs12 md5).pa-1
                                        v-text-field(
                                            v-model="novo_item.quantidade"
                                            :rules="[v => !!v || 'Preencha aqui']"
                                            label="Quantidade"
                                            outlined
                                        )
                            v-flex(xs12 md6).pa-2
                                v-btn(@click="adicionar_item") ADICIONAR
                        
                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Concluir <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>
        </Molde>
    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {
                itens: []
            },
            novo_item: {}
        }
    },
    methods: {
        adicionar_item() {
            if(this.$refs.novo.validate()) {
                const item = this.novo_item
                this.novo_item = {}
                this.dados.itens.push(item)
            }
        },
        remover_item(index) {
            this.dados.itens = this.dados.itens.filter((item, i) => i !== index)
        },
        save() {
            if(this.$refs.form.validate()) {
                this.$store.dispatch('up_charging')
                this.$axios.post('/kit', this.dados)
                    .then(res => {
                        this.$store.dispatch('down_charging')
                        this.$store.dispatch('snackbar_success', 'cadastrado com sucesso!')
                        this.$router.go(-1)
                    })
                    .catch((e) => {
                        this.$store.dispatch('down_charging')
                        this.$store.dispatch('snackbar_error', e )
                    })
            }
        },
    }
}
</script>

