import Criar from '../../../components/home/unidade/Criar'
import Listar  from '../../../components/home/unidade/Listar.vue'
import Visualizar from '../../../components/home/unidade/Visualizar.vue'

export const unidade_home = [
    {
        path: '/home/criar-unidade',
        component: Criar
    },
    {
        path: '/home/listar-unidade',
        component: Listar
    },
    {
        path: '/home/visualizar-unidade/:id',
        component: Visualizar,
        props: true
    },
]

export default {}