<template>
  <v-dialog transition="slide-x-transition" fullscreen elevation-0 persistent style="overflow: hidden" v-model="abrir">
    <v-container style="background: rgba(0,0,0,0.5); min-height: 100vh" fluid>
        <v-layout justify-center>
            <v-flex xs12 md6>
                <v-card>
                    <v-card-title>
                        <v-flex xs12>
                            <div class="semQuebra expande-horizontal pr-2">
                            <v-btn small @click="fechar" icon>
                                <v-icon color="grey" size="20">arrow_back</v-icon>
                            </v-btn>
                            <span>envio de arquivo</span>
                            </div>
                        </v-flex>
                        <v-divider></v-divider>
                    </v-card-title>

                    <v-flex xs12>
                        <v-layout class="pa-3 bg-color">
                        <v-flex xs12>
                            <v-card color="transparent" class="elevation-0">
                            <v-layout class="bordered pa-3" column>
                                <form ref="form">
                                    <h4 style="font-size: 12pt" class="mt-4 white--text fonteTitulo"></h4>
                                    <input type="file" @change="uploadArquivo"/>
                                </form>
                            </v-layout>
                            </v-card>
                        </v-flex>
                        </v-layout>
                    </v-flex>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</v-dialog>
</template>

<script>
  import { storage } from '@/services/Firebase'
  
  export default {
    props: ['link_arquivo', 'fechar', 'abrir'],
    methods: {
      uploadArquivo(evt) {
        this.$store.dispatch('up_charging')
        evt.stopPropagation();
        evt.preventDefault();
        var file = evt.target.files[0];

        var metadata = {
          'contentType': file.type
        };

        const self = this
        storage.ref('images').child(file.name).put(file, metadata)
            .then(function(snapshot) {
                //console.log('Uploaded', snapshot.totalBytes, 'bytes.');
                //console.log('File metadata:', snapshot.metadata);
                // Let's get a download URL for the file.
                snapshot.ref.getDownloadURL().then(function(url) {
                    self.url = url
                    self.$refs.form.reset()
                    self.link_arquivo(url)
                });
            })
            .catch(function(error) {
                // [START onfailure]
                console.error('Upload failed:', error);
                // [END onfailure]
            });
      }
    }
  };
</script>