import Criar from '../../../components/home/carro/Criar'
import Listar  from '../../../components/home/carro/Listar.vue'
import Visualizar from '../../../components/home/carro/Visualizar.vue'

export const carro_home = [
    {
        path: '/home/criar-carro',
        component: Criar
    },
    {
        path: '/home/listar-carro',
        component: Listar
    },
    {
        path: '/home/visualizar-carro/:id',
        component: Visualizar,
        props: true
    },
]

export default {}