<template>
    <Molde title="Mapa">
        <div style="display: flex; height: 100vh">
            <MglMap zoom="3" :center="center" style="background-color: #555" :accessToken="accessToken" :mapStyle.sync="mapStyle">
                <div v-for="item in locations" :key="item['.key']">
                    <MglMarker v-if="item.dados.status === 'em andamento'" :coordinates="item.coordinates" color="#F9A825">
                        <MglPopup showed :coordinates="item.coordinates" anchor="top">
                            <div>
                                <v-btn class="mt-1" outlined @click="OSClick(item)" small color="green" >
                                    <v-icon color="green" size="20">mdi-eye</v-icon>
                                    <span>Visualizar</span>
                                </v-btn>

                                <v-btn class="mt-1" outlined color="green" small>
                                    <v-icon color="green" size="20">person</v-icon>
                                    <span> Responsável: {{ item.dados.responsavel.nome }} </span>
                                </v-btn>

                                <v-btn class="mt-1" outlined color="green" small>
                                    <v-icon color="green" size="20">person</v-icon>
                                    <span> Cliente: {{ item.dados.cliente.nome }} </span>
                                </v-btn>

                                <v-flex class="mt-5" xs12>
                                    
                                    <v-btn outlined @click="removeOS(item)" small color="red" >Excluir</v-btn>
                                </v-flex>
                            </div>
                        </MglPopup>
                    </MglMarker>
                </div>
            </MglMap>
        </div>
    </Molde>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglPopup } from "vue-mapbox";

export default {
    components: {
        MglMap, MglMarker, MglPopup
    },
    data() {
        return {
            accessToken: 'pk.eyJ1IjoicHRrbG9ycmFuIiwiYSI6ImNrM2JzbXRqZTBnZjkzbnFlM3VyYTVzazkifQ.kgDrdfqGfd6XTV5DXfeSwg', // your access token. Needed if you using Mapbox maps
            mapStyle:  'mapbox://styles/mapbox/dark-v10', // your map style
            pesquisa: '',
            options: [],
            mapbox: Mapbox,
            coordinates: [],
            locations: [],
            center: [-50.50, -20]
        }
    },
    firebase() {
        return {
            locations: this.$db.ref('locations')
        }
    },
    methods: {
        OSClick(e) {
            this.$router.push(`/home/visualizar-ordem/${e.dados._id}`)
        },
        removeOS(item) {
            this.$db.ref(`locations/${item['.key']}`).remove()
        }
    }
}
</script>