import Criar from '../../../components/home/despesa_fixa/Criar'
import Listar  from '../../../components/home/despesa_fixa/Listar.vue'
import Visualizar from '../../../components/home/despesa_fixa/Visualizar.vue'

export const despesa_fixa_home = [
    {
        path: '/home/criar-despesa-fixa',
        component: Criar
    },
    {
        path: '/home/listar-despesa-fixa',
        component: Listar
    },
    {
        path: '/home/visualizar-despesa-fixa/:id',
        component: Visualizar,
        props: true
    },
]

export default {}