<template>
    <div style="min-height: 90vh">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>HORA INÍCIO</th>
              <th>HORA ENCERRAMENTO</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-weight-bold fonteCorpo">{{ dados.hora_inicio }}</td>
              <td class="font-weight-bold fonteCorpo">{{ dados.hora_encerramento }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
</template>

<script>
export default {
  props: ['dados']
}
</script>