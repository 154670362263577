import Criar from '../../../components/home/ordem/Criar'
import Listar  from '../../../components/home/ordem/Listar.vue'
import Visualizar from '../../../components/home/ordem/Visualizar.vue'

export const ordem_home = [
    {
        path: '/home/criar-ordem',
        component: Criar
    },
    {
        path: '/home/listar-ordem',
        component: Listar
    },
    {
        path: '/home/visualizar-ordem/:id',
        component: Visualizar,
        props: true
    },
]

export default {}