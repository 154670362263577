
<template>
  <Molde :title="`${dados.assunto}: ${dados.mensagem}`" icon="arrow_back" :options="options">
    <div class="pa-0" style="backround: #333; display: flex; height: 100vh; width: 100%; flex-direction: row; flex-wrap: wrap" >
        <!-- <v-flex xs12 md6>
            <div class="semQuebra expande-horizontal" >
                <v-flex class="pa-2" xs12>
                    <v-text-field :color="$primaria" readonly outlined label="Assunto" v-model="dados.assunto"></v-text-field>
                    <v-text-field :color="$primaria" readonly outlined label="Cliente" v-model="dados.usuario.nome"></v-text-field>
                    <v-text-field :color="$primaria" readonly outlined label="Ticket" v-model="dados._id"></v-text-field>
                </v-flex>
            </div>

            <div class="semQuebra expande-horizontal" >
                <v-flex  class="pa-2" xs12>
                    <v-textarea :color="$primaria" readonly outlined label="Conte-nos mais sobre sua dúvida ou problema..." v-model="dados.mensagem"></v-textarea>
                </v-flex>
            </div>
        </v-flex> -->
        <v-flex :class="$vuetify.breakpoint.smAndDown ? 'pa-0': 'pa-0'" xs12 md6>
            <v-card style="border-radius: 0px;" dark>
                <!-- <div style="display: flex; width: 100%">
                    <v-list class="pa-0 ma-0">
                        <v-list-item @click="() => {}">
                            <v-avatar class="ml-2">
                                <v-icon color="blue">account_circle</v-icon>
                            </v-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="blue--text" >Atendimento</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div> -->
                <!-- <v-divider></v-divider> -->
                <div class="pr-2 chat_content" style="display: flex; width: 100%; height: 84vh; overflow: auto; flex-direction: column">
                    <v-timeline clipped align-top dense>
                        <v-timeline-item
                            v-for="(item, i) in mensagens"
                            :key="i"
                            :color="$store.getters.user._id === item.usuario._id ? $primaria : $chat"
                            icon="account_circle"
                            fill-dot
                        >
                            <span slot="opposite">{{ item.usuario.nome }}</span>
                            <v-card
                                :color="item.color"
                                light
                            >
                                <v-card-text class="white">
                                    <p class="fonteCorpo" style="font-size: 12pt">{{ item.mensagem }}</p>
                                </v-card-text>
                                <div style="display: flex; width: 100%; flex-wrap: row">
                                    <h1 class="ml-1 mr-1 fonteCorpo grey--text text-center" style="font-size: 8pt;">{{ item.usuario.nome }} - {{ $moment(item.data).format('llll') }}</h1>
                                </div>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </div>
                <div style="display: flex; width: 100%">
                    <v-text-field append-icon="send" @keydown.enter="responder" label="Nos envie uma mensagem" @click:append="responder" class="pr-2 pl-2" hint="Escreva a mensagem de resposta" v-model="mensagemResposta" outlined></v-text-field>
                </div>
            </v-card>
        </v-flex>
    </div>
  </Molde>
</template>

<script>
export default {
    props: ['id'],
	data() {
		return {
            mensagemResposta: '',
			options: [
				{
					icon: 'close',
					nome: 'Finalizar Ticket',
					action: () => this.finalizar()  
					//action: () => this.post()  
				},
			],
            dados: {
                assunto:'',
                mensagem: '',
                usuario: {
                    nome: 'carregando...'
                }
            },
            mensagens: []
		}
    },
    firebase() {
        return {
            mensagens: this.$db.ref(`/chat/${this.id}/messages`)
        }
    },
	methods: {
        scrollChat() {
            var chatContent = document.querySelector(".chat_content");
            chatContent.scrollTop = chatContent.scrollHeight;
        },
		get() {
			this.$store.dispatch('up_charging')
			this.$axios.get(`/ticket/${this.id}`)
				.then(res => {
                    this.$store.dispatch('down_charging')
                    this.dados = res.data
                    setTimeout(() => this.scrollChat(), 1000)
                })
				.catch(e => {
					this.$store.dispatch('snackbar_error', e.response.data.message)
				 	this.$store.dispatch('down_charging')
                 })
        },
        responder() {
            this.$db.ref(`/chat/${this.id}/messages`).push({
                usuario: {
                    _id: this.$store.getters.user._id,
                    nome: this.$store.getters.user.nome
                },
                mensagem: this.mensagemResposta,
                data: this.$time
            }).then(() => {
                    this.mensagemResposta = ''
                    this.scrollChat()
                })
            

        },
        finalizar() {
            this.$store.dispatch('up_charging')
			this.$axios.put(`/ticket/${this.id}`, { status: 'finalizado' })
				.then(res => {
                    this.$store.dispatch('down_charging')
                    this.$store.dispatch('snackbar_success', 'Ticket finalizado com sucesso!')
                    this.$router.push('/home/listar-ticket')
                })
				.catch(e => {
                    this.$store.dispatch('snackbar_error', e.response.data.message)
                    this.$store.dispatch('down_charging')
                })
        }
    },
    created() {
        this.get()
    },
    mounted() {
        setTimeout(() => this.scrollChat(), 200)
    }
}
</script>