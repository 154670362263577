<template>
  <Molde title="Meus Tickets" :options="options">
    <v-flex class="pl-3" xs12>
      <v-switch color="green" label="Pendentes" v-model="pendentes"></v-switch>
    </v-flex>
    <div>
      <v-list v-if="tickets.length > 0 && !$vuetify.breakpoint.smAndUp" nav class="pa-0 ma-0">
        <template v-for="(item, index) in tickets">
          <v-list-item @click="$router.push('/cliente/visualizar-ticket-cliente/'+item._id)" :key="item._id">
            <v-avatar>
              <v-icon :color="item.status !== 'pendente' ? '#56ab2f' : 'yellow darken-3'">chat</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title> {{ item.assunto }} </v-list-item-title>
              <v-list-item-subtitle> {{ item.mensagem }} </v-list-item-subtitle>
              <v-list-item-subtitle :class="item.status !== 'pendente' ? 'green--text' : 'orange--text'"> Situação: {{ item.status }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="index" ></v-divider>
        </template>
      </v-list>
      <v-simple-table id="todas" v-if="tickets.length > 0 && $vuetify.breakpoint.smAndUp" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon>chat</v-icon></th>
              <th>CHAVE</th>
              <th>ASSUNTO</th>
              <th>STATUS</th>
              <th>AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tickets" :key="item._id">
              <td style="width: 20px"> <v-icon :color="item.status !== 'pendente' ? '#56ab2f' : 'yellow darken-3'" >chat</v-icon> </td>
              <td style="width: 20px" class="font-weight-bold fonteCash">#{{ item._id }}</td>
              <td class="font-weight-bold fonteCash"> {{ item.assunto }}</td>
              <td class="font-weight-bold fonteCash">{{ item.status }}</td>
              <td class="font-weight-bold"> <v-btn color="yellow darken-3" text class="white--text" @click="$router.push('/cliente/visualizar-ticket-cliente/'+item._id)"> Visualizar </v-btn></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        options: [
            {
                icon: 'post_add',
                nome: 'Criar um Ticket',
                action: () => this.$router.push('/cliente/criar-ticket-cliente')    
            }
        ],
        tickets: [],
        ticketspendentes: [],
        ticketspendentes: false,
        pendentes: false
    }
  },
  watch: {
    pendentes(val) {
      if(val) {
        this.tickets = this.ticketspendentes
      } else {
        this.tickets = this.ticketsbkp
      }
    }
  },
  methods: {
    get() {
      this.$store.dispatch('up_charging')

      const self = this

      this.$axios.get("/ticket/usuario/self")
        .then(res => {
          this.tickets = res.data.reverse()
          this.ticketsbkp = res.data.reverse()
          let pendentes = res.data.filter(item => item.status === 'pendente')
          this.$store.dispatch('down_charging')
          self.setaTicketsPendentes(pendentes)
        })
        .catch(e => {
            this.$store.dispatch('down_charging')
            this.$store.dispatch('snackbar_error', e.response.data.message)
        })
    },
    setaSaquesPendentes(pendentes) {
      this.ticketspendentes = pendentes
    }
  },
  created() {
    this.get()
  }
}
</script>

<style>
  .status-pendente {
    color: rgb(255, 208, 0);
  }
  .status-pago {
    color: green;
  }
</style>