<template>
  <Molde title="Lista de Mensagens" :options="options" >
    <div style="min-height: 90vh" class="pa-6">
      <v-simple-table class="item-table" dense v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th>TITULO</th>
              <th>MENSAGEM</th>
              <th>REMETENTE</th>
              <th>DATA</th>
              <!-- <th> AÇÕES </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dados" :key="item._id" class="animated fadeInUp" :class="index % 2 === 0 ? 'bg-table' : '' ">
              <td class="font-weight-bold fonteCorpo">{{ verifica_enviada_recebida(item.funcionario_origem_id) }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.titulo }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.mensagem }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.remetente }}</td>
              <td class="font-weight-bold fonteCorpo">{{ $moment(item.created_at).format('LLLL') }}</td>
              <!-- <td> <v-btn outlined class="text-lowercase" :color="$primaria" @click="$router.push(`/home/visualizar-notificacao/${item._id}`)"> visualizar</v-btn> </td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <Empty v-if="dados.length === 0" ></Empty>

      <v-list class="pa-0 ma-0" three-line v-if="dados.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in dados">
          <v-list-item class="animated fadeInUp item-table" :key="item._id">
              <v-icon color="green" class="mr-3">
                {{verifica_enviada_recebida(item.funcionario_origem_id) === 'enviado' ? 'arrow_circle_up' : 'arrow_circle_down'}}
              </v-icon>
          <!-- <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-notificacao/${item._id}`)" :key="item._id"> -->
            <v-list-item-content>
              <v-list-item-title class="text-uppercase fonteCorpo" style="color: #666"> ASSUNTO: {{ item.titulo }} </v-list-item-title>
              <v-list-item-subtitle :style="`background: ${$primaria}; color: white;padding: 10px; border-radius: 3px;`" class="fonteCorpo"> Mensagem: {{ item.mensagem }} </v-list-item-subtitle>
              <!-- <v-list-item-subtitle class="fonteCorpo"> {{ item.origem }} </v-list-item-subtitle> -->
              <v-list-item-subtitle style="font-size: 9pt; margin-top: 6px;" class="fonteCorpo"> De: {{ item.remetente }}, {{ $moment(item.created_at).format('LLLL') }} </v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action> -->
          </v-list-item>
        </template>
      </v-list>
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        options: [
            {
                icon: 'add',
                nome: 'Enviar',
                action: () => this.$router.push('/home/criar-notificacao')    
            },
            {
                icon: 'replay',
                nome: 'Atualizar',
                action: () => this.get()  
            },
        ],
        dados: [],
    }
  },
  methods: {
    verifica_enviada_recebida(func_origem_id) {
      let tipo = ''

      if(func_origem_id === this.$store.getters.user._id) {
        tipo = 'enviado'
      } else {
        tipo = 'recebido'
      }

      return tipo
    },
    async get() {
      const data = await this.$list('notificacao')
      this.dados = data 
    },
  },
  async created() {
      await this.get()
  }
}
</script>