<template>
  <Molde title="Carros" :options="options" >
    <template slot="pesquisa_molde">
      <v-flex class="pt-6 px-2" md3>
        <v-text-field outlined dense flat :hint="`${dados.length} encontrados.`" v-model="pesquisa" label="Pesquise um carro" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
    </template>
    <div style="min-height: 90vh" class="pa-2">
      <v-flex v-if="$vuetify.breakpoint.smAndDown" xs12>
        <v-text-field dense :hint="`${dados.length} encontrados.`" v-model="pesquisa" outlined="" label="Pesquise um carro" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>

      <v-app-bar v-if="item_selecionado" class="elevation-1" color="#333" app dense clipped-left dark>
        <div class="expande-horizontal centraliza">
          <v-btn class="animated fadeInUp elevation-0" hover  small :color="$primaria">
            <span :style="`color: white`"> EXCLUIR {{ contagem_selecionados }} SELECIONADO(S) </span>
          </v-btn>
        </div>
      </v-app-bar>

      <v-simple-table dense class="item-table" v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th>NOME</th>
              <th>MODELO</th>
              <th>PLACA</th>
              <th>VER</th>
              <th>EXCLUIR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dados" :key="item._id" class="animated fadeInUp item-table" :class="index % 2 === 0 ? 'bg-table':'' ">
              <!-- <td style="width: 20px"> <v-icon :color="$primaria">directions_car</v-icon> </td> -->
              <td @input="verificaSelecionados($event)" style="width: 20px;" :class="item.selected ? 'bg-selected' : ''">
                <input type="checkbox" x-small color="white" v-model="dados[index].selected" />
              </td>

              <td class="font-weight-bold fonteCorpo">{{ item.nome }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.modelo }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.placa }}</td>
              <td> <v-btn small outlined class="text-lowercase" :color="$primaria" @click="$router.push(`/home/visualizar-carro/${item._id}`)"> visualizar</v-btn> </td>
              <td> <v-btn small outlined class="text-lowercase" @click.prevent="remover(item._id, false)"> Excluir</v-btn> </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-if="dados.length === 0" ></Empty>
      <v-list class="pa-0 ma-0" v-if="dados.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in dados">
          <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-carro/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonteCorpo" style="color: #444"> {{ item.nome }} - {{ item.placa }} </v-list-item-title>
              <v-list-item-subtitle class="fonteCorpo"> quilometragem: {{ item.quilometragem_atual }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
      <Confirm :open="confirm_dialog" :action="() => this.remover(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" />
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
      pesquisa: '',
      options: [
          {
              icon: 'directions_car',
              nome: 'Cadastrar',
              action: () => this.$router.push('/home/criar-carro')    
          }
      ],
      dados: [],
      dadosbkp: [],
      confirm_dialog: false,
      item_selecionado: false,
      contagem_selecionados: 0,
      id_to_excluse: ''
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  filters: {
      filterBlock(val) {
        if(parseInt(val) === 1) {
          return 'bloqueado'
        } else {
          return 'ativo'
        }
      },
      filterSaldo(val) {
        return parseFloat(val).toFixed(2)
      }
  },
  methods: {
    verificaSelecionados() {
      setTimeout(() => {
        let tem_item_selecionado = false
        let contagem = 0
        this.dados.map(item => {
          if(item.selected) {
            tem_item_selecionado = true
            contagem += 1
          }
        })
  
        this.item_selecionado = tem_item_selecionado
        this.contagem_selecionados = contagem
      }, 300)
    },
    async remover(id, confirmacao) {
      if(confirmacao) {
        await this.$del(`carro/${id}`)
        this.get()
      } else {
        this.confirm_dialog = true
        this.id_to_excluse = id
      }
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    },
    get() {
      this.$store.dispatch('up_charging')

      this.$axios.get("/carro")
        .then(res => {
          this.dados = res.data
          this.dadosbkp = res.data
          this.$store.dispatch('down_charging')
        })
        .catch(e => {
            this.$store.dispatch('down_charging')
            this.$store.dispatch('snackbar_info', e.response.data.message)
        })
    }
  },
  created() {
    this.get()
  }
}
</script>
