// import Criar from '../../../components/home/posvenda/Criar'
import Listar  from '../../../components/home/posvenda/Listar.vue'
import Visualizar from '../../../components/home/posvenda/Visualizar.vue'

export const posvenda_home = [
    // {
    //     path: '/home/criar-posvenda',
    //     component: Criar
    // },
    {
        path: '/home/listar-posvenda',
        component: Listar
    },
    {
        path: '/home/visualizar-posvenda/:id',
        component: Visualizar,
        props: true
    },
]

export default {}