<template>
    <div>
        <Molde title="Meu contrato" icon="description" :options="options">
            <v-tabs v-model="tab" :color="$primaria">
                <v-tab>Dados do contrato</v-tab>
                <v-tab @click="getDocs">Documentos</v-tab>
                <v-tab @click="getHomologacao">Homologação</v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items v-model="tab" :color="$primaria">
                <v-tab-item>
                    <div class="expande-horizontal">
                        <v-flex xs12 class="pa-3 ">
                            <v-form ref="form">
                                <div class="expande-horizontal semquebra">
                                    <v-text-field readonly :color="$primaria" class="mr-2 ml-2" v-model="dados.cliente.nome" outlined required label="Cliente" />
                                </div>
                                
                                <div class="expande-horizontal semquebra">
                                    <v-text-field readonly :color="$primaria" class="mr-2 ml-2" v-model="dados.responsavel.nome" outlined required label="Funcionário autor do contrato" />
                                </div>
                                
                                <v-flex xs12>
                                    <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.endereco" outlined required label="Endereço" />
                                </v-flex>

                                <div class="expande-horizontal semquebra">
                                    <v-text-field v-mask="['##/##/####']" :color="$primaria" class="mr-2 ml-2" v-model="dados.data" outlined required label="Data do contrato" />
                                    <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.contato" outlined required label="Contato do cliente" />
                                </div>

                                <div class="expande-horizontal semquebra">
                                    <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.valor" outlined required label="Valor total do contrato" />
                                </div>
                                
                                <div class="expande-horizontal semquebra">
                                    <v-text-field hint="ex: Débito" :color="$primaria" class="mr-2 ml-2" v-model="dados.metodo_pagamento" outlined required label="Método de pagamento" />
                                </div>
                                
                                <div class="expande-horizontal semquebra">
                                    <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.garantia" outlined required label="Anos garantia" />
                                </div>

                                <div class="expande-horizontal semquebra">
                                    <v-textarea :color="$primaria" class="mr-2 ml-2" v-model="dados.descricao" outlined required label="Descrição dos itens do contrato" />
                                </div>

                                <!-- <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Atualizar <v-icon>check</v-icon> </v-btn> -->
                            </v-form>
                        </v-flex>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <v-flex xs12>
                        <v-simple-table v-if="documento.length > 0" >
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th><v-icon :color="$primaria">receipt</v-icon></th>
                                    <th>Data</th>
                                    <th>Link</th>
                                    <th>Excluir</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in documento" :key="index">
                                    <td style="width: 20px" > <v-icon :color="$primaria" >receipt</v-icon> </td>
                                    <td class="font-weight-bold fonteCorpo">{{ item.documento }}</td>
                                    <td class="font-weight-bold fonteCorpo"> <a :href="item.url" target="_blank"> Ver </a> </td>
                                    <td class="font-weight-bold fonteCorpo"> <a @click="removeDoc(item._id)"> Excluir </a> </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                        <Empty v-else />
                    </v-flex>
                </v-tab-item>
                <v-tab-item class="pa-4">
                    <v-timeline>
                        <v-timeline-item
                            v-for="item in homologacao"
                            :key="item._id"
                            icon="check"
                            :color="$primaria"
                            v-show="item.cliente"
                        >
                            <v-card color="grey lighten-4" hover>
                                <v-card-title>
                                    <div class="expande-horizontal centraliza">
                                        <h5 class="text-center">{{ $moment(item.created_at).locale('pt-br').format('LLLL') }}</h5>
                                    </div>
                                </v-card-title>

                                <v-divider></v-divider>

                                <v-card-text style="min-height: 100px">
                                    <h3 class="fonteCorpo"> Andamento: {{ item.documento }} </h3>
                                </v-card-text>

                                <v-divider></v-divider>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </v-tab-item>
            </v-tabs-items>
        </Molde>
	    <AddDocumento :abrir="add_documento" :fechar="() => this.add_documento = false" :id="this.id" />
	    <AddHomologacaoEmpresa :abrir="add_homologacao_empresa" :fechar="() => { this.add_homologacao_empresa = false; this.getHomologacao() }" :id="this.id" />
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import AddDocumento from './add/Documento'
import AddHomologacaoEmpresa from './add/AddHomologacaoEmpresa'

export default {
    props: ['id'],
    components: {
        AddDocumento,
        AddHomologacaoEmpresa,
    },
    directives: {
        mask
    },
    data() {
        return {
            tab: 0,
            add_documento: false,
            add_homologacao_empresa: false,
            options: [
            ],
            dados: {},
            documento: [],
            cliente_selecionado: '',
            funcionario: '',
            responsavel: '',
            homologacao: []
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`contrato/${this.id}`, this.dados)
            }
        },
        async getDocs() {
            this.documento = await this.$list(`contrato_documento/${this.id}`)
        },
        async removeDoc(id) {
            await this.$del(`contrato_documento/${id}`)
            await this.getDocs()
        },
        async getHomologacao() {
            this.homologacao = await this.$list(`contrato_homo_empresa/${this.id}`)
        },
        async salvaHomologacao(item) {
            await this.$update(`contrato_homo_empresa/${item._id}`, item)
        }
    },
    async created() {
        this.dados = await this.$show(`contrato/${this.id}`)
    }
}
</script>

