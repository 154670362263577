import Criar from '../../../components/home/cliente/Criar'
import Listar  from '../../../components/home/cliente/Listar.vue'
import Visualizar from '../../../components/home/cliente/Visualizar.vue'

export const cliente_home = [
    {
        path: '/home/criar-cliente',
        component: Criar
    },
    {
        path: '/home/listar-cliente',
        component: Listar
    },
    {
        path: '/home/visualizar-cliente/:id',
        component: Visualizar,
        props: true
    },
]

export default {}