<template lang="pug">
    Molde(title="Enviar Mensagem")
        div.expande-horizontal
            v-form.expande-horizontal.coluna(ref="form")
                v-flex(xs12)
                    div.expande-horizontal
                        v-flex.pa-2(xs12 md6)
                            v-select(
                                v-model="dados.setor_id"
                                :items="setores" 
                                @input="seleciona_funcionarios_do_setor($event)"
                                item-text="nome"
                                item-value="_id"
                                outlined
                                dense
                                label="Qual setor?"
                                :rules="[v => !!v || 'Informe pelo menos o setor']" 
                            )
                        v-flex.pa-2(xs12 md6 v-if="funcionario_escolhido")
                            v-select(
                                v-model="dados.funcionario_destino_id"
                                :items="funcionarios_filtrados"
                                item-text="nome"
                                item-value="_id"
                                outlined 
                                dense
                                label="Funcionario?"
                                :persistent-hint="true"
                                hint="Deixe em branco para enviar à todos os funcionários do setor escolhido" 
                            )
                v-flex.pa-2(xs12)
                    v-text-field(
                        v-model="dados.titulo"
                        label="Título"
                        outlined
                        dense
                    )
                v-flex.pa-2(xs12)
                    v-textarea(
                        v-model="dados.mensagem"
                        label="Mensagem"
                        outlined
                        dense
                    )
                v-flex(xs12).pa-2
                    v-btn(
                        @click="post_notificacao"
                        :color="$primaria"
                        class="white--text"
                    )
                        span Enviar
                        v-icon(size="18") send
</template>

<script>
export default {
    data() {
        return {
            setores: [],
            funcionarios: [],
            funcionarios_filtrados: [],
            funcionario_escolhido: false,
            dados: {
                funcionario_destino_id: 'setor',
                remetente: this.$store.getters.user.nome,
                setor_id: '',
                titulo: '',
                mensagem: ''
            }
        }
    },
    methods: {
        async get_setores() {
            this.setores = await this.$list("permissao")
        },
        async get_funcionarios() {
            this.funcionarios = await this.$list("funcionario")
        },
        post_notificacao() {
            this.$store.dispatch('up_charging')

            this.$axios.post('/notificacao', this.dados)
                .then(res => {
                    this.$store.dispatch('down_charging')
                    this.$store.dispatch('snackbar_success', 'Mensagem Enviada!')
                })
                .catch(e => {
                    this.$store.dispatch('down_charging')
                    this.$store.dispatch('snackbar_info', 'Erro' + e)
                })
        },
        seleciona_funcionarios_do_setor(setor) {
            this.funcionarios_filtrados = this.funcionarios.filter(funcionario => funcionario.permissao_id === setor)
            this.funcionario_escolhido = true
        }
    },
    created() {
        this.get_setores()
        this.get_funcionarios()
    }
}
</script>