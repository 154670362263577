<template>
  <div
    class="principal"
  >
    <div
      class="HomePublic bg"
      style="flex-direction: column; justify-content: center; align-items: center;"
    >
      <v-card
        color="transparent"
        class="cardLogin elevation-0 fadeInUp ma-1 pr-7 pl-7"
        style="width: 530px; border-radius: 6px; opacity: 1"
      >
        <div class="animated" style="display: flex; width: 100%; justify-content: center">
          <v-avatar color="white" class="elevation-3" size="200" style="">
            <v-img
              :src="$logo"
              alt="logo"
            />
          </v-avatar>
        </div>
        <v-flex v-if="loginEmpresa" xs12 class="pa-2">
          <h3 class="fonteLogin text-center white--text mt-3 mb-5 "> Empresa </h3>
          <v-flex xs12>
            <v-text-field v-if="$app_config.login_type === 'cpf' ? true : false" filled autofocus dark autocomplete="new-login" color="white" v-model="email" v-mask="['###.###.###-##']" label="Informe seu cpf" />
            <v-text-field v-if="$app_config.login_type === 'nickname' ? true : false" filled autofocus dark autocomplete="new-login" color="white" v-model="email" label="Login" />
          </v-flex>
          
          <v-flex xs12>
            <v-text-field
              color="white"
              dark
              filled
              :append-icon="showPass ? 'visibility' : 'visibility_off'"
              :type="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              @keydown.enter="login"
              v-model="senha"
              label="Senha"
            />
          </v-flex>
          <v-flex xs12>
            <div class="expande-horizontal centraliza">
              <v-btn rounded text color="white" class="mr-2 white--text" @click="resetLogin">
                <v-icon class="mr-2">arrow_back</v-icon>
                <h4 class="fonteLogin">Voltar</h4>
              </v-btn>
              
              <v-btn color="green" class="white--text" @click="login">
                <h4 class="fonteLogin">Entrar</h4>
                <v-icon class="ml-2">work</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-flex>
        
        <v-flex v-if="loginCliente" xs12 class="pa-2">
          <h3 class="fonteLogin text-center white--text mt-3 mb-5"> Cliente </h3>
          <v-flex>
            <v-checkbox v-model="cnpj_on" color="white" dark label="É CNPJ? MARQUE AQUI"></v-checkbox>
          </v-flex>
          <v-flex xs12>
            <v-text-field solo v-if="!cnpj_on" filled autofocus autocomplete="new-login" color="white" v-model="cpf" label="CPF" v-mask="['###.###.###-##']" />
            <v-text-field solo v-else filled autofocus autocomplete="new-login" color="white" v-model="cpf" label="CNPJ" v-mask="['##.###.###/####-##']" />
          </v-flex>
          <v-flex xs12>
            <div class="expande-horizontal centraliza">
              <v-btn rounded text color="white" class="mr-2 white--text" @click="resetLogin">
                <v-icon class="mr-2">arrow_back</v-icon>
                <h4>Voltar</h4>
              </v-btn>

              <v-btn class="white--text" rounded :color="$primaria" @click="loginCli">
                <h4>Entrar</h4>
                <v-icon class="ml-2">account_circle</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-flex>

        <v-flex v-if="!loginEmpresa && !loginCliente">
          <div class="expande-horizontal centraliza coluna pb-4">
            <h2 class="text-center white--text mt-4 mb-4 white--text fonteLogin">Seja bem vindo(a) a Plataforma {{ $app_name }}, qual caminho deseja seguir?</h2>
            <div class="expande-horizontal coluna">
              <v-btn @click="loginCliente = true" large outlined color="white" class="ma-2 white--text fonteLogin" >Cliente
                <v-icon size="20" class="ml-2">account_circle</v-icon>
              </v-btn>
              <v-btn @click="loginEmpresa = true" large outlined color="white" class="ma-2 white--text fonteLogin" >Corporativo
                <v-icon size="20" class="ml-2">work</v-icon>
              </v-btn>
            </div>
          </div>
        </v-flex>
        
        <!-- <v-flex v-if="cordova_active">
          <div class="mt-6 expande-horizontal centraliza coluna pb-4">
            <v-card class="pa-3 elevation-3">
              <div v-if="type=== 'text'" class="expande-horizontal coluna">
                <h3 class="green--text">{{info}}</h3>
              </div>
              <div v-else>
                <canvas id="show-qrcode" ></canvas>
              </div>
            </v-card>
          </div>
          <h3 class="text-center white--text mt-4 mb-4 fonteLogin">Copie o código acima e envie para liberação.</h3>
          <v-btn block @click="verifica_a_liberacao">Verificar</v-btn>
        </v-flex> -->

      </v-card>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  directives: {
    mask
  },
  data() {
    return {
      cordova_active: false,
      info: '2',
      type: 'text',
      cnpj_on: false,
      random_um: 0,
      random_dois: 0,
      captcha: false,
      loginCliente: false,
      loginEmpresa: false,
      resultado: '',
      email: '',
      senha: "",
      cpf: "",
      showPass: false,
      entrando: false,
      error: false,
      errorMessage: "",
      alteraSenhaDialog: false,
      enviaLinkRecuperacao: false,
      carregandoLogin: false,
    };
  },
  methods: {
    verificaCaptcha() {
      const randoms = parseInt(this.random_dois) + parseInt(this.random_um)
      const res = parseInt(this.resultado)
      if(res === randoms) {
        this.captcha = true
      } else {
        this.$store.dispatch('snackbar_info', 'Captcha não confere, tente novamente')
      }
    },
    resetLogin() {
      this.loginCliente = false
      this.loginEmpresa = false
    },
    login() {
      this.$store.dispatch('up_charging')

      const dados = {
        email: this.email,
        senha: this.senha
      }

      this.$axios.post('/session', dados)
        .then((res) => this.posLogin(res.data))
        .catch(e => {
          this.$store.dispatch('snackbar_error', e.response.data.message)
          this.$store.dispatch('down_charging')
        })
    },
    loginCli() {
      this.$store.dispatch('up_charging')

      const dados = {
        cpf: this.cpf
      }

      this.$axios.post('/session_cli', dados)
        .then((res) => {
          sessionStorage.perfil = JSON.stringify(res.data)
          this.$router.push('/cliente/listar-contrato')
          location.reload()
        })
        .catch(e => {
          this.$store.dispatch('snackbar_error', e.response.data.message)
          this.$store.dispatch('down_charging')
        })
    },
    posLogin(perfil) {
      if(parseInt(perfil.user.block) === 1) {
        this.$store.dispatch('snackbar_info', 'Conta Bloqueada!')
        this.$store.dispatch('down_charging')
      } //else if(!perfil.user.conta_confirmada) {
        //this.$store.dispatch('snackbar_info', 'Esta conta ainda não está ativa em nossa plataforma, um email com o link de ativação da conta foi enviado para '+perfil.user.email)
        //this.$store.dispatch('down_charging')
      //} 
      else {
        sessionStorage.perfil = JSON.stringify(perfil)
        // this.$store.dispatch('set_token', perfil.token)
        // this.$store.dispatch('set_user', perfil.user)

        if(perfil.tipo === 'cliente') {
          this.$router.push('/cliente/listar-contrato')
        } else {
          this.$router.push('/home/listar-painel')
        }
        location.reload()
      }
    },
    enviaLinkRecuperacaoDeSenha() {
      // inserir lógica de recuperação de senha aqui
      this.$store.dispatch('up_charging')

      const dados = {
        email: this.email
      }

      this.$axios.post('/esqueci_senha', dados)
        .then((res) => {
          this.$store.dispatch('down_charging')
          this.$store.dispatch('snackbar_success', res.data.message)
          this.alteraSenhaDialog = false
        })
        .catch(e => {
          this.$store.dispatch('snackbar_error', e.response.data.message)
          this.$store.dispatch('down_charging')
        })
    },
    verifica_a_liberacao() {
      this.$store.dispatch("up_charging")
      this.$axios.post("/login-token", { token: this.info })
        .then(res => {
          this.$store.dispatch("down_charging")
          this.posLogin(res.data)
        })
        .catch(e => {
          this.$store.dispatch('down_charging')
          // this.$store.dispatch("snackbar_info", e)
        })
    },
    readQrcode(qrcode) {
      this.verifica_a_liberacao()
      var canvas = document.getElementById('show-qrcode')
      this.$qrcode.toCanvas(canvas, `${qrcode}`, (error) => console.log(error))
    },
    goToCadastro() {
      this.$router.push("/cadastro/sem");
    },
    isAvailableSuccess(result) {
      /*
      result depends on device and os. 
      iPhone X will return 'face' other Android or iOS devices will return 'finger'  
      */
      this.$store.dispatch('snackbar_success', result)
    },
    isAvailableError(error) {
      // 'error' will be an object with an error code and message
      this.$store.dispatch('snackbar_info', error.message)

    }
  },
  created() {
    const self = this
    this.cordova_active = cordova !== undefined ? true : false

    // cordova.plugins.firebase.messaging.requestPermission().then(function() {
        cordova.plugins.firebase.messaging.getToken().then(async function(token) {
            self.info = token
            self.readQrcode(token)
        })
    // })

    Fingerprint.isAvailable(this.isAvailableSuccess, this.isAvailableError);
  }
};
</script>

<style>
  .bg {
    background: linear-gradient(to right, #fdc830, #f37335);
  }
  .principal {
    background: linear-gradient(to right, #eba627, #f7f8f5);
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .fonte {
    font-family: "Crimson Text", serif;
    font-size: 25pt;
  }

  .loginForm {
    border-radius: 5px;
    display: flex;
    width: 100%;
    opacity: 0.9;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #fdfcfc;
  }

  .cardLogin {
    animation-duration: 400ms;
  }

.meio {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .snack {
    animation-duration: 600ms;
  }
</style>
