<template>
  <Molde title="Notificações">
    <div style="min-height: 90vh">
      <!-- <Empty v-if="notifications.length === 0" ></Empty> -->
      <v-divider></v-divider>
      <v-list :three-line="$vuetify.breakpoint.smAndDown" class="pa-0 ma-0" v-if="notifications.length > 0">
        <template v-for="(item, index) in notifications">
          <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-ordem/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-subtitle class="fonteCorpo" style="color: #444"> login: {{ item.message }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        notifications:[],
        options: []
    }
  },
  firebase() {
      return {
        notifications: this.$db.ref('notifications')
      }
  }
}
</script>