<template>
  <Molde title="Execução das O.S." :options="options" >
    <div style="min-height: 90vh">
      <v-flex class="pa-2" xs12>
        <v-text-field :hint="`${dados.length} encontrados.`" v-model="pesquisa" outlined="" label="Pesquise um nome" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
      <v-simple-table v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon :color="$primaria">work</v-icon></th>
              <th>CLIENTE</th>
              <th>INSTALADOR</th>
              <th>ENDEREÇO</th>
              <th>STATUS</th>
              <th>SOLICITAR VISTORIA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dados" :key="item._id" class="animated fadeInUp">
              <td style="width: 20px"> <v-icon :color="$primaria">work</v-icon> </td>
              <td @click="$router.push(`/home/visualizar-executar_os/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.cliente.nome }}</td>
              <td @click="$router.push(`/home/visualizar-executar_os/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.responsavel.nome }}</td>
              <td @click="$router.push(`/home/visualizar-executar_os/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.endereco }}</td>
              <td @click="$router.push(`/home/visualizar-executar_os/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.status }}</td>
              <td> <v-btn outlined class="text-lowercase" :color="$primaria" @click="solicitar_vistoria(item)"> Sol. Vistoria</v-btn> </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-if="dados.length === 0" ></Empty>
      <v-divider></v-divider>
      <v-list class="pa-0 ma-0" v-if="dados.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in dados">
          <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-executar_os/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonteCorpo" style="color: #444"> <v-icon :color="$primaria" size="12">person</v-icon> {{ item.cliente.nome }} </v-list-item-title>
              <v-list-item-subtitle class="fonteCorpo grey--text"> data: {{ item.data }} - {{ item.hora }} </v-list-item-subtitle>
              <v-list-item-subtitle class="fonteCorpo grey--text"> responsável: {{ item.responsavel.nome }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'update',
                nome: 'Atualizar',
                action: () => this.get()   
            }
        ],
        dados: [],
        dadosbkp: []
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  methods: {
    async get() {
      const data = await this.$list(`ordem/funcionario/${this.$store.getters.user._id}`)
      this.dados = data
      this.dadosbkp = data
    },
    solicitar_vistoria(item) {
      this.$store.dispatch("up_charging")
      this.$axios.put(`ordem/${item._id}`, { status: 'À VISTORIAR' })
        .then(() => {
          this.$store.dispatch("down_charging")
          this.get()
        })
        .catch(e => {
          this.$store.dispatch("down_charging")
          console.log(e)
        })
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.cliente.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    },
  },
  async created() {
      await this.get()
  }
}
</script>