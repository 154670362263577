import Vue from "vue";
import Vuex from "vuex";

// notifications
import Notifications from './modules/notifications'
import User from './modules/user'

import { statePagamento } from "./modules/pagamento/state";
import { actionsPagamento } from "./modules/pagamento/actions";
import { mutationsPagamento } from "./modules/pagamento/mutations";
import { gettersPagamento } from "./modules/pagamento/getters";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notifications: {
      state: {...Notifications.state},
      actions: {...Notifications.actions},
      mutations: {...Notifications.mutations},
      getters: {...Notifications.getters}
    },
    user: {
      state: {...User.state},
      actions: {...User.actions},
      mutations: {...User.mutations},
      getters: {...User.getters}
    },
    pagamento: {
      state: { ...statePagamento },
      actions: { ...actionsPagamento },
      mutations: { ...mutationsPagamento },
      getters: { ...gettersPagamento }
    }
  }
});
