<template>
    <div style="display: flex; min-height: 50vh;">
        <Molde title="Orçamento" icon="check" :options="options">
            <div class=" expande-horizontal elevation-0 ">
                <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="true"
                    filename="proposta"
                    :manual-pagination="true"
                    pdf-format="a4"
                    pdf-orientation="portrait"
            
                    @progress="() => {}"
                    @hasStartedGeneration="() => {}"
                    @hasGenerated="() => {}"
                    ref="html2Pdf"
                >
                    <div slot="pdf-content" style="padding: 0px;">
                        <section class="pdf-item coluna" style="display: flex; margin-top: 0px; pading-top: 0px; background: e2e2e2;">
                            <div style="padding: 48px;">
                                <h1 class="text-center" style="font-size: 18px; color: black;">
                                    PROPOSTA
                                </h1>
                                <h1 style="font-size: 27px; color: grey;">
                                    {{ projeto.unidade.nome.toUpperCase() }}
                                </h1>

                                <v-simple-table class="item-table" dense v-if="kit_selecionado.itens.length > 0" >
                                    <template v-slot:default>
                                        <tbody>
                                            <tr class="bg-table item-table">
                                            <td class="font-weight-bold fonteCorpo">{{ dados.cliente.nome }}</td>
                                            <td class="font-weight-bold fonteCorpo">{{ $moment().format('DD/MM/YYYY') }}</td>
                                            <!-- <td> <v-btn x-small text class="text-lowercase" color="red" @click="remover(item._id)"> <v-icon size="18">delete</v-icon> </v-btn> </td> -->
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <h3 style="margin-top: 40px; margin-bottom: 40px; text-align: 'center " class="pa-2 mt-6 mb-6 text-center">
                                    Segue abaixo os itens que compõe a Proposta de número {{ dados.id_contrato }} do Sistema Fotovoltaico "ON GRID" de potência nominal de {{ kit_selecionado.potencia_kwp }} kWp.
                                </h3>

                                <v-simple-table class="item-table" dense v-if="kit_selecionado.itens.length > 0">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                            <th>QUANTIDADE</th>
                                            <th>DESCRIÇÃO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in kit_selecionado.itens" :key="item._id" :class="index % 2 === 0 ? 'bg-table':'' ">
                                            <td class="font-weight-bold fonteCorpo">{{ item.quantidade }}</td>
                                            <td class="font-weight-bold fonteCorpo">{{ item.descricao }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <h1 class="bg-table item-table" style="padding: 12px;">VALOR TOTAL: {{ $helper.formataSaldo(kit_selecionado.preco) }}</h1>
                            </div>
                            
                            <div class="coluna centraliza expande-horizontal">
                                <span>________________________________________</span>
                                <span class="mb-12 text-uppercase">CLIENTE:  {{ dados.cliente.nome }}</span>
                                <span style="margin-top: 40px; margin-bottom: 20px;">
                                    Autorizo a realização de Análise de crédito para financiamento solar.
                                </span>
                            </div>
                            
                            <div style="align-items: center; display: flex; width: 100%; padding: 96px; height: 180px;">
                                <div style="display: flex; flex-direction: column">
                                    <img width="120" :src="$app_config.logo" alt="tess">
                                </div>

                                <div style="display: flex; flex-direction: column">
                                    <h3 class="fonteCorpo"> {{ projeto.unidade.nome }} </h3>
                                    <span class="fonteCorpo"> Referência Nacional em Energia Renovável. </span>
                                    <span class="fonteCorpo">   </span>
                                    <span class="fonteCorpo"> Norte Brasil Distribuição Comércio e Serviço EIRELI</span>
                                    <h3 class="fonteCorpo"> CNPJ 04.266.878/0001-24 </h3>
                                </div>
                                
                            </div>
                        </section>

                    </div>
                </vue-html2pdf>
            </div>
            
            <div class="expande-horizontal centraliza pa-2" style="display: flex; min-height: 95vh;">
                <v-flex xs12 md6 class="pa-6 item-table bg-table" v-if="tab===0">
                    <div class="expande-horizontal centraliza coluna">
                        <v-icon size="64" :color="$primaria">post_add</v-icon>
                        <h3 class="fonteCorpo mt-6 mb-6">Informe os Dados do Negócio</h3>
                    </div>
                    <v-form ref="tab_0">
                        <div class="expande-horizontal">
                            <v-flex class="px-1" xs12 >
                                <v-combobox
                                    v-model="cliente_selecionado"
                                    :items="clientes"
                                    item-text="nome"
                                    return-object
                                    label="CLIENTE"
                                    dense
                                    :color="$primaria"
                                    outlined
                                    :rules="[v => !!v || 'Preencha este campo']"
                                />
                            </v-flex>
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-flex md12 xs12>
                                <v-select dense class="mr-2 ml-2" outlined v-model="dados.tipo_de_estrutura" :items="['COLONIAL', 'FIBROCIMENTO (MADEIRA)', 'FIBROCIMENTO (METÁLICA)', 'ZINCO (METÁLICA)', 'SOLO']" label="Tipo de estrutura"></v-select>
                            </v-flex>
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-text-field
                                dense 
                                :color="$primaria" 
                                class="mr-2 ml-2" 
                                prefix="kWp: "
                                v-model="dados.potencia_instalada_em_kwp" 
                                placeholder="" 
                                outlined 
                                required 
                                label="Potência Instalada em KWP" 
                            />
                        </div>

                        <div class="expande-horizontal centraliza">
                            <v-btn class="white--text" @click="buscar_proposta" :color="$primaria">buscar</v-btn>
                        </div>
                        
                    </v-form>
                </v-flex>

                <v-flex xs12 md4 v-if="tab===1">
                    <v-form ref="tab_1">
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.potencia_da_placa_em_watts" outlined required label="Potência da Placa em Watts" />
                        </div>

                        <v-flex xs12>
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.descricao_unidade_consumidora" outlined required label="Descrição da Unidade Consumidora" />
                        </v-flex>

                        <div class="expande-horizontal semquebra">
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-select dense class="mr-2 ml-2" outlined v-model="dados.chance_de_venda" :items="['Alta', 'Baixa', 'Não sei']" label="Qual a chance de fechar o negócio?"></v-select>
                        </div>
                        

                        <div class="expande-horizontal semquebra">
                            <v-text-field
                                dense 
                                :color="$primaria" 
                                class="mr-2 ml-2" 
                                v-model="dados.potencia_instalada_em_kwp" 
                                placeholder="" 
                                outlined 
                                required 
                                label="Potência Instalada em KWP" 
                            />
                        </div>

                        
                    </v-form>
                </v-flex>
            </div>
        </Molde>
    </div>
</template>

<script>
import Printd from 'printd'

export default {
    data() {
        return {
            tab: 0,
            kits: [],
            d: null,
            relatorio_ok: false,
            projeto: {
                unidade: {
                    nome: 'NORTE BRASIL SOLAR'
                },
                responsavel: this.$store.getters.user
            },
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {
                responsavel: this.$store.getters.user,
                responsavel_Id: this.$store.getters.user._id,
                cliente: {
                    nome: ''
                }
            },
            relatorio: {
                quantidade_de_placas: 0
            },
            clientes: [],
            cliente_selecionado: '',
            funcionario: '',
            responsavel: '',
            css: '',
            show_printer: false,
            estado_selecionado: '',
            cidade_selecionada: '',
            kit_selecionado: {
                itens: []
            }
        }
    },
    watch: {
        cliente_selecionado(val) {
            this.dados.cliente = val
            this.dados.cliente_id = val._id
        },
        responsavel(val) {
            this.dados.responsavel = val
            this.dados.responsavel_id = val._id
        },
    },
    methods: {
        buscar_proposta() {
            const kits = this.kits
            const kits_por_telhado = kits.filter(item => item.estrutura_do_telhado === this.dados.tipo_de_estrutura)
            console.log("kitsss", kits_por_telhado)
            const valor = parseFloat(this.dados.potencia_instalada_em_kwp);

            if(kits_por_telhado.length > 1) {
                var maisProximo = kits_por_telhado.reduce(function(anterior, corrente) {
                    return (Math.abs(parseFloat(corrente.potencia_kwp) - valor) < Math.abs(parseFloat(anterior.potencia_kwp) - valor) ? corrente : anterior);
                });
            } else {
                var maisProximo = kits_por_telhado[0]  
            }

            this.kit_selecionado = maisProximo

            this.gera_pdf()

            // if(maisProximo.potencia_kwp) {
            //     this.$store.dispatch("up_charging")
            //     let dados = this.dados
            //     dados.status = 'PROPOSTA'
            //     dados.kit_selecionado = this.kit_selecionado
            //     this.$axios.post('/contrato', dados)
            //         .then(res => {
            //             this.dados = res.data
            //             this.$store.dispatch("down_charging")
            //             this.generateReport()
            //         })
            //         .catch(e => {
            //             this.$store.dispatch("down_charging")
            //             console.log("e", e)
            //         })
            // }
        },
        gera_pdf() {
            const maisProximo = this.kit_selecionado
            if(maisProximo.potencia_kwp) {
                this.$store.dispatch("up_charging")
                let dados = this.dados
                dados.status = 'PROPOSTA'
                dados.kit_selecionado = this.kit_selecionado
                dados.ano_referente = this.$moment().format('YYYY')
                dados.mes_referente = this.$moment().format('MM')
                dados.dia_referente = this.$moment().format('DD')
                this.$axios.post('/contrato', dados)
                    .then(res => {
                        this.dados = res.data
                        this.$store.dispatch("down_charging")
                        this.generateReport()
                    })
                    .catch(e => {
                        this.$store.dispatch("down_charging")
                        console.log("e", e)
                    })
            }
        },
        generateReport() {
            this.show_printer = true
            this.$refs.html2Pdf.generatePdf()
        },
        async save() {
            this.calc_relatorio()
            if(this.$refs.form.validate()) {
                await this.$create('orcamento', this.dados)
                this.$router.go(-1)
            }
        },
        calc_relatorio() {
            //resultados esperados
            let relatorio = {
                //dados de entrada
                geracao_anual: this.dados.geracao_anual,
                potencia_da_placa_em_watts: this.dados.potencia_da_placa_em_watts,
                preco_kwh: this.dados.preco_kwh,
                //processamento
                quantidade_de_placas: Math.ceil(parseFloat(this.dados.potencia_instalada_em_kwp)*1000/(parseFloat(this.dados.potencia_da_placa_em_watts))),
                superficie_necessaria_se_teto_inclinado: parseFloat(this.dados.potencia_da_placa_em_watts)*8
            }
            this.dados.relatorio = relatorio
            this.relatorio = relatorio
            this.imprime('sem')
        },
        imprime(id_origem) {
            this.show_printer = true
            setTimeout(() => this.envia_impressao(id_origem), 100)
        },
        envia_impressao(id_origem) {
            const self = this
            this.d.print( document.getElementById('impressao'), [this.css], [ '(()=> console.log(\'Hello world from IFrame!\'))()' ], ({ launchPrint }) => { launchPrint() })
            this.show_printer = false
        },
    },
    mounted() {
        this.d = new Printd()

        const { contentWindow } = this.d.getIFrame()

        contentWindow.addEventListener(
            'beforeprint', () => console.log('before print event!')
        )
        contentWindow.addEventListener(
            'afterprint', () => console.log('after print event!')
        )
    },
    async created() {
        this.clientes = await this.$list('cliente')
        this.funcionario = await this.$list('funcionario')
        this.kits = await this.$list('kit')
    }
}
</script>

