<template>
    <div>
        <Molde title="Nova O.S." icon="description" :options="options">
            <div class="expande-horizontal centraliza">
                <v-flex xs12 md6 class="pa-3 ">
                    <v-form ref="form">
                         <div class="expande-horizontal semquebra">
                            <v-autocomplete
                                dense
                                :rules="[v => !!v || 'Preencha aqui']"
                                class="mr-2 ml-2"
                                :color="$primaria"
                                outlined
                                v-model="responsavel"
                                :items="funcionario"
                                label="Selecionar Instalador"
                                return-object
                                placeholder="ex: Ricardo"
                                item-text="nome"
                            />
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-autocomplete
                                dense
                                :rules="[v => !!v || 'Selecione um cliente']"
                                class="mr-2 ml-2"
                                :color="$primaria"
                                outlined
                                v-model="cliente_selecionado"
                                :items="cliente"
                                label="Cliente"
                                return-object
                                placeholder="ex: Michel"
                                item-text="nome"
                            />
                        </div>

                        <v-flex xs12>
                            <v-text-field :rules="[v => !!v || 'Preencha aqui']" dense :color="$primaria" class="mr-2 ml-2" v-model="dados.endereco" outlined required label="Endereço completo" />
                        </v-flex>
                        
                        <div class="expande-horizontal item-table mt-3 mb-3 pa-2 coluna">
                            <h3 class="text-center mb-2">Período de Execução</h3>
                            
                            <div class="expande-horizontal">
                                <v-flex xs12>
                                    <v-text-field :rules="[v => !!v || 'Preencha aqui']" dense v-mask="['##/##/####']" :color="$primaria" class="mr-2 ml-2" v-model="dados.periodo_inicio" outlined required label="Inicial" />
                                </v-flex>

                                <v-flex xs12>
                                    <v-text-field :rules="[v => !!v || 'Preencha aqui']" dense v-mask="['##/##/####']" :color="$primaria" class="mr-2 ml-2" v-model="dados.periodo_fim" outlined required label="Final" />
                                </v-flex>
                            </div>
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-textarea :color="$primaria" :rules="[v => !!v || 'Preencha aqui']" class="mr-2 ml-2" v-model="dados.descricao" outlined required label="Descrição da O.S" />
                        </div>

                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="orange"> SALVAR </v-btn>
                    </v-form>
                </v-flex>
            </div>
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    directives: {
        mask
    },
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {},
            cliente: [],
            cliente_selecionado: '',
            funcionario: '',
            responsavel: '',
            periodo_inicio: '',
            periodo_fim: '',
        }
    },
    watch: {
        cliente_selecionado(val) {
            this.dados.cliente = val
            this.dados.cliente_id = val._id
            this.dados.endereco = val.endereco
        },
        responsavel(val) {
            this.dados.responsavel = val
            this.dados.responsavel_id = val._id
        },
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$create('ordem', this.dados)
                this.$router.go(-1)
            }
        },
    },
    async created() {
        this.cliente = await this.$list('cliente')
        this.funcionario = await this.$list('funcionario')
    }
}
</script>

