<template>
  <v-dialog width="800" transition="slide-x-transition" elevation-0 persistent v-model="abrir">
    <Molde title="Andamento" :options="options" icon="arrow_back" :action="() => this.fechar()">
      <div class="pa-1">
        <v-form ref="form">
            <div class="animated flixInX expande-horizontal semquebra">
              <v-flex class="pa-1" xs12>
                <v-textarea ref="documento" clearable :rules="[v => !!v || 'Preencha este campo primeiro']" label="Mensagem " outlined :color="$primaria" v-model="andamento"/>
              </v-flex>
              <!-- <v-flex xs4>
                <v-switch outlined :color="$primaria" v-model="cliente" label="Ative aqui caso deseje que essa atualização fique visível para o cliente"></v-switch>
              </v-flex> -->
            </div>
        </v-form>
        <div class="pb-3 expande-horizontal centraliza">
          <v-btn @click="enviarAndamento" class="white--text" small rounded color="green">
            Enviar
            <v-icon>check</v-icon>
          </v-btn>
        </div>
      </div>
    </Molde>
  </v-dialog>
</template>

<script>
  import { mask } from "vue-the-mask";
  
  export default {
    props: ['id', 'abrir', 'fechar'],
    directives: {
      mask
    },
    data() {
      return {
        andamento: '',
        cliente: false,
        options: [
          {
            icon: 'check',
            nome: "Enviar",
            action: () => this.enviarAndamento()
          }
        ]
      };
    },
    methods: {
      enviarAndamento() {
        if(this.$refs.form.validate()) {
            const data = {
              documento: this.andamento,
              contrato_id: this.id,
              cliente: this.cliente
            }

            this.$store.dispatch('up_charging')

            this.$axios.post("/contrato_homo_empresa", data)
              .then(() => {
                this.$store.dispatch('down_charging')
                this.$store.dispatch('snackbar_success', 'Mensagem enviada!')
                this.fechar()
                this.documento = ''
                this.cliente = false
              })
              .catch(() => {
                this.$store.dispatch('down_charging')
                this.$store.dispatch('snackbar_error', 'Erro, tente novamente')
                this.$store.dispatch('fecha_pagamento')
              })
        }
      }
    }
  };
</script>