<template>
    <div>
        <Molde title="Cadastro de Funcionário" icon="description" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <div class="expande-horizontal comquebra">
                            <v-flex xs12>
                                <v-text-field dense :rules="[v => !!v || 'Preencha este campo']" :color="$primaria" class="mr-1 ml-1" v-model="dados.nome" outlined required  label="Nome" ></v-text-field>
                            </v-flex>

                            <v-flex class="px-1" xs12 md3>
                                <v-text-field
                                    :color="$primaria"
                                    outlined
                                    dense
                                    label="Admissão"
                                    v-mask="['##/##/####']"
                                    v-model="dados.admissao"
                                    placeholder="##/##/####"
                                    hint="dia/mês/ano"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class="px-1" xs12 md3>
                                <v-text-field
                                    :color="$primaria"
                                    outlined
                                    dense
                                    label="Demissão"
                                    v-mask="['##/##/####']"
                                    v-model="dados.demissao"
                                    placeholder="##/##/####"
                                    hint="dia/mês/ano"
                                ></v-text-field>
                            </v-flex>
                            
                            <v-flex class="px-1" xs12 md3>
                                <v-select
                                    dense
                                    :color="$primaria"
                                    :items="permissao"
                                    v-model="dados.permissao_id"
                                    item-text="nome"
                                    :return-object="false"
                                    item-value="_id"
                                    :rules="[v => !!v || 'Selecione um departamento']"
                                    outlined
                                    label="Departamento"
                                />
                            </v-flex>

                            <v-flex class="px-1" xs12 md3>
                                <v-text-field
                                    :color="$primaria"
                                    outlined
                                    dense
                                    label="Função"
                                    v-model="dados.funcao"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12>
                                <v-text-field dense :rules="[v => !!v || 'Preencha este campo']" :color="$primaria" class="mr-1 ml-1" v-model="dados.endereco" outlined required  label="Endereço Completo" ></v-text-field>
                            </v-flex>

                            <v-flex class="px-1" xs12 md3>
                                <v-text-field
                                    :color="$primaria"
                                    outlined
                                    dense
                                    label="Identidade"
                                    v-model="dados.rg"
                                ></v-text-field>
                            </v-flex>
                            
                            <v-flex class="px-1" xs12 md3>
                                <v-text-field 
                                    dense
                                    :rules="[v => !!v || 'Preencha este campo']" 
                                    :color="$primaria" 
                                    v-mask="['###.###.###-##']" 
                                    class="mr-1 ml-1" v-model="dados.cpf" 
                                    outlined 
                                    required  
                                    label="CPF"
                                ></v-text-field>
                            </v-flex>
                            
                            <v-flex class="px-1" xs12 md3>
                                <v-text-field 
                                    dense
                                    :color="$primaria"
                                    :rules="[v => !!v || 'Preencha este campo']" 
                                    class="mr-1 ml-1" 
                                    v-model="dados.email" 
                                    outlined 
                                    required  
                                    label="Email institucional"
                                ></v-text-field>
                            </v-flex>
                            
                            <v-flex class="px-1" xs12 md3>
                                <v-text-field 
                                    dense
                                    :color="$primaria"
                                    :rules="[v => !!v || 'Preencha este campo']" 
                                    class="mr-1 ml-1" 
                                    v-model="dados.celular" 
                                    v-mask="['(##) # ####-####']"
                                    outlined 
                                    required  
                                    label="Celular"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class="px-1" xs12 md4>
                                <v-text-field 
                                    dense
                                    :color="$primaria"
                                    :rules="[v => !!v || 'Preencha este campo']" 
                                    class="mr-1 ml-1" 
                                    v-model="dados.regime_contratacao" 
                                    outlined 
                                    required  
                                    label="Regime de contratação"
                                ></v-text-field>
                            </v-flex>
                            
                            <v-flex class="px-1" xs12 md4>
                                <v-text-field 
                                    dense
                                    :color="$primaria"
                                    :rules="[v => !!v || 'Preencha este campo']" 
                                    class="mr-1 ml-1" 
                                    v-model="dados.nome_da_mae" 
                                    outlined 
                                    required  
                                    label="Nome da mãe"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class="px-1" xs12 md4>
                                <v-text-field 
                                    dense 
                                    :rules="[v => !!v || 'Preencha este campo']" 
                                    :color="$primaria" 
                                    class="mr-1 ml-1" 
                                    v-model="dados.senha" 
                                    outlined 
                                    required  
                                    label="senha"
                                ></v-text-field>
                            </v-flex>
                            
                            <v-flex xs12 class="pa-2">
                                <v-select
                                    :items="unidades"
                                    v-model="dados.unidade_id"
                                    item-text="nome"
                                    :return-object="false"
                                    item-value="_id"
                                    :rules="[v => !!v || 'Selecione uma unidade para este funcionário']"
                                    outlined
                                    label="Unidade de Alocação"
                                />
                            </v-flex>

                        </div>
                        
                        <v-btn
                            @click="save" 
                            class="text-capitalize white--text ml-2" 
                            color="green"
                        >
                            <span>Concluir Cadastro</span> 
                            <v-icon>check</v-icon> 
                        </v-btn>
                    </v-form>
                </v-flex>
            </div>  
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    directives: {
        mask
    },
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {},
            permissao: [],
            unidades: []
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$create('funcionario', this.dados)
                this.$router.go(-1)
            }
        },
    },
    async created() {
        this.permissao = await this.$list('permissao')
        this.unidades = await this.$list('unidade')
    }
}
</script>

