import Listar from '../../../components/home/mapa/Listar.vue'
// import Visualizar from '../../../components/home/mapa/Visualizar.vue'

export const mapa_home = [
    {
        path: '/home/listar-mapa',
        component: Listar
    },
    // {
    //     path: '/home/visualizar-mapa/:id',
    //     component: Visualizar,
    //     props: true
    // },
]

export default {}