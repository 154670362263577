<template>
    <v-dialog persistent width="300" v-model="abrir">
        <v-card light class="pa-3" style="display: flex; flex-direction: column; width:100%;">
            <div style="display: flex; flex-direction: column; align-items: center; width:100%;">
                <v-textarea
                    v-model="dados.mensagem"
                    label="Mensagem"
                    placeholder="Escreva aqui um andamento"
                    outlined
                    :color="$primaria"
                />
                <div style="display: flex; width: 100%; justify-content: center; flex-wrap: nowrap">
                    <v-btn text class=" mr-2 white--text" @click="this.fechar" color="red">Cancelar</v-btn>
                    <v-btn class="white--text" @click="add" color="green">Adicionar</v-btn>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['abrir', 'fechar', 'id'],
    data() {
        return {
            dados: {},
            options: []
        }
    },
    methods: {
        async add() {
            const dados = {
                mensagem: this.dados.mensagem,
                ordem_id: this.id
            }
            //id ordem
            await this.$create(`ordem_diario_bordo/${this.id}`, dados)
            this.fechar()
        }
    }
}
</script>