<template>
  <v-dialog width="800" transition="slide-x-transition" elevation-0 persistent v-model="abrir">
    <Molde title="Andamento de Homologação" :options="options" icon="arrow_back" :action="() => this.fechar()">
      <div class="pa-1">
        <v-form ref="form">
            <div class="animated flixInX expande-horizontal semquebra">
              <v-flex class="pa-1" xs8>
                <v-textarea ref="documento" clearable :rules="[v => !!v || 'Preencha este campo primeiro']" label="Parecer sobre " outlined :color="$primaria" v-model="dados.documento"/>
              </v-flex>
              <v-flex xs4>
                <v-switch outlined :color="$primaria" v-model="dados.cliente" label="Ative aqui caso deseje que essa atualização fique visível para o cliente"></v-switch>
              </v-flex>
            </div>
        </v-form>
        <div class="pb-3 expande-horizontal centraliza">
          <v-btn @click="enviarDocumento" class="white--text" small rounded color="green">
            Concluir andamento
            <v-icon>check</v-icon>
          </v-btn>
        </div>
      </div>
    </Molde>
  </v-dialog>
</template>

<script>
  import { mask } from "vue-the-mask";
  import { storage } from '../../../../services/Firebase'
  
  export default {
    props: ['id', 'abrir', 'fechar'],
    directives: {
      mask
    },
    data() {
      return {
        dados: {
          documento: '',
          url: '',
          status: '',
          cliente: false
        },
        options: [
          {
            icon: 'check',
            nome: "Concluir",
            action: () => {}
          }
        ]
      };
    },
    methods: {
      uploadArquivo(evt) {
        this.$store.dispatch('up_charging')
        evt.stopPropagation();
        evt.preventDefault();
        var file = evt.target.files[0];

        var metadata = {
          'contentType': file.type
        };

        const self = this

        storage.ref('images').child(file.name).put(file, metadata).then(function(snapshot) {
          console.log('Uploaded', snapshot.totalBytes, 'bytes.');
          console.log('File metadata:', snapshot.metadata);
          // Let's get a download URL for the file.
          snapshot.ref.getDownloadURL().then(function(url) {
            // [START_EXCLUDE]
            self.url = url
            self.enviarDocumento()
            //this.pagarCota()
            // [END_EXCLUDE]
          });
        }).catch(function(error) {
          // [START onfailure]
          console.error('Upload failed:', error);
          // [END onfailure]
        });
      },

      enviarDocumento() {
        if(this.$refs.form.validate()) {
            const data = {
              url: this.dados.url,
              documento: this.dados.documento,
              contrato_id: this.id,
              cliente: this.dados.cliente
            }
    
            this.$axios.post("/contrato_homo_empresa", data)
              .then(() => {
                this.$store.dispatch('down_charging')
                this.$store.dispatch('snackbar_success', 'Homologação atualizada')
                this.fechar()
                this.documento = ''
                this.url = ''
              })
              .catch(() => {
                this.$store.dispatch('down_charging')
                this.$store.dispatch('snackbar_error', 'Erro, tente novamente')
                this.$store.dispatch('fecha_pagamento')
              })
        }
      },

      addNovoDocumento() {
        this.documentos.push({
          documento: '',
          url: '',
          status: ''
        })
      }
    }
  };
</script>