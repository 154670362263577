<template>
    <div>
        <Molde title="Visualizar O.S." icon="description" :options="options">
            <v-tabs show-arrows :background-color="$primaria" color="white" v-model="tab">
                <v-tab>Visualizar Ordem de Serviço</v-tab>
                <!-- <v-tab @click="get(`ordem_material`, id)">Matéria Prima</v-tab>
                <v-tab @click="get('ordem_equipamento', id)">Equipamento</v-tab>
                <v-tab @click="get('ordem_carro', id)">Carro</v-tab>
                <v-tab @click="get('ordem_funcionario', id)">Funcionário</v-tab> -->
                <!-- <v-tab @click="get('ordem_diario_bordo', id)">Diário de Bordo</v-tab> -->
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <div class="expande-horizontal centraliza">
                        <v-flex xs12 md6 class="pa-3 ">
                            <v-form ref="form">
                                <div class="expande-horizontal semquebra">
                                    <v-autocomplete
                                        dense
                                        :rules="[v => !!v || 'Preencha aqui']"
                                        class="mr-2 ml-2"
                                        :color="$primaria"
                                        outlined
                                        v-model="responsavel"
                                        :items="funcionario"
                                        label="Selecionar Instalador"
                                        return-object
                                        placeholder="ex: Ricardo"
                                        item-text="nome"
                                    />
                                </div>

                                <div class="expande-horizontal semquebra">
                                    <v-autocomplete
                                        dense
                                        :rules="[v => !!v || 'Selecione um cliente']"
                                        class="mr-2 ml-2"
                                        :color="$primaria"
                                        outlined
                                        v-model="cliente_selecionado"
                                        :items="cliente"
                                        label="Cliente"
                                        return-object
                                        placeholder="ex: Michel"
                                        item-text="nome"
                                    />
                                </div>

                                <v-flex xs12>
                                    <v-text-field :rules="[v => !!v || 'Preencha aqui']" dense :color="$primaria" class="mr-2 ml-2" v-model="dados.endereco" outlined required label="Endereço completo" />
                                </v-flex>
                                
                                <div class="expande-horizontal item-table mt-3 mb-3 pa-2 coluna">
                                    <h3 class="text-center mb-2">Período de Execução</h3>
                                    
                                    <div class="expande-horizontal">
                                        <v-flex xs12>
                                            <v-text-field readonly :rules="[v => !!v || 'Preencha aqui']" dense v-mask="['##/##/####']" :color="$primaria" class="mr-2 ml-2" v-model="dados.periodo_inicio" outlined required label="Inicial" />
                                        </v-flex>

                                        <v-flex xs12>
                                            <v-text-field readonly :rules="[v => !!v || 'Preencha aqui']" dense v-mask="['##/##/####']" :color="$primaria" class="mr-2 ml-2" v-model="dados.periodo_fim" outlined required label="Final" />
                                        </v-flex>
                                    </div>
                                </div>

                                <div class="expande-horizontal semquebra">
                                    <v-textarea :color="$primaria" :rules="[v => !!v || 'Preencha aqui']" class="mr-2 ml-2" v-model="dados.descricao" outlined required label="Descrição da O.S" />
                                </div>

                                <!-- <v-btn @click="save" class="text-capitalize white--text ml-2" color="orange"> SALVAR </v-btn> -->
                            </v-form>
                        </v-flex>
                    </div>
                </v-tab-item>

                <!-- Materiais -->
                <v-tab-item>
                    <div class="expande-horizontal coluna">
                        <Material :dados="ordem_material" :id="this.id" />
                    </div>
                </v-tab-item>

                <!-- Equipamento -->
                <v-tab-item>
                    <div class="expande-horizontal coluna">
                        <Equipamento :dados="ordem_equipamento" :id="this.id" />
                    </div>
                </v-tab-item>

                <!-- Carro -->
                <v-tab-item>
                    <div class="expande-horizontal coluna">
                        <Carro :dados="ordem_carro" :id="this.id" />
                    </div>
                </v-tab-item>

                <!-- Funcionários -->
                <v-tab-item>
                    <div class="expande-horizontal coluna">
                        <Funcionario :dados="ordem_funcionario" :id="this.id" />
                    </div>
                </v-tab-item>
                
                <!-- Diário de Bordo
                <v-tab-item>
                    <div class="expande-horizontal coluna">
                        <DiarioDeBordo :dados="ordem_diario_bordo" :id="this.id" />
                    </div>
                </v-tab-item> -->
                
            </v-tabs-items>
            <v-dialog v-model="dialogo_de_encerramento">
                <v-card class="pa-3">
                    <v-form ref="form">
                        <div v-if="materiais_utilizados.length > 0" class="expande-horizontal coluna">
                            <v-text-field v-for="item in materiais_utilizados" :key="item._id" :rules="[v => !!v || 'Preencha este campo']" :label="`Quantidade de ${item.material.nome !== undefined ? item.material.nome : ''} que foi utilizado(a)`" :color="$primaria" outlined v-model="item.quantidade_utilizada"></v-text-field>
                        </div>
                        <div v-if="carros_utilizados.length > 0" class="expande-horizontal coluna">
                            <v-text-field v-for="carro in carros_utilizados" :key="carro._id" :rules="[v => !!v || 'Preencha este campo']" :label="`Quilometragem atual do carro ${carro.carro.nome !== undefined ? carro.carro.nome : ''}`" :color="$primaria" outlined v-model="carro.quilometragem_atual"></v-text-field>
                        </div>
                        <v-btn color="green" class="white--text" @click="encerraOrdem">Concluir</v-btn>
                    </v-form>
                </v-card>
            </v-dialog>
    		<!-- <AddDiarioDeBordo :abrir="add_diario_de_bordo" :fechar="() => this.add_diario_de_bordo = false" :id="this.id" /> -->

        </Molde>
    </div>
</template>

<script>
import Carro from './tabs/Carro'
import Equipamento from './tabs/Equipamento'
import Material from './tabs/Material'
import Funcionario from './tabs/Funcionario'
// import DiarioDeBordo from './tabs/DiarioDeBordo'
// import AddDiarioDeBordo from './add/AddDiarioDeBordo'


import { mask } from 'vue-the-mask'

export default {
    props: ['id'],
    directives: {
        mask
    },
    components: {
        Carro,
        Equipamento,
        Material,
        Funcionario,
        // DiarioDeBordo,
        // AddDiarioDeBordo
    },
    data() {
        return {
            dialogo_de_encerramento: false,
            tab: 0,
            options: [
                // {
				// 	icon: 'check',
				// 	nome: 'Sinalizar como Em Andamento',
				// 	action: () => this.ordemEmAndamento()
				// },
                {
					icon: 'check',
					nome: 'Solicitar Vistoria',
					action: () => this.solicitar_vistoria()
				},
                // {
				// 	icon: 'check',
				// 	nome: 'Sinalizar como Pausada',
				// 	action: () => this.pausaOrdem()
				// },
                // {
				// 	icon: 'check',
				// 	nome: 'Sinalizar como concluído',
				// 	action: () => this.antesDeEncerrar()
                // },
                // {
                //     icon: 'add',
                //     nome: 'Adicionar Andamento',
                //     action: () => this.add_diario_de_bordo = true
                // },
            ],
            ordem_material: [],
            ordem_equipamento: [],
            ordem_carro: [],
            ordem_funcionario: [],
            ordem_diario_bordo: [],
            dados: {
                data: this.formatDate(new Date().toISOString().substr(0, 10))
            },
            cliente: [],
            cliente_selecionado: '',
            funcionario: [],
            materiais_utilizados: [],
            carros_utilizados: [],
            add_diario_de_bordo: false,

            responsavel: {},
            date: new Date().toISOString().substr(0, 10),
            menu: false,
        }
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
    watch: {
        cliente_selecionado(val) {
            this.dados.cliente = val
            this.dados.cliente_id = val._id
        },
        responsavel(val) {
            this.dados.responsavel = val
            this.dados.responsavel_id = val._id
        },
        date (val) {
            this.dados.data = this.formatDate(this.date)
        },
    },
    methods: {
        solicitar_vistoria() {
            this.$store.dispatch("up_charging")
            this.$axios.put(`ordem/${this.id}`, { status: 'À VISTORIAR' })
                .then(() => {
                this.$store.dispatch("down_charging")
                this.$store.dispatch("Enviada para vistoria")
                })
                .catch(e => {
                this.$store.dispatch("down_charging")
                console.log(e)
                })
        },
        async antesDeEncerrar() {
            this.materiais_utilizados = await this.$list(`ordem_material/${this.id}`)
            this.carros_utilizados = await this.$list(`ordem_carro/${this.id}`)
            this.dialogo_de_encerramento = true
        },
        dogLocation() {
            setInterval(() => {
                if(this.dados.status === 'em andamento') {
                    this.getPosition()
                }
            } , 10000)
        },
        async encerraOrdem() {
            if(this.$refs.form.validate()) {

                await this.$update(`ordem/${this.id}`, { status: 'encerrada', hora_encerramento: this.$moment().format('DD/MM/YYYY [às] HH:mm') })
                
                this.materiais_utilizados.map(async material => {
                    await this.$update(`ordem_material/${material._id}`, { quantidade_utilizada: material.quantidade_utilizada })
                })
                
                this.carros_utilizados.map(async carro => {
                    await this.$update(`carro/${carro.carro._id}`, { quilometragem_atual: carro.quilometragem_atual })
                })

                const self = this

                self.$db.ref(`notifications`).push({
                    usuario: self.$store.getters.user,
                    dados: self.dados,
                    message: `${self.$store.getters.user.nome} -> Ordem encerrada -> ${self.$moment().format('DD/MM/YYYY [às] HH:mm')}` 
                })

                await this.$update(`ordem_material/${this.id}`, { status: 'encerrada', hora_encerramento: this.$moment().format('DD/MM/YYYY [às] HH:mm') })

                this.$db.ref(`/locations/${self.$store.getters.user._id}`).remove()
                    .then(() => console.log('sucesso'))
                    .catch(e => console.log('erro', e))

                location.reload()
            }

        },
        async ordemEmAndamento() {
            await this.$update(`ordem/${this.id}`, { status: 'em andamento' , hora_inicio: this.$moment().format('DD/MM/YYYY [às] HH:mm')})

            const self = this

            self.$db.ref(`notifications`).push({
                usuario: self.$store.getters.user,
                dados: self.dados,
                message: `${self.$store.getters.user.nome} -> Ordem em andamento -> ${self.$moment().format('DD/MM/YYYY [às] HH:mm')}` 
            })

            this.dogLocation()
            
            // this.$router.go(-1)
        },
        async pausaOrdem() {
            await this.$update(`ordem/${this.id}`, { status: 'pausado' })

            const self = this

            self.$db.ref(`notifications`).push({
                usuario: self.$store.getters.user,
                dados: self.dados,
                message: `${self.$store.getters.user.nome} -> Ordem em pausa -> ${self.$moment().format('DD/MM/YYYY [às] HH:mm')}` 
            })

            this.$router.go(-1)
        },
        async get(collection, id) {
            this[`${collection}`] = await this.$list(`${collection}/${id}`)
        },
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`ordem/${this.id}`, this.dados)
                this.$router.go(-1)
            }
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        getPosition() {
            const self = this

            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(self.showPosition, self.showError, {timeout: 30000, enableHighAccuracy: true, maximumAge: 75000});
            } else {
                this.$store.dispatch('snackbar_info', 'Este browser não tem suporte a geolocalização ou o gps está desligado.')
            }
        },
        showPosition(position) {
            const self = this

            self.$db.ref(`/locations/${self.$store.getters.user._id}`).set({
                coordinates: [position.coords.longitude, position.coords.latitude],
                usuario: self.$store.getters.user,
                dados: self.dados
            })
            

        },
        showError(error) {
            const self = this
            self.$store.dispatch('snackbar_info', error.message)
            //self.$store.dispatch('snackbar_info', 'Desculpe, não conseguimos as permissões necessárias para ver sua localização, vá até configurações/aplicativos procure o app iluz solar/permissões e ative a localização.')
            //switch(error.code) {
            //    case error.PERMISSION_DENIED:
            //        self.$store.dispatch('snackbar_info', 'A permissão para pegar a localização foi negada pelo usuário.')
            //    break;
            //    case error.POSITION_UNAVAILABLE:
            //        self.$store.dispatch('snackbar_info', 'As informações de localização não estão disponíveis neste dispositivo, verifique se o GPS está ativo.')
            //    break;
            //    case error.TIMEOUT:
            //        self.$store.dispatch('snackbar_info', 'O tempo de espera pela localização se esgotou')
            //    break;
            //    case error.UNKNOWN_ERROR:
            //        self.$store.dispatch('snackbar_info', 'Erro, atualize a págine')
            //    break;
            //}
        }

    },
    async created() {
        const dados = await this.$show(`ordem/${this.id}`)
        this.dados = dados

        this.cliente = await this.$list('cliente')
        this.funcionario = await this.$list('funcionario')

        this.cliente_selecionado = dados.cliente
        this.responsavel = dados.responsavel
        this.dogLocation()
    }
}
</script>

