<template>
    <div>
        <Molde title="Criar Unidade" icon="description" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        
                        <div class="expande-horizontal comquebra">
                            <v-flex xs12 md4>
                                <v-text-field 
                                    v-model="dados.nome" 
                                    :color="$primaria" 
                                    class="mr-2 ml-2" 
                                    outlined 
                                    required
                                    dense
                                    label="Nome da Unidade" ></v-text-field>
                            </v-flex>

                            <v-flex xs12 md4>
                                <v-text-field 
                                    v-model="dados.user_admin" 
                                    :color="$primaria" 
                                    class="mr-2 ml-2" 
                                    readonly
                                    outlined 
                                    required  
                                    dense
                                    label="Criador"></v-text-field>
                            </v-flex>
                            
                            <v-flex xs12 md4>
                                <v-text-field 
                                    v-model="dados.cnpj" 
                                    :color="$primaria" 
                                    class="mr-2 ml-2" 
                                    dense
                                    outlined 
                                    required  
                                    label="CNPJ"></v-text-field>
                            </v-flex>
                        </div>

                        <div class="expande-horizontal comquebra">
                            <v-flex xs12>
                                <v-text-field
                                    v-model="dados.endereco"
                                    :color="$primaria"
                                    class="mr-2 ml-2"
                                    dense
                                    outlined
                                    required
                                    label="Endereço"
                                ></v-text-field>
                            </v-flex>
                        </div>
                       
                        <div class="expande-horizontal comquebra">
                            <v-flex xs12 md3>
                                <v-select
                                    v-model="dados.estado"
                                    :items="estados"
                                    :color="$primaria"
                                    class="mr-2 ml-2"
                                    dense
                                    outlined
                                    required
                                    label="Estado"
                                ></v-select>
                            </v-flex>

                            <v-flex xs12 md3>
                                <v-text-field
                                    v-model="dados.latitude"
                                    :color="$primaria"
                                    class="mr-2 ml-2"
                                    dense
                                    outlined
                                    required
                                    label="Latitude"
                                ></v-text-field>
                            </v-flex>
                            
                            <v-flex xs12 md3>
                                <v-text-field
                                    v-model="dados.longitude"
                                    :color="$primaria"
                                    class="mr-2 ml-2"
                                    dense
                                    outlined
                                    required
                                    label="Longitude"
                                ></v-text-field>
                            </v-flex>
                        </div>


                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Concluir <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    directives: {
        mask
    },
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {
                user_admin_id: this.$store.getters.user._id,
                user_admin: this.$store.getters.user.nome
            },
            estados: [
                'AC - Acre','AL - Alagoas','AM - Amazonas','AP - Amapá','BA - Bahia',
                'CE - Ceará','DF - Distrito Federal','ES - Espírito Santo','GO - Goiás','MA - Maranhão','MG - Minas Gerais',
                'MS - Mato Grosso do Sul','MT - Mato Grosso','PA - Pará','PB - Paraíba','PE - Pernambuco','PI - Piauí',
                'PR - Paraná','RJ - Rio de Janeiro','RN - Rio Grande do Norte','RO - Rondônia','RR - Roraima','RS - Rio Grande do Sul',
                'SC - Santa Catarina','SP - São Paulo','SE - Sergipe','TO - Tocantins'
            ],
        }
    },
    methods: {
        save() {
            if(this.$refs.form.validate()) {
                this.$store.dispatch('up_charging')
                this.$axios.post('/unidade', this.dados)
                    .then(res => {
                        this.$store.dispatch('down_charging')
                        this.$store.dispatch('snackbar_success', 'cadastrado com sucesso!')
                        this.$router.go(-1)
                    })
                    .catch((e) => {
                        this.$store.dispatch('down_charging')
                        this.$store.dispatch('snackbar_error', e )
                    })
            }
        },
    }
}
</script>

