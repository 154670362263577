import Listar from '../../../components/home/log/Listar.vue'

export const log_home = [

    {
        path: '/home/listar-log',
        component: Listar
    },
]

export default {}