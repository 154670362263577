<template>
    <div>
        <Molde title="Visualizar Categoria" icon="description" :options="options">
            <v-tabs :background-color="$primaria" dark v-model="tab">
                <v-tab>Dados Gerais</v-tab>
                <!-- <v-tab>Ordens do Cliente</v-tab> -->
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <div class="expande-horizontal">
                        <v-flex xs12 class="pa-3 ">
                            <v-form ref="form">
                                <v-flex xs12>
                                    <v-text-field 
                                        dense 
                                        :rules="[v => !!v || 'Preencha este campo']" 
                                        :color="$primaria" 
                                        class="mr-2 ml-2" 
                                        v-model="dados.nome" 
                                        outlined 
                                        required
                                        label="NOME"></v-text-field>
                                </v-flex>

                                <div class="expande-horizontal centraliza mt-6">
                                    <v-btn
                                        @click="save" 
                                        class=" white--text ml-2" 
                                        color="green">
                                            <span>Salvar Alterações</span>
                                    </v-btn>
                                </div>


                            </v-form>
                        </v-flex>
                    </div>  
                </v-tab-item>

                <v-tab-item>
                    <Ordens :cliente_id="this.id" />
                </v-tab-item>
            </v-tabs-items>
        </Molde>
        <EnvioArquivo :fechar="() => this.fechar_envio_arquivo()" :link_arquivo="(link) => recebe_link_arquivo(link)" :abrir="abrir_envio_arquivo" />

    </div>
</template>

<script>
import Ordens from './tab/Ordens'
import { mask } from 'vue-the-mask'
export default {
    props: ['id'],
    directives: {
        mask
    },
    components: {
        Ordens
    },
    data() {
        return {
            tab: 0,
            abrir_envio_arquivo: false,
            documento_em_envio: false,
            options: [
                {
                    icon: "save",
                    nome: "Salvar Alterações",
                    action: () => this.save()
                }
            ],
            dados: {},
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`despesa-categoria/${this.id}`, this.dados)
                this.$router.go(-1)
            }
        }
    },
    async created() {
        this.dados = await this.$show(`despesa-categoria/${this.id}`)
    }
}
</script>

