import Criar from '../../../components/home/kit/Criar'
import Listar  from '../../../components/home/kit/Listar.vue'
import Visualizar from '../../../components/home/kit/Visualizar.vue'

export const kit_home = [
    {
        path: '/home/criar-kit',
        component: Criar
    },
    {
        path: '/home/listar-kit',
        component: Listar
    },
    {
        path: '/home/visualizar-kit/:id',
        component: Visualizar,
        props: true
    },
]

export default {}