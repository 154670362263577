import Firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/database'

const config = {
    apiKey: "AIzaSyDJ0RQldWWBgQ5CxuYHcpTLjuarLoLs7oA",
    authDomain: "sae-consultas.firebaseapp.com",
    databaseURL: "https://sae-consultas.firebaseio.com",
    projectId: "sae-consultas",
    storageBucket: "sae-consultas.appspot.com",
    messagingSenderId: "608483889159",
    appId: "1:608483889159:web:55bbda48ba895a55f15aa2",
    measurementId: "G-MP6E0LPMY8"
}

export const app = Firebase.initializeApp(config)

export const time = Firebase.database.ServerValue.TIMESTAMP
export const storage = app.storage()
export const db = app.database()