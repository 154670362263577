<template>
  <Molde title="Departamentos" :options="options" >
    <div style="min-height: 90vh">
      <v-flex class="pa-2" xs12>
        <v-text-field :hint="`${dados.length} encontrados.`" v-model="pesquisa" outlined="" label="Pesquise um nome" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
      <v-simple-table v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon :color="$primaria">lock</v-icon></th>
              <th>NOME</th>
              <th>VISUALIZAR</th>
              <th>EXCLUIR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dados" :key="item._id" class="animated fadeInUp">
              <td style="width: 20px"> <v-icon :color="$primaria">lock</v-icon> </td>
              <td class="font-weight-bold fonteCorpo">{{ item.nome }}</td>
              <td> <v-btn outlined class="text-lowercase" :color="$primaria" @click="$router.push(`/home/visualizar-permissao/${item._id}`)"> visualizar</v-btn> </td>
              <td> <v-btn outlined class="text-lowercase" color="red" @click="remover(item._id, false)"> excluir</v-btn> </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-if="dados.length === 0" ></Empty>
      <v-divider></v-divider>
      <v-list class="pa-0 ma-0" v-if="dados.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in dados">
          <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-permissao/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonteCorpo" style="color: #444"> {{ item.nome }} </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </div>
    <Confirm :open="confirm_dialog" :action="() => this.remover(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" />
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'add',
                nome: 'Cadastrar',
                action: () => this.$router.push('/home/criar-permissao')    
            }
        ],
        dados: [],
        dadosbkp: [],
        confirm_dialog: false,
        id_to_excluse: ''
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  methods: {
    async get() {
      const data = await this.$list('permissao')
      this.dados = data 
      this.dadosbkp = data
    },
    async remover(id, confirmacao) {
      if(confirmacao) {
        await this.$del(`permissao/${id}`)
        this.get()
      } else {
        this.confirm_dialog = true
        this.id_to_excluse = id
      }
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    }
  },
  async created() {
      await this.get()
  }
}
</script>