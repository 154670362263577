<template>
  <Molde title="Meus Cursos">
    <div class="expande-horizontal centraliza pa-2">
      <v-btn @click="connectToNewUser"></v-btn>
      <v-flex id="video-grid" xs12 md2>

      </v-flex>
      <!-- <video style="width: 300px; height: 300px" :srcObject="stream">

      </video> -->
      <!-- <video v-for="(video, index) in videos" :key="index" srcObject="">

      </video> -->
    </div>
  </Molde>
</template>

<script>
  import Peer from 'peerjs'
  export default {
    data() {
      return {
        peer: null,
        videos: [],
        stream: null
      };
    },
    methods: {
      connectToNewUser(userId, stream) {
        // const call = this.peer.call(userId, stream)
        const call = this.peer.call('5e4fe6e5b52e2404484d3c87', stream)

        const video = document.createElement('video')

        call.on('stream', userVideoStream => {
          this.addVideoStream(video, userVideoStream)
        })

        call.on('close', () => {
          video.remove()
        })

      },
      addVideoStream(video, stream) {
        video.srcObject = stream

        const videoGrid = document.getElementById('video-grid')

        video.addEventListener('loadedmetadata', () => {
          console.log("video", video.srcObject)
          video.play()
        })

        videoGrid.append(video)
      }
    },
    mounted () {

      const myVideo = document.createElement('video')
      myVideo.muted = true

      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then( stream => {
          this.addVideoStream(myVideo, stream)
          this.peer.on("call", call => {
            call.answer(stream)

            const video = document.createElement('video')

            call.on('stream', userVideoStream => {
              this.addVideoStream(video, userVideoStream)
            })
          })
        })
      // this.onJoin()
    },
    created() {
      this.peer = new Peer(this.$store.getters.user._id)
    }
  };
</script>