<template>
    <div>
        <Molde title="Nova Categoria de Despesa" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <v-flex xs12>
                            <v-text-field 
                                dense 
                                :rules="[v => !!v || 'Preencha este campo']" 
                                :color="$primaria" 
                                class="mr-2 ml-2" 
                                v-model="dados.nome" 
                                outlined 
                                required
                                placeholder="ex: Insumos"
                                label="NOME"></v-text-field>
                        </v-flex>

                        <div class="expande-horizontal centraliza mt-6">
                            <v-btn
                                @click="save" 
                                class=" white--text ml-2" 
                                color="green">
                                    <span>Salvar Alterações</span>
                            </v-btn>
                        </div>
                    </v-form>
                </v-flex>
            </div>  
        </Molde>

    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {},
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$create('despesa-categoria', this.dados)
                this.$router.go(-1)
            }
        }
    }
}
</script>

