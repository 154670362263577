// import Criar from '../../../components/home/executar_os/Criar'
import Listar  from '../../../components/home/executar_os/Listar.vue'
import Visualizar from '../../../components/home/executar_os/Visualizar.vue'

export const executar_os_home = [
    // {
    //     path: '/home/criar-executar_os',
    //     component: Criar
    // },
    {
        path: '/home/listar-executar_os',
        component: Listar
    },
    {
        path: '/home/visualizar-executar_os/:id',
        component: Visualizar,
        props: true
    },
]

export default {}