<template>
    <div>
        <Molde title="Visualizar Despesa" icon :options="options">
            <v-tabs centered :grow="$vuetify.breakpoint.smAndDown" :background-color="$primaria" dark v-model="tabs">
                <v-tab>Pagamentos</v-tab>
                <v-tab>Dados</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabs">
                <v-tab-item>
                    <div class="expande-horizontal pa-2">
                        <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12>
                            <div class="expande-horizontal centraliza">

                                <v-simple-table dense class="item-table" v-if="dados.parcelas.length > 0" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>TIPO</th>
                                                <th>CONTA</th>
                                                <th>VALOR</th>
                                                <th>MÊS DE REFERÊNCIA</th>
                                                <th>DIA DE VENCIMENTO</th>
                                                <th>ANO</th>
                                                <th>SITUAÇÃO</th>
                                                <th>PAGO EM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr @click="abre_informe_de_pagamento(item)" style="cursor: pointer;" v-for="(item, index) in dados.parcelas" :key="item._id" :class="index % 2 === 0 ? 'bg-table' : '' " class="animated fadeInUp item-table">
                                                <td class="font-weight-bold fonteCorpo">{{ dados.categoria.nome }}</td>
                                                <td class="font-weight-bold fonteCorpo">{{ dados.nome }}</td>
                                                <td class="font-weight-bold fonteCorpo green--text">R$ {{ dados.valor }}</td>
                                                <td class="font-weight-bold fonteCorpo">{{ item.mes_referencia | mes_filtro }}</td>
                                                <td class="font-weight-bold fonteCorpo">{{ item.dia_referente }}</td>
                                                <td class="font-weight-bold fonteCorpo">{{ item.ano_referencia }}</td>
                                                <td class="font-weight-bold fonteCorpo">{{ item.pago ? 'PAGO' : 'PENDENTE' }}</td>
                                                <td class="font-weight-bold fonteCorpo" v-if="item.pago">{{ $moment(item.pago_em, 'x').format('LLLL') }}</td>
                                                <td class="font-weight-bold fonteCorpo" v-else>  </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </v-flex>

                        <v-flex v-else xs12>
                            <v-list class="item-table" three-line>
                                <template v-for="(item, index) in dados.parcelas">
                                    <v-list-item @click="abre_informe_de_pagamento(item)" class="item-table" :key="index">
                                        <v-list-item-content>
                                            <v-list-item-title :class="item.pago ? 'green--text':'red--text' " class="fonteCorpo font-weight-bold"> {{ $helper.formataSaldo(dados.valor) }} - {{ item.pago ? 'PAGO' : 'PENDENTE' }} </v-list-item-title>
                                            <v-list-item-subtitle class="fonteCorpo" style="font-size: 8pt;"> {{ dados.nome }} ( {{ dados.categoria.nome }} ) </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="!item.pago" class="fonteCorpo" style="font-size: 8pt; background: #900; border-radius: 3px; padding: 5px; color: white;"> Vence dia {{ item.dia_referente }} de {{ item.mes_referencia | mes_filtro }}. </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else class="fonteCorpo" style="font-size: 9pt; background: green; border-radius: 3px; padding: 5px; color: white;"> Paga em: {{ $moment(item.pago_em, 'x').format('llll') }} ({{ item.obs }}) </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-flex>
                    </div>
                </v-tab-item>

                <v-tab-item>
                    <div class="expande-horizontal">
                        <v-flex xs12 class="pa-3 ">
                            <v-form ref="form">
                                <div class="expande-horizontal centraliza">
                                    <v-flex xs12 md5>
                                        <div class="expande-horizontal centraliza item-table comquebra pa-3">
                                            <v-flex xs12 md12 class="px-1">
                                                <v-checkbox
                                                    v-model="dados.mensal" 
                                                    outlined 
                                                    label="Esta despesa é mensal? (recorrente)"></v-checkbox>
                                            </v-flex>

                                            <!-- <v-flex v-if="dados.mensal" xs12>
                                                <v-text-field
                                                    v-model="dados.numero_de_parcelas"
                                                    type="number"
                                                    prefix="x"
                                                    outlined
                                                    dense
                                                    label="Informe o número de parcelas caso necessário"></v-text-field>
                                            </v-flex> -->

                                            <v-flex class="pa-1" xs12 md12>
                                                <v-select
                                                    dense
                                                    :color="$primaria"
                                                    :items="tipo_de_despesa"
                                                    v-model="categoria_selecionada"
                                                    item-text="nome"
                                                    :return-object="true"
                                                    item-value="_id"
                                                    :rules="[v => !!v || 'Selecione um tipo']"
                                                    outlined
                                                    label="Tipo de Despesa"
                                                />
                                            </v-flex>
                                            
                                            <v-flex class="pa-1" xs12 md12>
                                                <v-text-field
                                                    dense
                                                    :color="$primaria"
                                                    v-model="dados.nome"
                                                    :rules="[v => !!v || 'Preencha aqui']"
                                                    outlined
                                                    label="Nome da despesa"
                                                />
                                            </v-flex>
                                            
                                            <v-flex class="pa-1" xs12 md12>
                                                <v-textarea
                                                    dense
                                                    :color="$primaria"
                                                    v-model="dados.descricao"
                                                    outlined
                                                    label="Descrição"
                                                />
                                            </v-flex>

                                            <v-flex xs12 md6 class="pa-2 centraliza">
                                                <v-text-field
                                                    v-mask="['#,##', '##,##', '###,##', '####,##', '##.###,##']"
                                                    dense 
                                                    :rules="[v => !!v || 'Preencha este campo']" 
                                                    :color="$primaria" 
                                                    v-model="dados.valor" 
                                                    outlined 
                                                    prefix="R$"
                                                    placeholder="ex: 300,00"
                                                    label="Valor"></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 md6 class="pa-2 centraliza">
                                                <v-text-field
                                                    type="Number"
                                                    dense 
                                                    :rules="[v => !!v || 'Preencha este campo']" 
                                                    :color="$primaria" 
                                                    v-model="dados.dia_do_vencimento" 
                                                    outlined 
                                                    required
                                                    placeholder="ex: 1"
                                                    label="Dia do vencimento"></v-text-field>
                                            </v-flex>
                                        </div>
                                    </v-flex>
                                </div>

                                <div class="expande-horizontal centraliza mt-6">
                                    <v-btn
                                        @click="save" 
                                        class=" white--text ml-2" 
                                        color="green">
                                            <span>Salvar Alterações</span>
                                    </v-btn>
                                </div>
                            </v-form>
                        </v-flex>
                    </div>  
                </v-tab-item>
            </v-tabs-items>
        </Molde>
        <v-dialog transition="slide-x-transition" width="500" v-model="informar_pagamento_dialog">
            <div class="expande-horizontal centraliza elevation-0">
                <v-flex xs12>
                    <v-card style="min-height: 30vh;border-radius: 0px;" dark color="#333">
                        <div class="expande-horizontal coluna" style="">
                            <v-app-bar dense flat>
                                <v-btn @click="informar_pagamento_dialog=!informar_pagamento_dialog" class="mr-1" icon>
                                    <v-icon>arrow_back</v-icon>
                                </v-btn>
                                INFORMAR PAGAMENTO
                            </v-app-bar>

                            <div class="expande-horizontal">
                                <v-flex xs4 md4 class="pa-3">
                                    <v-card color="transparent" class="expande-horizontal" height="100">
                                        <div class="expande-horizontal centraliza coluna" style="height: 100px; border: 1px solid #40C4FF;">
                                            <v-icon color="#40C4FF"> post_add </v-icon>
                                            <h1 class="fonteCorpo text-center" style="color: #40C4FF; font-size: 12pt;"> {{ dados.nome }} </h1>
                                        </div>
                                    </v-card>
                                </v-flex>
                                <v-flex xs4 md4 class="pa-3">
                                    <v-card color="transparent" class="expande-horizontal" height="100">
                                        <div class="expande-horizontal centraliza coluna" style="height: 100px; border: 1px solid #40C4FF;">
                                            <v-icon color="#40C4FF"> monetization_on </v-icon>
                                            <h1 class="fonteCorpo text-center" style="color: #40C4FF; font-size: 12pt;"> {{ $helper.formataSaldo(dados.valor) }} </h1>
                                        </div>
                                    </v-card>
                                </v-flex>
                                <v-flex xs4 md4 class="pa-3">
                                    <v-card color="transparent" class="expande-horizontal" height="100">
                                        <div class="expande-horizontal centraliza coluna" style="height: 100px; border: 1px solid #40C4FF;">
                                            <v-icon color="#40C4FF"> ballot </v-icon>
                                            <span class="fonteCorpo text-center" style="color: #40C4FF; font-size: 9pt;"> {{ dados.categoria.nome }} </span>
                                        </div>
                                    </v-card>
                                </v-flex>
                            </div>

                            <div class="expande-horizontal coluna pa-3">
                                <h3 class=" text-center orange--text">Vence dia {{ informar_pagamento.dia_referente }} de {{ informar_pagamento.mes_referencia | mes_filtro }} de {{ informar_pagamento.ano_referencia }}. </h3>
                                <h3 class=" text-center orange--text font-weight-light"> ( hoje é {{ $moment().format('LL') }} ) </h3>
                            </div>

                            <div class="expande-horizontal">
                                <v-flex xs12 class="pa-3">
                                    <v-textarea color="#40C4FF" v-model="informar_pagamento.obs" outlined label="Observação"></v-textarea>
                                </v-flex>
                            </div>
                        </div>
                        <v-app-bar color="transparent" class="elevation-0" clipped-left>
                            <v-btn v-if="!informar_pagamento.pago" @click="efetiva_pagamento_parcela" block text large class="green">EFETIVAR PAGAMENTO</v-btn>
                        </v-app-bar>
                    </v-card>
                </v-flex>
            </div>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['id'],
    data() {
        return {
            informar_pagamento_dialog: false,
            informar_pagamento: {
                mes_referencia: '',
                dia_referente: ''
            },
            tabs: 0,
            options: [
                {
                    icon: "save",
                    nome: "Salvar Alterações",
                    action: () => this.save()
                }
            ],
            dados: {
                mensal: false,
                parcelas: []
            },
            tipo_de_despesa: [],
            categoria_selecionada: {}
        }
    },
    filters: {
        mes_filtro(val) {
            const meses = {
                1: 'Janeiro',
                2: 'Fereveiro',
                3: 'Março',
                4: 'Abril',
                5: 'Maio',
                6: 'Junho',
                7: 'Julho',
                8: 'Agosto',
                9: 'Setembro',
                10: 'Outubro',
                11: 'Novembro',
                12: 'Dezembro',
            }
            return meses[val]
        }
    },
    watch: {
        categoria_selecionada(val) {
            this.dados.categoria = val
            this.dados.categoria_id = val._id
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`despesafixa/${this.id}`, this.dados)
                this.$router.go(-1)
            }
        },
        abre_informe_de_pagamento(item) {
            this.informar_pagamento = item
            this.informar_pagamento_dialog = true
        },
        efetiva_pagamento_parcela() {
            this.$store.dispatch("up_charging")
            this.informar_pagamento.valor = this.dados.valor.replace(',','.')

            this.$axios.put('/despesa_fixa_parcela/'+this.informar_pagamento._id, this.informar_pagamento)
                .then(async () => {
                    this.$store.dispatch("down_charging")   
                    this.$store.dispatch('snackbar_success', 'O Pagamento foi efetivado!')
                    this.dados = await this.$show(`despesafixa/${this.id}`)

                    this.tipo_de_despesa.map(tipo => {
                        if(tipo._id === this.dados.categoria_id) {
                            this.categoria_selecionada = tipo
                        }
                    })
                    this.informar_pagamento_dialog = false
                })
                .catch(e => {
                    this.$store.dispatch("down_charging")
                    console.log("e", e)
                })  
        }
    },
    async created() {
        this.tipo_de_despesa = await this.$list("despesa-categoria")
        this.dados = await this.$show(`despesafixa/${this.id}`)

        this.tipo_de_despesa.map(tipo => {
            if(tipo._id === this.dados.categoria_id) {
                this.categoria_selecionada = tipo
            }
        })
    }
}
</script>

