<template>
  <v-dialog class="bg_white" width="800" transition="slide-x-transition" elevation-0 persistent v-model="abrir">
      <v-app-bar flat>
        <v-btn text @click="() => fechar()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
          Anexar Documento
      </v-app-bar>
      <div class="pa-1 bg_white">
        <v-form ref="form">
            <div class="animated flixInX expande-horizontal coluna">
              <v-flex class="pa-1" xs12>
                <v-text-field ref="documento" clearable :rules="[v => !!v || 'Preencha este campo primeiro']" hint="ex: Descrição do documento" label="Nome do documento" outlined :color="$primaria" v-model="documento"></v-text-field>
              </v-flex>

              <v-flex v-if="documento !== ''" xs12 class="pa-1">
                <input type="file" @change="uploadArquivo"/>
              </v-flex>
            </div>
        </v-form>
      </div>
  </v-dialog>
</template>

<script>
  import { mask } from "vue-the-mask";
  import { storage } from '../../../../services/Firebase'
  
  export default {
    props: ['id', 'abrir', 'fechar'],
    directives: {
      mask
    },
    data() {
      return {
        documento: '',
        url: '',
        status: '',
        options: [
          {
            icon: 'close',
            nome: "Fechar",
            action: () => this.fechar()
          }
        ]
      };
    },
    methods: {
      uploadArquivo(evt) {
        this.$store.dispatch('up_charging')
        evt.stopPropagation();
        evt.preventDefault();
        var file = evt.target.files[0];

        var metadata = {
          'contentType': file.type
        };

        const self = this

        storage.ref('images').child(file.name).put(file, metadata).then(function(snapshot) {
          // Let's get a download URL for the file.
          snapshot.ref.getDownloadURL().then(function(url) {
            // [START_EXCLUDE]
            self.url = url
            if(self.url !== '') {
              self.enviarDocumento()
            }
            //this.pagarCota()
            // [END_EXCLUDE]
          });
        }).catch(function(error) {
          // [START onfailure]
          this.$store.dispatch('snackbar_error', error.message)
          this.$store.dispatch('down_charging')
          // [END onfailure]
        });
      },

      enviarDocumento() {
        if(this.$refs.form.validate()) {
            const data = {
              url: this.url,
              documento: this.documento,
              contrato_id: this.id
            }
    
            this.$axios.post("/contrato_documento", data)
              .then(() => {
                this.$store.dispatch('down_charging')
                this.$store.dispatch('snackbar_success', 'O documento foi enviado e o formulário limpo para o envio de um novo documendo, ao terminar o envio de documentos, clique em fechar.')
                this.documento = ''
                this.url = ''
              })
              .catch(() => {
                this.$store.dispatch('down_charging')
                this.$store.dispatch('snackbar_error', 'Erro, tente novamente')
                this.$store.dispatch('fecha_pagamento')
              })
        }
      }
    }
  };
</script>

<style>
  .bg_white {
    background: #fff;;
  }
</style>