import Listar  from '../../../components/cliente/contrato/Listar.vue'
import Visualizar from '../../../components/cliente/contrato/Visualizar.vue'

export const contrato_cliente = [
    {
        path: '/cliente/listar-contrato',
        component: Listar
    },
    {
        path: '/cliente/visualizar-contrato/:id',
        component: Visualizar,
        props: true
    },
]

export default {}