<template>
  <Molde title="Orçamentos" :options="options" >
    <div style="min-height: 90vh">
      <v-flex class="pa-2" xs12>
        <v-text-field :hint="`${dados.length} encontrados.`" v-model="pesquisa" outlined="" label="Pesquise um nome" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
      <v-simple-table v-if="dados.length > 0" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon :color="$primaria">description</v-icon></th>
              <th>CLIENTE</th>
              <th>AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dados" :key="item._id" class="animated fadeInUp">
              <td style="width: 20px"> <v-icon :color="$primaria">description</v-icon> </td>
              <td class="font-weight-bold fonteCorpo">{{ item.cliente.nome }}</td>
              <td> <v-btn outlined class="text-lowercase" :color="$primaria" @click="$router.push(`/home/visualizar-orcamento/${item._id}`)"> visualizar</v-btn> </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-else ></Empty>
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'receipt',
                nome: 'Novo',
                action: () => this.$router.push('/home/criar-orcamento')    
            }
        ],
        dados: [],
        dadosbkp: []
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  filters: {
      filterBlock(val) {
        if(parseInt(val) === 1) {
          return 'bloqueado'
        } else {
          return 'ativo'
        }
      },
      filterSaldo(val) {
        return parseFloat(val).toFixed(2)
      }
  },
  methods: {
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    },
    get() {
      this.$store.dispatch('up_charging')

      this.$axios.get("/orcamento")
        .then(res => {
          this.dados = res.data
          this.dadosbkp = res.data
          this.$store.dispatch('down_charging')
        })
        .catch(e => {
            this.$store.dispatch('down_charging')
            this.$store.dispatch('snackbar_info', e.response.data.message)
        })
    }
  },
  created() {
    this.get()
  }
}
</script>