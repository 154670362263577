export const actions = {
  
  desativa_snackbar(state) {
    state.commit('desativa_snackbar');
  },

  snackbar_error(state, mensagem) {
    state.commit('snackbar_error', mensagem);
  },

  snackbar_success(state, mensagem) {
    state.commit('snackbar_success', mensagem);
  },

  snackbar_warning(state, mensagem) {
    state.commit('snackbar_warning', mensagem);
  },

  snackbar_info(state, mensagem) {
    state.commit('snackbar_info', mensagem);
  },

  up_charging(state) {
    state.commit('up_charging');
  },
  
  down_charging(state) {
    state.commit('down_charging');
  },

  drawer(state, boleano) {
    state.commit('drawer', boleano)
  }

}

export default {}