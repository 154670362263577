<template>
  <Molde title="Ordem de Serviço" :options="options" >
    <div style="min-height: 90vh">
      <v-flex class="pa-2" xs12>
        <v-text-field :hint="`${dados.length} encontrados.`" v-model="pesquisa" outlined="" label="Pesquise um nome" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
      <v-simple-table v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon :color="$primaria">work</v-icon></th>
              <th>CLIENTE</th>
              <th>INSTALADOR</th>
              <th>PERIODO</th>
              <th>STATUS</th>
              <th>VISUALIZAR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dados" :key="item._id">
              <td style="width: 20px"> <v-icon :color="$primaria">work</v-icon> </td>
              <td class="font-weight-bold fonteCorpo">{{ item.cliente.nome }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.responsavel.nome }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.periodo_inicio }} - {{ item.periodo_fim }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.status }}</td>
              <td> <v-btn outlined class="text-lowercase" :color="$primaria" @click="$router.push(`/cliente/visualizar-ordem/${item._id}`)"> visualizar</v-btn> </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-if="dados.length === 0" ></Empty>
      <v-divider></v-divider>
      <v-list class="pa-0 ma-0" v-if="dados.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in dados">
          <v-list-item @click="$router.push(`/cliente/visualizar-ordem/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonteCorpo" style="color: #444"> <v-icon :color="$primaria" size="12">person</v-icon> {{ item.cliente.nome }} </v-list-item-title>
              <v-list-item-subtitle class="fonteCorpo grey--text"> responsável: {{ item.responsavel.nome }} </v-list-item-subtitle>
              <v-list-item-subtitle class="fonteCorpo grey--text"> status: {{ item.status }} </v-list-item-subtitle>
              <v-list-item-subtitle class="fonteCorpo grey--text"> {{ item.periodo_inicio }} - {{ item.periodo_fim }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        pesquisa: '',
        options: [
            //{
            //    icon: 'add',
            //    nome: 'Nova O.S',
            //    action: () => this.$router.push('/home/criar-ordem')    
            //}
        ],
        dados: [],
        dadosbkp: []
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  methods: {
    async get() {
      const data = await this.$list(`ordem/cliente/${this.$store.getters.user._id}`)
      this.dados = data
      this.dadosbkp = data
    },
    async remover(id, responsavel_id) {
      await this.$del(`ordem/${id}`)
      this.$db.ref(`/locations/${responsavel_id}`).remove()
      await this.get()
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.cliente.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    }
  },
  async created() {
      await this.get()
  }
}
</script>