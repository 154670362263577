import Criar from '../../../components/home/contrato/Criar.vue'
import Listar from '../../../components/home/contrato/Listar.vue'
import Visualizar from '../../../components/home/contrato/Visualizar.vue'

export const contrato_home = [
    {
        path: '/home/criar-contrato',
        component: Criar
    },
    {
        path: '/home/listar-contrato',
        component: Listar
    },
    {
        path: '/home/visualizar-contrato/:id',
        component: Visualizar,
        props: true
    },
]

export default {}