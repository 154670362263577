import Criar from '../../../components/home/curso/Criar'
import Listar  from '../../../components/home/curso/Listar.vue'
import Visualizar from '../../../components/home/curso/Visualizar.vue'

export const curso_home = [
    {
        path: '/home/criar-curso',
        component: Criar
    },
    {
        path: '/home/listar-curso',
        component: Listar
    },
    {
        path: '/home/visualizar-curso/:id',
        component: Visualizar,
        props: true
    },
]

export default {}