import Criar from '../../../components/home/financeiro/Criar'
import Listar  from '../../../components/home/financeiro/Listar.vue'
// import Visualizar from '../../../components/home/financeiro/Visualizar.vue'

export const financeiro_home = [
    {
        path: '/home/criar-financeiro',
        component: Criar
    },
    {
        path: '/home/listar-financeiro',
        component: Listar
    },
    // {
    //     path: '/home/visualizar-financeiro/:id',
    //     component: Visualizar,
    //     props: true
    // },
]

export default {}