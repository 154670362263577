<template>
  <Molde title="Tickets" :options="options">
    <v-flex class="pl-3" xs12>
      <v-switch color="green" label="Pendentes" v-model="pendentes"></v-switch>
    </v-flex>
    <div>
      <v-list v-show="$vuetify.breakpoint.smAndDown" :three-line="$vuetify.breakpoint.smAndDown" class="pa-0 ma-0" v-if="tickets.length > 0">
        <template v-for="(item, index) in tickets">
          <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-ticket/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"> CLIENTE: {{ item.usuario.nome }} </v-list-item-title>
              <v-list-item-subtitle class="fonteCorpo"> {{ item.assunto }} </v-list-item-subtitle>
              <v-list-item-subtitle class="fonteCorpo"> msg: {{ item.mensagem }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="item.status !== 'pendente' ? '#56ab2f' : 'yellow darken-3'" size="12">lens</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>

      <v-simple-table v-show="!$vuetify.breakpoint.smAndDown" id="todas" v-if="tickets.length > 0" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon>chat</v-icon></th>
              <th>CLiente</th>
              <th>Assunto</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tickets" :key="item._id">
              <td style="width: 20px"> <v-icon :color="item.status !== 'pendente' ? '#56ab2f' : 'yellow darken-3'" >chat</v-icon> </td>
              <td class="font-weight-bold fonteCorpo">{{ item.usuario.nome }}</td>
              <td class="font-weight-bold fonteCash"> {{ item.assunto }}</td>
              <td class="font-weight-bold fonteCorpo" :class="item.status === 'pendente' ? 'status-pendente' : 'status-pago'">{{ item.status }} <v-icon color="green" v-if="item.status !== 'pendente'" size="15" >check</v-icon> </td>
              <td class="font-weight-bold"> <v-btn color="yellow darken-3" text class="white--text" @click="$router.push('/home/visualizar-ticket/'+item._id)"> Visualizar </v-btn></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-else ></Empty>
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        options: [
            {
                icon: 'cached',
                nome: 'Atualizar',
                action: () => this.get()    
            }
        ],
        tickets: [],
        ticketspendentes: [],
        pendentes: false
    }
  },
  watch: {
    pendentes(val) {
      if(val) {
        this.tickets = this.ticketspendentes
      } else {
        this.tickets = this.ticketsbkp
      }
    }
  },
  methods: {
    get() {
      this.$store.dispatch('up_charging')

      this.$axios.get("/ticket")
        .then(res => {
          const data = res.data.reverse()
          this.tickets = data
          this.ticketsbkp = data
          this.$store.dispatch('down_charging')
          this.setaTicketsPendentes(data)
        })
        .catch(e => {
            this.$store.dispatch('down_charging')
            this.$store.dispatch('snackbar_error', e.response.data.message)
        })
    },
    setaTicketsPendentes(pendentes) {
      const p = pendentes.filter(item => item.status === 'pendente')
      this.ticketspendentes = p
    }
  },
  created() {
    this.get()
  }
}
</script>
