import Criar from '../../../components/home/funcionario/Criar.vue'
import Listar from '../../../components/home/funcionario/Listar.vue'
import Visualizar from '../../../components/home/funcionario/Visualizar.vue'

export const funcionario_home = [
    {
        path: '/home/criar-funcionario',
        component: Criar
    },
    {
        path: '/home/listar-funcionario',
        component: Listar
    },
    {
        path: '/home/visualizar-funcionario/:id',
        component: Visualizar,
        props: true
    },
]

export default {}