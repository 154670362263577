import Criar from '../../../components/home/orcamento/Criar.vue'
import Listar from '../../../components/home/orcamento/Listar.vue'
import Visualizar from '../../../components/home/orcamento/Visualizar.vue'

export const orcamento_home = [
    {
        path: '/home/criar-orcamento',
        component: Criar
    },
    {
        path: '/home/listar-orcamento',
        component: Listar
    },
    {
        path: '/home/visualizar-orcamento/:id',
        component: Visualizar,
        props: true
    },
]

export default {}