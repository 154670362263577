<template lang="pug">
  div(light class="pa-1 expande-horizontal centraliza coluna" style="min-height: 100vh; background: linear-gradient(to right, #2e5999, #8aafe5);")    
    div.expande-horizontal.centraliza
      v-flex(xs12 md8)
        v-card(
          v-if="cadastro_ativo" 
          class="elevation-6 expande-horizontal"
          style="border-radius: 10px;")
            div.expande-horizontal.coluna( 
              class="pl-2 pr-2 mb-2" 
              :style="`border-radius: 0px; display: flex; width: 100%;`")
              v-flex(xs12 md12)
                //- div.expande-horizontal.centraliza
                //-   v-icon(
                //-     :color="$primaria" 
                //-     ml-3 
                //-     mb-1) account_circle
                  
                //-   h2.text-center.pa-2.orange--text Faça Seu Cadastro Abaixo
                
                //- v-btn.mt-1(:color="$primaria"text to="/")
                //-   h3 Login
                //-   v-icon(:color="$primaria"class="ml-2") arrow_forward
              v-flex.pr-2.pb-2.pl-2(xs12)

                v-form(ref="cadastroForm")
                  v-flex(xs12).mb-6
                    v-flex(xs12)
                      div.expande-horizontal.centraliza
                        v-flex(xs2 md1)
                          v-img(:src="$logo")
                        h1.grey--text.text-uppercase(:style="`font-size: ${$vuetify.breakpoint.smAndDown ? '30px' : '46px'}`") {{ tipo }}
                    h2.mb-3.mt-6 Informações Gerais
                    span.mt-6.my-6 Seja um integrador credenciado e receba nossa política de preços, documentações, promoções e muito mais!
                  div.expande-horizontal.comquebra
                    v-checkbox(
                      :color="$primaria"
                      label="Você é profissional liberal? (Engenheiro Elétrico)"
                      v-model="dados.pj"
                    )
                  div.expande-horizontal.comquebra
                    v-flex(xs12 md4).pa-1
                      v-text-field(
                        outlined 
                        dense 
                        :color="$primaria"
                        :rules="[v => !!v || 'Este campo deve ser preenchido']" 
                        label="Nome completo*" 
                        placeholder="ex: Patrick Lorran"
                        v-model="dados.nome")
                    v-flex(xs12 md4).pa-1
                      v-text-field(
                        v-model="dados.cnpj"
                        outlined 
                        dense 
                        :color="$primaria"
                        v-if="!dados.pj"
                        :rules="[v => !!v || 'Este campo deve ser preenchido']" 
                        v-mask="masks.cnpj" 
                        label="CNPJ")

                      v-text-field(
                        v-model="dados.cpf"
                        outlined 
                        dense 
                        :color="$primaria"
                        v-else
                        :rules="[v => !!v || 'Este campo deve ser preenchido']" 
                        v-mask="masks.cpf" 
                        label="CPF")
                  
                    v-flex(xs12 v-if="!dados.pj" md4).pa-1
                      v-text-field(
                        outlined 
                        dense
                        :color="$primaria"
                        :rules="[v => !!v || 'Este campo deve ser preenchido']" 
                        label="Inscrição Estadual" 
                        v-model="dados.inscricao_estadual")
                    
                    div.expande-horizontal.comquebra
                      v-flex(xs12 v-if="!dados.pj" md6).pa-1
                        v-text-field(
                          v-model="dados.nome_fantasia"
                          label="Nome Fantasia"
                          placeholder="ex: Empresa Exemplo"
                          outlined
                          dense
                        )
                      
                      v-flex(xs12 v-if="!dados.pj" md6).pa-1
                        v-text-field(
                          v-model="dados.razao_social"
                          label="Razão Social"
                          placeholder="ex: Empresa Exemplo LTDA"
                          outlined
                          dense
                        )
                    div.expande-horizontal.comquebra
                      v-flex(xs12).mb-6
                        h2.mb-3 Contatos
                        span.mt-6.my-6 Preencha os dados para contato informando nome, email e um telefone e/ou celular.
                      v-flex(xs12 md2).pa-1
                        v-text-field(
                          label="CPF"
                          v-model="dados.cpf"
                          :color="$primaria"
                          v-mask="masks.cpf"
                          outlined
                          dense
                        )
                      
                      v-flex(xs12 md8).pa-1
                        v-text-field(
                          label="Endereço Completo"
                          v-model="dados.endereco"
                          outlined
                          dense
                        )
                      v-flex(xs12 md2).pa-1
                        v-text-field(
                          label="Celular"
                          v-model="dados.celular"
                          v-mask="['(##) # ####-####']"
                          outlined
                          dense
                        )
                      
                    div.expande-horizontal.comquebra
                      v-flex(xs12).mb-6
                        h2.mb-3 Login
                        span.mt-6.my-6 Suas credenciais para acessar o sistema.
                      v-flex(xs12 md4).pa-1
                        v-text-field(
                          label="Email"
                          :color="$primaria"
                          v-model="dados.email"
                          outlined
                          dense
                        )
                      v-flex(xs12 md4).pa-1
                        v-text-field(
                          label="Senha"
                          v-model="dados.senha"
                          type="password"
                          :color="$primaria"
                          outlined
                          dense
                        )

                  v-btn(
                    block 
                    dense 
                    large 
                    :color="$primaria"
                    @click="cadastraUsuario" 
                    class="" 
                    outlined) Concluir meu Cadastro

                    v-progress-circular(
                      v-if="carregandoCadastrar" 
                      class="ml-2" 
                      size="15" 
                      indeterminate)
</template>

<script>
import Moment from "moment";
import "moment/locale/pt-br";
import { mask } from 'vue-the-mask';
import validarCPF from 'validar-cpf'

export default {
  props: ["tipo"],
  directives: {
    mask
  },
  data() {
    return {
      cadastro_ativo: true,
      masks: {
        cnpj: '##.###.###/####-##',
        cpf: '###.###.###-##',
        nascimento: '##/##/####'
      },
      showPass: false,
      senhaOk: false,
      confirmaSenha: "",
      cpf_valido: false,
      dados: {
        tipo: this.tipo,
        nome: "",
        endereco: "",
        contatos: "",
        cpf: "",
        email: '',
        pais: 'Brasil',
        estado: 'Amapá',
        municipio: '',
        pj: false
      },
      patrao: {
        nome: '',
        sessao_id: ''
      },
      confirmaSenha: "",
      confirmaEmail: "",
      carregandoCadastrar: false,
      status: "ativo"
    };
  },
  watch: {
    confirmaSenha(val) {
      if(val === this.dados.senha) {
        this.senhaOk = true
      } else {
        this.senhaOk = false
      }
    }
  },
  methods: {
    cadastraUsuario() {
      if(!validarCPF(this.dados.cpf)) {
        this.$store.dispatch('snackbar_error', 'Informe um cpf válido')
        return 
      }
      
      if(this.$refs.cadastroForm.validate()) {
        this.$store.dispatch('up_charging')

        this.$axios.post(`/cadastro`, this.dados)
          .then(() => {
            this.$store.dispatch('snackbar_success', 'Seu cadastro foi realizado, parabéns!')
            this.$store.dispatch('down_charging')
            this.$router.push('/')
          })
          .catch((e) => {
            this.$store.dispatch('snackbar_info', e.response.data.message)
            this.$store.dispatch('down_charging')
          })
      } else {
        this.$store.dispatch('snackbar_error', 'Preencha todos os dados')
      }
    },
  }
};
</script>
