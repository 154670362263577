import Criar from '../../../components/home/permissao/Criar'
import Listar  from '../../../components/home/permissao/Listar.vue'
import Visualizar from '../../../components/home/permissao/Visualizar.vue'

export const permissao_home = [
    {
        path: '/home/criar-permissao',
        component: Criar
    },
    {
        path: '/home/listar-permissao',
        component: Listar
    },
    {
        path: '/home/visualizar-permissao/:id',
        component: Visualizar,
        props: true
    },
]

export default {}