import Criar from '../../../components/home/notificacao/Criar'
import Listar  from '../../../components/home/notificacao/Listar.vue'
import Visualizar from '../../../components/home/notificacao/Visualizar.vue'

export const notificacao_home = [
    {
        path: '/home/criar-notificacao',
        component: Criar
    },
    {
        path: '/home/listar-notificacao',
        component: Listar
    },
    {
        path: '/home/visualizar-notificacao/:id',
        component: Visualizar,
        props: true
    },
]

export default {}