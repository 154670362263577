<template>
    <div>
        <Molde title="Novo Setor" icon="lock" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <v-flex xs12>
                            <v-text-field :rules="[v => !!v || 'Dê um nome ao novo setor']" :color="$primaria" class="mr-2 ml-2" v-model="dados.nome" outlined required label="Nome do perfil" />
                        </v-flex>

                        <v-flex xs12 class="pl-3 bg-table item-table">
                            <v-switch label="Diretoria" v-model="dados.diretoria.ativo"></v-switch>
                            <v-checkbox
                                label="Notificações"
                                v-model="dados.diretoria.notificacao"
                            ></v-checkbox>
                            <v-checkbox
                                label="Visualizar Mapa"
                                v-model="dados.diretoria.visualizar_mapa"
                            ></v-checkbox>
                            <v-checkbox
                                label="Departamento"
                                v-model="dados.diretoria.departamento"
                            ></v-checkbox>
                            <v-checkbox
                                label="Unidade"
                                v-model="dados.diretoria.unidade"
                            ></v-checkbox>
                        </v-flex>
                        
                        <v-flex xs12 class="pl-3 bg-table item-table">
                            <v-switch label="Administração" v-model="dados.administracao.ativo"></v-switch>
                            <v-checkbox
                                label="Notificações"
                                v-model="dados.administracao.notificacao"
                            ></v-checkbox>
                            <v-checkbox
                                label="Atendimento"
                                v-model="dados.administracao.atendimento"
                            ></v-checkbox>
                        </v-flex>
                        
                        <v-flex xs12 class="pl-3 bg-table item-table">
                            <v-switch label="Gestão" v-model="dados.gestao.ativo"></v-switch>
                            
                            <v-checkbox
                                label="Notificações"
                                v-model="dados.gestao.notificacao"
                            ></v-checkbox>

                            <v-checkbox
                                label="Receitas"
                                v-model="dados.gestao.receita"
                            ></v-checkbox>
                            
                            <v-checkbox
                                label="Categoria de Despesas"
                                v-model="dados.gestao.categoria_despesa"
                            ></v-checkbox>
                            
                            <v-checkbox
                                label="Despesa Fixa"
                                v-model="dados.gestao.despesa_fixa"
                            ></v-checkbox>
                            
                            <v-checkbox
                                label="Despesa Variável"
                                v-model="dados.gestao.despesa_variavel"
                            ></v-checkbox>
                            
                            <v-checkbox
                                label="Comparativo"
                                v-model="dados.gestao.comparativo"
                            ></v-checkbox>
                        </v-flex>
                        
                        <v-flex xs12 class="pl-3 bg-table item-table">
                            <v-switch label="Técnico" v-model="dados.tecnico.ativo"></v-switch>
                            <v-checkbox
                                label="Projetos"
                                v-model="dados.tecnico.projeto"
                            ></v-checkbox>
                            
                            <v-checkbox
                                label="Ordem de Serviço"
                                v-model="dados.tecnico.ordem_de_servico"
                            ></v-checkbox>
                            
                            <v-checkbox
                                label="Vistoriar"
                                v-model="dados.tecnico.vistoriar"
                            ></v-checkbox>
                        </v-flex>
                        
                        <v-flex xs12 class="pl-3 bg-table item-table">
                            <v-switch label="Operacional" v-model="dados.operacional.ativo"></v-switch>
                            <v-checkbox
                                label="Notificação"
                                v-model="dados.operacional.notificacao"
                            ></v-checkbox>
                            
                            <v-checkbox
                                label="Executor de O.S"
                                v-model="dados.operacional.executor_os"
                            ></v-checkbox>
                        </v-flex>
                        
                        <v-flex xs12 class="pl-3 bg-table item-table">
                            <v-switch label="Vendas" v-model="dados.venda.ativo"></v-switch>
                            <v-checkbox
                                label="Proposta"
                                v-model="dados.venda.proposta"
                            ></v-checkbox>
                            
                            <v-checkbox
                                label="Kit Fotovoltaico"
                                v-model="dados.venda.kit_fotovoltaico"
                            ></v-checkbox>
                        </v-flex>
                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Concluir <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    directives: {
        mask
    },
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir",
                    action: () => this.save()
                }
            ],
            dados: {
                diretoria: {
                    ativo: false
                },
                administracao: {
                    ativo: false
                },
                gestao: {
                    ativo: false
                },
                tecnico: {
                    ativo: false
                },
                operacional: {
                    ativo: false
                },
                venda: {
                    ativo: false
                }
            },
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$create('permissao', this.dados)
                this.$router.go(-1)
            }
        }
    }
}
</script>

