<template>
  <Molde title="Contratos" :options="options">
    <template slot="pesquisa_molde">
      <v-flex class="pt-6 px-2" md3>
        <v-text-field outlined dense flat :hint="`${dados.length} encontrados.`" v-model="pesquisa" label="Faça uma busca" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
    </template>
    <div style="min-height: 90vh" class="pa-2">
      <v-flex v-if="$vuetify.breakpoint.smAndDown" xs12>
        <v-text-field dense :hint="`${dados.length} encontrados.`" v-model="pesquisa" outlined="" label="Faça uma busca" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
      <v-simple-table class="item-table" v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th>CLIENTE</th>
              <th>AUTOR</th>
              <th>STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dados" :key="item._id" class="animated fadeInUp" :class="index % 2 === 0 ? 'bg-table':'' ">
              <td @click="$router.push(`/home/visualizar-contrato/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.cliente.nome }}</td>
              <td @click="$router.push(`/home/visualizar-contrato/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.responsavel.nome }}</td>
              <td @click="$router.push(`/home/visualizar-contrato/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.status }}</td>
              <th> <v-btn v-if="item.status === 'PROPOSTA'" small color="green" @click="atualizar_para_convertido(item)">CONVERTER EM VENDA</v-btn> </th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-if="dados.length === 0" ></Empty>
      <v-list class="pa-0 ma-0" v-if="dados.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in dados">
          <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-contrato/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonteCorpo" style="color: #444"> {{ item.cliente.nome }} </v-list-item-title>
              <v-list-item-subtitle class="fonteCorpo grey--text"> responsável: {{ item.responsavel.nome }} </v-list-item-subtitle>
              <v-list-item-subtitle class="fonteCorpo grey--text"> status: {{ item.status }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
      <Confirm :open="confirm_dialog" :action="() => this.remover(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" />
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'add',
                nome: 'Criar',
                action: () => this.$router.push('/home/criar-contrato')    
            }
        ],
        dados: [],
        dadosbkp: [],
        confirm_dialog: false,
        id_to_excluse: ''
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  methods: {
    async atualizar_para_convertido(item) {
      await this.$update(`contrato/${item._id}`, { status: 'convertido em venda' })
      this.get()
    },
    async get() {
      const dados = await this.$list('contrato')
      this.dados = dados
      this.dadosbkp = dados
    },
    async remover(id, confirmacao) {
      if(confirmacao) {
        await this.$del(`contrato/${id}`)
        this.get()
      } else {
        this.confirm_dialog = true
        this.id_to_excluse = id
      }
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.cliente.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    }
  },
  async created() {
    await this.get()
  }
}
</script>