export const mutations = {
  desativa_snackbar(state) {
    state.snackbar_success = {
      mostrar: false,
      mensagem: '',
    };
    state.snackbar_error = {
      mostrar: false,
      mensagem: '',
    };
    state.snackbar_warning = {
      mostrar: false,
      mensagem: '',
    };
    state.snackbar_info = {
      mostrar: false,
      mensagem: '',
    };
  },
  snackbar_error(state, mensagem) {
    state.snackbar_error = {
      mostrar: true,
      mensagem,
    };
  },
  snackbar_success(state, mensagem) {
    state.snackbar_success = {
      mostrar: true,
      mensagem,
    };
  },
  snackbar_warning(state, mensagem) {
    state.snackbar_warning = {
      mostrar: true,
      mensagem,
    };
  },
  snackbar_info(state, mensagem) {
    state.snackbar_info = {
      mostrar: true,
      mensagem,
    };
  },
  up_charging(state) {
    if(state.charging.mostrar === false) {
      state.charging.mostrar = true
    }
  },
  down_charging(state) {
    if(state.charging.mostrar === true) {
      state.charging.mostrar = false
    }
  },
  up_drawer(state) {
    if(state.drawer === false) {
      state.drawer = true
    } else {
      state.drawer = false
      setTimeout(() => state.drawer = true, 200)
    }
  }
}

export default {}