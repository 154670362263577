import Criar from '../../../components/home/vistoria/Criar'
import Listar  from '../../../components/home/vistoria/Listar.vue'
import Visualizar from '../../../components/home/vistoria/Visualizar.vue'

export const vistoria_home = [
    {
        path: '/home/criar-vistoria',
        component: Criar
    },
    {
        path: '/home/listar-vistoria',
        component: Listar
    },
    {
        path: '/home/visualizar-vistoria/:id',
        component: Visualizar,
        props: true
    },
]

export default {}