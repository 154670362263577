import Criar from '../../../components/home/equipamento/Criar'
import Listar  from '../../../components/home/equipamento/Listar.vue'
import Visualizar from '../../../components/home/equipamento/Visualizar.vue'

export const equipamento_home = [
    {
        path: '/home/criar-equipamento',
        component: Criar
    },
    {
        path: '/home/listar-equipamento',
        component: Listar
    },
    {
        path: '/home/visualizar-equipamento/:id',
        component: Visualizar,
        props: true
    },
]

export default {}