<template>
    <div>
        <Molde title="Visualizar Contrato" icon="description" :options="options">
            <v-tabs dark show-arrows color="white" v-model="tab" :background-color="$primaria">
                <v-tab>Dados do contrato</v-tab>
                <v-tab @click="getDocs">Documentos</v-tab>
                <v-tab @click="getHomologacao">Homologação</v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items v-model="tab" :color="$primaria">
                <v-tab-item>
                    <div class="expande-horizontal">
                        <v-flex xs12 class="pa-3 ">
                            <v-form ref="tab_0">
                                <!-- <div class="expande-horizontal">
                                    <v-flex class="px-1" xs12 >
                                        <v-combobox
                                            v-model="cliente_selecionado"
                                            :items="clientes"
                                            item-text="nome"
                                            return-object
                                            label="CLIENTE"
                                            dense
                                            :color="$primaria"
                                            outlined
                                            :rules="[v => !!v || 'Preencha este campo']"
                                        />
                                    </v-flex>
                                </div> -->
                                <div class="expande-horizontal semquebra">
                                    <v-text-field
                                        dense 
                                        :color="$primaria" 
                                        class="mr-2 ml-2" 
                                        v-model="dados.cliente.nome" 
                                        placeholder="" 
                                        outlined 
                                        required 
                                        label="CLIENTE" 
                                    />
                                </div>

                                <div class="expande-horizontal semquebra">
                                    <v-flex md12 xs12>
                                        <v-select dense class="mr-2 ml-2" outlined v-model="dados.tipo_de_estrutura" :items="['COLONIAL', 'FIBROCIMENTO (MADEIRA)', 'FIBROCIMENTO (METÁLICA)', 'ZINCO (METÁLICA)', 'SOLO']" label="Tipo de estrutura"></v-select>
                                    </v-flex>
                                </div>

                                <div class="expande-horizontal semquebra">
                                    <v-text-field
                                        dense 
                                        :color="$primaria" 
                                        class="mr-2 ml-2" 
                                        prefix="kWp: "
                                        v-model="dados.potencia_instalada_em_kwp" 
                                        placeholder="" 
                                        outlined 
                                        required 
                                        label="Potência Instalada em KWP" 
                                    />
                                </div>

                                <!-- <div class="expande-horizontal centraliza">
                                    <v-btn class="white--text" @click="buscar_proposta" :color="$primaria">buscar</v-btn>
                                </div> -->
                                
                            </v-form>
                        </v-flex>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <v-flex xs12>
                        <v-simple-table v-if="documento.length > 0" >
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th><v-icon :color="$primaria">receipt</v-icon></th>
                                    <th>Data</th>
                                    <th>Link</th>
                                    <th>Excluir</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in documento" :key="index">
                                    <td style="width: 20px" > <v-icon :color="$primaria" >receipt</v-icon> </td>
                                    <td class="font-weight-bold fonteCorpo">{{ item.documento }}</td>
                                    <td class="font-weight-bold fonteCorpo"> <a :href="item.url" target="_blank"> Ver </a> </td>
                                    <td class="font-weight-bold fonteCorpo"> <a @click="removeDoc(item._id, false)"> Excluir </a> </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                        <Empty v-else />
                    </v-flex>
                </v-tab-item>
                <v-tab-item class="pa-4">
                    <v-timeline>
                        <v-timeline-item
                            v-for="item in homologacao"
                            :key="item._id"
                            icon="check"
                            :color="$primaria"
                        >
                            <v-card color="grey lighten-4" hover>
                                <v-card-title>
                                    <div class="expande-horizontal centraliza">
                                        <h5 class="text-center">{{ $moment(item.created_at).locale('pt-br').format('LLLL') }}</h5>
                                    </div>
                                </v-card-title>

                                <v-divider></v-divider>

                                <v-card-text style="min-height: 100px">
                                    <h3 class="fonteCorpo"> Andamento: {{ item.documento }} </h3>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-action>
                                    <v-switch class="ma-2" :color="$primaria" label="visivel para o cliente" v-model="item.cliente"></v-switch>
                                    <v-btn @click="salvaHomologacao(item)" class="ma-2" color="green" outlined small>Salvar Alterações</v-btn>
                                </v-card-action>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </v-tab-item>
            </v-tabs-items>
        </Molde>
	    <AddDocumento :abrir="add_documento" :fechar="() => this.add_documento = false" :id="this.id" />
	    <!-- <AddHomologacaoEmpresa :abrir="add_homologacao_empresa" :fechar="() => { this.add_homologacao_empresa = false; this.getHomologacao() }" :id="this.id" /> -->
	    <AddHomologacaoCliente :abrir="add_homologacao_empresa" :fechar="() => this.add_homologacao_empresa = false" :id="this.id" />
        <Confirm :open="confirm_dialog" :action="() => this.removeDoc(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" />
        
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import AddDocumento from './add/Documento'
// import AddHomologacaoEmpresa from './add/AddHomologacaoEmpresa'
import AddHomologacaoCliente from './add/AddHomologacaoCliente'

export default {
    props: ['id'],
    components: {
        AddDocumento,
        // AddHomologacaoEmpresa,
        AddHomologacaoCliente
    },
    directives: {
        mask
    },
    data() {
        return {
            tab: 0,
            add_documento: false,
            add_homologacao_cliente: false,
            add_homologacao_empresa: false,
            options: [
                {
                    icon: "save",
                    nome: "Salvar",
                    action: () => this.save()
                },
                {
					icon: 'add',
					nome: 'Anexar Documento',
					action: () => this.add_documento = true
				},
                {
					icon: 'add',
					nome: 'Add Homologação Empresa',
					action: () => this.add_homologacao_empresa = true
				},
            ],
            dados: {},
            documento: [],
            cliente_selecionado: '',
            funcionario: '',
            responsavel: '',
            homologacao: [],
            confirm_dialog: false,
            id_to_excluse: '',
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`contrato/${this.id}`, this.dados)
            }
        },
        async getDocs() {
            this.documento = await this.$list(`contrato_documento/${this.id}`)
        },
        async removeDoc(id, confirmacao) {
            if(confirmacao) {
                await this.$del(`contrato_documento/${id}`)
                await this.getDocs()
            } else {
                this.id_to_excluse = id
                this.confirm_dialog = true
            }
        },
        async getHomologacao() {
            this.homologacao = await this.$list(`contrato_homo_empresa/${this.id}`)
        },
        async salvaHomologacao(item) {
            await this.$update(`contrato_homo_empresa/${item._id}`, item)
        }
    },
    async created() {
        this.dados = await this.$show(`contrato/${this.id}`)
    }
}
</script>

