export const state = {
  snackbar_error: {
    mostrar: false,
    mensagem: ""
  },
  snackbar_success: {
    mostrar: false,
    mensagem: ""
  },
  snackbar_warning: {
    mostrar: false,
    mensagem: ""
  },
  snackbar_info: {
    mostrar: false,
    mensagem: ""
  },
  charging: {
    mostrar: false
  },
  drawer: true
};

export default {};
