<template>
  <v-container style="flex-direction: column; background: rgba(0,0,0,0) " fluid class="molde pa-0 ma-0">
    <v-layout column>
       
    <v-card :elevation="$vuetify.breakpoint.smAndDown ? '0' : '0'" class="pa-0 ma-0 elevation-0">

      <v-app-bar dark :fixed="$vuetify.breakpoint.smAndDown" :color="$colors.app_bar_template" class="elevation-0  " :style="$vuetify.breakpoint.smAndDown ? 'margin-bottom: 0px; border-radius: 0px' : 'border-radius: 0px;margin-bottom: 0px'" style="justify-content: center; align-items: center;" >
        
        <v-btn v-if="this.icon === undefined" @click="$store.commit('up_drawer')" icon>
          <v-icon size="18" :color="$colors.primaria">menu</v-icon>
        </v-btn>

        <v-btn v-if="this.icon !== undefined" @click="goBack()" icon>
          <v-avatar class="elevation-0">
            <v-icon color="grey"> arrow_back </v-icon>
          </v-avatar>
        </v-btn>

        <h3 class="fonteCorpo" :class="$vuetify.breakpoint.smAndDown ? 'font-weight-bold' : ''" :style="`color: ${$colors.primaria}; drop-shadow(20px -20px 10px blue);`">{{ title }}</h3>

        <v-spacer></v-spacer>

        <slot v-if="$vuetify.breakpoint.lgAndUp" name="pesquisa_molde"></slot>

        <v-spacer></v-spacer>

        <v-btn outlined small v-for="item in options" :color="$colors.primaria" class="mr-1 hidden-sm-and-down animated flipInX delay-1s" @click="item.action()" :key="item.nome">
            <v-icon class="mr-1" size="18"> {{ item.icon }} </v-icon>
            <span>{{ item.nome}}</span> 
        </v-btn>

        <!-- <v-btn x-small color="yellow darken-3" v-if="verificaOption()" @click="() => options[0].action()"> {{ options[0].nome }} </v-btn> -->
        <v-menu
          v-if="verificaOption()"
          class="hidden-lg-and-up"
          left
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn class="hidden-lg-and-up" icon v-on="on">
              <v-icon :color="$colors.primaria">more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="item in menu" :key="item.nome" @click="() => item.action()">
              <v-list-item-title>{{ item.nome }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="item in options" :key="item.nome" @click="() => item.action()">
              <v-list-item-title>{{ item.nome }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <!-- <v-divider></v-divider> -->

      <!-- Conteúdo enviado via slot -->
      <div :class="$vuetify.breakpoint.smAndDown ? 'mt-12 pt-1' : ''" class="molde">
        <v-flex xs12>
          <slot></slot>
        </v-flex>
      </div>

    </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ['title', 'menu', 'options', 'icon', 'action', 'origem'],
  data() {
    return {
      pesquisa: false,
      fab: true
    }
  },
  methods: {
    goBack() {
      if(this.action !== undefined) {
        this.action()
      } else {
        this.$router.go(-1)
      }
    },
    verificaOption() {
      let val = false
      
      if(this.options !== undefined) {
        if(this.options.length > 0) {
          val = true
        }
      }

      return val
    }
  },
  mounted() {
  }
}
</script>

<style>
  .centraliza-tudo {
    display: flex;
    flex-direction: row;
    align-items: center
  }

  .molde {
    display: flex;
    width: 100%;
  }
</style>