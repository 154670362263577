<template lang="pug">
    Molde(
        title="Visualizar Financiamento"
        :options="options"
        icon
    )
        div.expande-horizontal
            v-flex.pa-3(xs12)
                v-form(ref="form")
                    v-flex(xs12)
                        div.expande-horizontal.comquebra
                            v-flex(xs12 md4).px-1
                                v-text-field(
                                    v-model="dados.instituicao"
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    label="INSTITUIÇÃO"
                                    :color="$primaria"
                                    outlined
                                    dense
                                )
                            v-flex(xs12 md4).px-1
                                v-text-field(
                                    type="Number"
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    v-model="dados.quantidade_parcelas"
                                    label="QUANTIDADE DE PARCELAS"
                                    :color="$primaria"
                                    outlined
                                    dense
                                )
                        
                        div.expande-horizontal.comquebra
                            v-flex(xs12 md4).px-1
                                v-text-field(
                                    v-model="dados.percentual_entrada"
                                    label="PERCENTUAL DE ENTRADA"
                                    prefix="%"
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    v-mask="['#.##', '##.##']"
                                    :color="$primaria"
                                    persistent-hint
                                    outlined
                                    dense
                                )
                            
                            v-flex(xs12 md4).px-1
                                v-text-field(
                                    v-model="dados.taxa_juros_mensal"
                                    label="TAXA DE JUROS MENSAL"
                                    prefix="%"
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    v-mask="['#.##', '##.##']"
                                    :color="$primaria"
                                    persistent-hint
                                    outlined
                                    dense
                                )
                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Concluir <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>
        </Molde>
    </div>
</template>

<script>
export default {
    props: ['id'],
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir",
                    action: () => this.save()
                }
            ],
            dados: {},
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`financiamento/${this.id}`, this.dados)
                this.$router.go(-1)
            }
        }
    },
    async created() {
        this.dados = await this.$show(`financiamento/${this.id}`)
    }
}
</script>

