<template>
  <Molde title="Clientes" :options="options" >
    <div style="min-height: 90vh" class="pa-2">
      <v-flex xs12>
        <v-text-field autofocus dense :hint="`${usuarios.length} encontrados.`" v-model="pesquisa" outlined label="Pesquise um cliente" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
      <v-simple-table class="item-table" dense v-if="usuarios.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th>NOME</th>
              <th>CPF</th>
              <th>VISUALIZAR</th>
              <th>EXCLUIR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in usuarios" :key="item._id" class="animated fadeInUp" :class="index % 2 === 0 ? 'bg-table':'' ">

              <td class="font-weight-bold fonteCorpo">{{ item.nome }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.cpf_cnpj }}</td>
              <td> <v-btn small outlined class="text-lowercase" :color="$primaria" @click="$router.push(`/home/visualizar-cliente/${item._id}`)"> visualizar</v-btn> </td>
              <td> <v-btn small outlined class="text-lowercase" color="red" @click="remover(item._id, false)"> excluir </v-btn> </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-if="usuarios.length === 0" ></Empty>

      <v-list class="pa-0 ma-0" v-if="usuarios.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in usuarios">
          <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-cliente/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonteCorpo" style="color: #444"> {{ item.nome }} </v-list-item-title>
              <v-list-item-subtitle class="fonteCorpo grey--text"> cpf: {{ item.cpf }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </div>
    <Confirm :open="confirm_dialog" :action="() => this.remover(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" />
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'person_add',
                nome: 'Cadastrar',
                action: () => this.$router.push('/home/criar-cliente')    
            }
        ],
        usuarios: [],
        usuariosbkp: [],
        confirm_dialog: false,
        id_to_excluse: ''
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  methods: {
    async get() {
      const dados = await this.$list('cliente')
      this.usuarios = dados
      this.usuariosbkp = dados
    },
    async remover(id, confirmacao) {
      if(confirmacao) {
        await this.$del(`cliente/${id}`)
        this.get()
      } else {
        this.confirm_dialog = true
        this.id_to_excluse = id
      }
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.usuarios  = this.usuariosbkp
      } else {
        const filtroTotal = this.usuariosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.usuarios = filtrados
      }
    },
  },
  async created() {
    await this.get()
  },
}
</script>

<style>
  .item-table {
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
</style>