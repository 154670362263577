<template>
  <canvas :id="this.id"></canvas>
</template>

<script>
import Chart from "chart.js";
export default {
  props: ["id", "type", "labels", "dados"],
  watch: {
    dados() {
      this.montaChart()
    }
  },
  methods: {
    montaChart() {
      var tela = document.getElementById(this.id).getContext("2d");
      var chart = new Chart(tela, {
        // The type of chart we want to create
        type: "line",

        // The data for our dataset
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.id,
              color: "#fff",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderColor: "rgba(255, 255, 255, 0.7)",
              data: this.dados
            }
          ]
        },

        // Configuration options go here
        options: {}
      });
    }
  },
  mounted() {
    this.montaChart()
  }
};
</script>