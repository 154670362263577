<template>
<Molde title="Dashboard">
  <v-container class="mt-4" fluid>
    <v-layout row>
      <v-flex class="pa-2" :style="`cursor: pointer`" xs12 md4>
        <v-card class="pa-2">
          <v-sheet
            class="btnn v-sheet--offset"
            color="white"
          >
            <Chart id="Contratos" type="line" :labels="labels" :dados="chartContratos" />
          </v-sheet>

          <v-card-text class="pt-0">
            <h4 class="fonteCorpo"> {{ contratos.length }} Contratos Cadastrados </h4>
          </v-card-text>
        </v-card>
      </v-flex>
      
      <v-flex class="pa-2" :style="`cursor: pointer`" xs12 md4>
        <v-card class="pa-2">
          <v-sheet
            class="btnn v-sheet--offset"
            color="white"
          >
            <Chart id="Clientes" type="line" :labels="labels" :dados="chartClientes" />
          </v-sheet>

          <v-card-text class="pt-0">
            <h4 class="fonteCorpo"> {{ clientes.length }} Clientes Cadastrados </h4>
          </v-card-text>
        </v-card>
      </v-flex>
      
      <v-flex class="pa-2" :style="`cursor: pointer`" xs12 md4>
        <v-card class="pa-2">
          <v-sheet
            class="btnn v-sheet--offset"
            color="white"
          >
            <Chart id="O.S." type="line" :labels="labels" :dados="chartOrdens" />
          </v-sheet>

          <v-card-text class="pt-0">
            <h4 class="fonteCorpo"> {{ ordens.length }} O.S. Cadastradas </h4>
          </v-card-text>
        </v-card>
      </v-flex>

    </v-layout>
  </v-container>
</Molde>
</template>

<script>
export default {
  data() {
    return {
      effects: {
        userScale: 0.9,
        class: ''
      },
      contratos: [],
      clientes: [],
      ordens: [],
      chartContratos: [],
      chartClientes: [],
      chartOrdens: [],
      labels: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ]
    };
  },
  methods: {
    async getContratos() {
        this.contratos = await this.$list('contrato')
        this.chart(this.contratos, 'contratos')
    },
    async getClientes() {
        this.clientes = await this.$list('cliente')
        this.chart(this.clientes, 'clientes')
    },
    async getOrdens() {
        this.ordens = await this.$list('ordem')
        this.chart(this.ordens, 'ordens')
    },
    chart(dados, origem) {
      let retultado = [];

      let meses = [0,0,0,0,0,0,0,0,0,0,0,0];

      dados.filter(item => {
        console.log("mes", this.$moment(!!item.data_ativacao ? item.data_ativacao : item.criadoEm, 'X').format("MM"))
        switch (`${this.$moment(!!item.data_ativacao ? item.data_ativacao : item.criadoEm, !!item.data_ativacao ? 'x' : '').format("MM")}`) {
          case '01':
            return meses[0] = 1;
            break;
          case '02':
            return meses[1] += 1;
            break;
          case '03':
            return meses[2] += 1;
            break;
          case '04':
            return meses[3] += 1;
            break;
          case '05':
            return meses[4] += 1;
            break;
          case '06':
            return meses[5] += 1;
            break;
          case '07':
            return meses[6] += 1;
            break;
          case '08':
            return meses[7] += 1;
            break;
          case '09':
            return meses[8] += 1;
            break;
          case '10':
            return meses[9] += 1;
            break;
          case '11':
            return meses[10] += 1;
            break;
          case '12':
            return meses[11] += 1;
            break;
          default:
            console.log("n/a");
        }
      });

      switch (origem) {
        case "contratos":
          this.chartContratos = meses;
          break;
        case "clientes":
          this.chartClientes = meses;
          break;
        case "ordens":
          this.chartOrdens = meses;
          break;
        default:
          console.log("n/a");
      }
    }
  },
  created() {
      this.getContratos()
      this.getClientes()
      this.getOrdens()
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.btnn {
  background: linear-gradient(#56ccf2, #2f80ed);
  border-radius: 3px;
  border: 0;
  min-height: 150px;
  color: rgb(241, 234, 234);
  box-shadow: 0 3px 5px 2px rgba(158, 144, 98, 0.1);
}
</style>