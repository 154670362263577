export const gettersPagamento = {
  pagamento(state) {
    return state.pagamento;
  },
  mostrar(state) {
    return state.pagamento.mostrar
  }
};

export default {};
