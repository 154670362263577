import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Molde from "@/templates/Molde.vue";
import Empty from '@/components/core/Empty'
import axios from "axios"
// import wb from "./registerServiceWorker";
import SnackbarSuccess from "./components/core/SnackbarSucess.vue";
import SnackbarError from "./components/core/SnackbarError.vue";
import SnackbarWarning from "./components/core/SnackbarWarning.vue";
import SnackbarInfo from "./components/core/SnackbarInfo.vue";
import SnackbarCharging from "./components/core/SnackbarCharging.vue";
import Chart from "./components/charts/baseChart.vue";
import 'vue-material-design-icons/styles.css';
import QRCode  from 'qrcode'
import moment from 'moment'
import 'moment/locale/pt-br'
import { rtdbPlugin } from 'vuefire'
import Helper from "./services/Helper";
import { db, time } from './services/Firebase'
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";
import Confirm from "./components/core/Confirm.vue";
import './registerServiceWorker'
import EnvioArquivo from "./components/core/EnvioArquivo.vue"
import mask from 'vue-the-mask'
import VueHtml2pdf from 'vue-html2pdf'
import {WebRTC} from 'vue-webrtc'
import money from 'v-money'


Vue.use(money)
Vue.use(WebRTC)
Vue.component(WebRTC.name, WebRTC)
Vue.use(VueHtml2pdf)
Vue.use(mask)
Vue.use(VueMapbox, { mapboxgl: Mapbox });

const app_config = [
  {
    // api: 'https://c0sm01nfr4.patricklorran.dev/iluzsolar/',
    // api: 'http://192.168.1.139:3001',
    // api: 'http://10.20.29.198:3001',
    api: 'http://localhost:3000',
    name: 'iluzsolar',
    logo: '/img/iluzsolar.png',
    app_name_complete: 'Iluz Solar',
    login_type: 'nickname',
    logo_mini: '/img/iluzsolar.png',
    colors: {
      primaria: '#FBC02D',
      home_drawer: '#f9f9f9',
      app_bar_template: '#333',
      dark: false,
    }
  },
  {
    api: 'https://c0sm01nfr4.patricklorran.dev/nortebrasilsolar/',
    // api: 'http://localhost:3000',
    name: 'nortebrasil',
    app_name_complete: 'Norte Brasil Solar',
    logo: '/img/nortebrasil.png',
    login_type: 'cpf',
    logo_mini: '/img/nortebrasil.png',
    // logo: 'https://firebasestorage.googleapis.com/v0/b/sae-consultas.appspot.com/o/logo3.png?alt=media&token=d8118249-ef6b-4466-9464-1aadee5b8e20',
    // logo_mini: 'https://firebasestorage.googleapis.com/v0/b/sae-consultas.appspot.com/o/logo3.png?alt=media&token=d8118249-ef6b-4466-9464-1aadee5b8e20',
    colors: {
      primaria: '#2196F3',
      home_drawer: '#194380',
      app_bar_template: '#333',
      dark: true,
    }
  },
]

const tema = 1

Vue.prototype.$logo = app_config[tema].logo
Vue.prototype.$logo_mini = app_config[tema].logo_mini
Vue.prototype.$colors = app_config[tema].colors
Vue.prototype.$app_name = app_config[tema].app_name_complete
Vue.prototype.$app_config = app_config[tema]

Vue.prototype.$primaria = app_config[tema].colors.primaria // yello darken-3
Vue.prototype.$secundaria = 'yellow' // azul solar
Vue.prototype.$terciaria = '#F9A825' // grey darken-3
Vue.prototype.$danger = '#C62828' // red darken-3
Vue.prototype.$warning = '#F9A825' // yellow darken-3
Vue.prototype.$info = '#C62828' // red darken-3
Vue.prototype.$success = 'green' // 

Vue.use(rtdbPlugin)
Vue.config.productionTip = false;
// Vue.prototype.$workbox = wb;

const http = axios.create({ 
  // baseURL: 'http://localhost:3000/',
  baseURL: app_config[tema].api,

  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${store.getters.token}`
  }
})

Vue.prototype.$axios = http

const req = async (method, routing, success, payload) => {
  try {
    store.dispatch('up_charging');
    const res = await http[`${method}`](routing, payload)
    if(!!success) store.dispatch('snackbar_success', success)
    store.dispatch('down_charging')
    return res.data

  } catch (error) {
    store.dispatch('snackbar_info', error.response.data.message)
    store.dispatch('down_charging')

  }
}

Vue.prototype.$list = (route, payload) => req('get', route, false, payload)
Vue.prototype.$show = (route) => req('get', route)
//rotas com carga útil
Vue.prototype.$create = (route, payload) => req('post', route, 'Cadastrado', payload)
Vue.prototype.$update = (route, payload) => req('put', route, 'Atualizado', payload)
Vue.prototype.$del = (route) => req('delete', route, 'Exclusão concluída!')

Vue.component("Molde", Molde);
Vue.component("Empty", Empty);
Vue.component("Chart", Chart);
Vue.component("Confirm", Confirm);
Vue.component("SnackbarSuccess", SnackbarSuccess);
Vue.component("SnackbarError", SnackbarError);
Vue.component("SnackbarWarning", SnackbarWarning);
Vue.component("SnackbarInfo", SnackbarInfo);
Vue.component("SnackbarCharging", SnackbarCharging);
Vue.component("EnvioArquivo", EnvioArquivo);

Vue.prototype.$qrcode = QRCode
Vue.prototype.$moment = moment
Vue.prototype.$helper = new Helper()

Vue.prototype.$db = db
Vue.prototype.$time = time

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
