<template>
    <div>
        <Molde title="Nova entrada ou saída de valor" icon="description" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <v-flex class="pa-2" xs12>
                            <v-switch :color="$primaria" v-model="entrada" label="Entrada de valor"></v-switch>
                            <v-switch :color="$primaria" v-model="despesa" label="Despesa"></v-switch>
                        </v-flex>
                        
                        <div v-if="entrada || despesa" class="expande-horizontal coluna">
                            <v-textarea :rules="[v => !!v || 'Preencha este campo']" :color="$primaria" class="mr-2 ml-2" v-model="dados.descricao" outlined required  label="Descrição" />
                            <v-text-field class="pa-2" :color="$primaria" :rules="[v => !!v || 'Este campo deve ser preenchido']" prefix="R$" v-mask="['##,##', '###,##', '####,##','#,##', '#####,##']" outlined v-model="dados.valor" label="Valor" ></v-text-field>
                        </div>

                        <v-btn v-if="entrada || despesa" @click="save" class="text-capitalize white--text ml-2" color="green"> Concluir <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>  
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    directives: {
        mask
    },
    data() {
        return {
            options: [
            ],
            dados: {},
            entrada: false,
            despesa: false
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                const data = {
                    origem: this.entrada ? 'entrada' : 'saída',
                    valor: this.dados.valor,
                    descricao: this.dados.descricao
                }
                await this.$create('financeiro', data)
                this.$router.go(-1)
            }
        },
    }
}
</script>

