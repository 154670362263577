<template>
  <Molde title="Funcionários" :options="options" >
    <div style="min-height: 90vh">
      <v-flex class="pa-2" xs12>
        <v-text-field :hint="`${usuarios.length} encontrados.`" v-model="pesquisa" outlined="" label="Pesquise um funcionário" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
      <v-simple-table v-if="usuarios.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th><v-icon :color="$primaria">account_circle</v-icon></th>
              <th>NOME</th>
              <th>EMAIL</th>
              <th>VISUALIZAR</th>
              <th>EXCLUIR</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in usuarios" :key="item._id" class="animated fadeInUp">
              <td style="width: 20px"> <v-icon :color="$primaria">account_circle</v-icon> </td>
              <td class="font-weight-bold fonteCorpo">{{ item.nome }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.email }}</td>
              <td> <v-btn outlined class="text-lowercase" :color="$primaria" @click="$router.push(`/home/visualizar-funcionario/${item._id}`)"> visualizar</v-btn> </td>
              <td> <v-btn outlined class="text-lowercase" color="red" @click="remover(item._id)"> excluir</v-btn> </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-if="usuarios.length === 0" ></Empty>
      <v-divider></v-divider>
      <v-list class="pa-0 ma-0" v-if="usuarios.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in usuarios">
          <v-list-item class="animated fadeInUp" @click="$router.push(`/home/visualizar-funcionario/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonteCorpo" style="color: #444"> <v-icon :color="$primaria" size="12">person</v-icon> {{ item.nome }} </v-list-item-title>
              <v-list-item-subtitle class="fonteCorpo grey--text"> login: {{ item.email }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="$primaria">keyboard_arrow_right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </div>
    <Confirm :open="confirm_dialog" :action="() => this.remover(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" />
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        accessToken: 'pk.eyJ1IjoicHRrbG9ycmFuIiwiYSI6ImNrM2JzbXRqZTBnZjkzbnFlM3VyYTVzazkifQ.kgDrdfqGfd6XTV5DXfeSwg', // your access token. Needed if you using Mapbox maps
        mapStyle:  'mapbox://styles/mapbox/dark-v10', // your map style
        pesquisa: '',
        options: [
            {
                icon: 'person_add',
                nome: 'Cadastrar',
                action: () => this.$router.push('/home/criar-funcionario')    
            }
        ],
        usuarios: [],
        usuariosbkp: [],
        coordinates: [-111.549668, 39.014],
        confirm_dialog: false,
        id_to_excluse: ''
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  filters: {
      filterBlock(val) {
        if(parseInt(val) === 1) {
          return 'bloqueado'
        } else {
          return 'ativo'
        }
      },
      filterSaldo(val) {
        return parseFloat(val).toFixed(2)
      }
  },
  methods: {
    async remover(id, confirmacao) {
      if(confirmacao) {
        await this.$del(`funcionario/${id}`)
        this.get()
      } else {
        this.confirm_dialog = true
        this.id_to_excluse = id
      }
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.usuarios  = this.usuariosbkp
      } else {
        const filtroTotal = this.usuariosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.usuarios = filtrados
      }
    },
    get() {
      this.$store.dispatch('up_charging')

      this.$axios.get("/funcionario")
        .then(res => {
          this.usuarios = res.data
          this.usuariosbkp = res.data
          this.$store.dispatch('down_charging')
        })
        .catch(e => {
            this.$store.dispatch('down_charging')
            this.$store.dispatch('snackbar_info', e.response.data.message)
        })
    },
    
  },
  created() {
    this.get()
  },
}
</script>