<template>
  <div>
    <v-navigation-drawer style="background:#333" class="elevation-1" v-model="drawer" app clipped width="270">
      <v-card class="elevation-0" style="border-radius: 0px; margin-bottom: 0px;" color="#333">
        <div class="semQuebra expande-horizontal">
          <v-list light style="width: 100%" class="pa-0 ma-0">
            <v-list-item @click="() => {}">
              <v-avatar color="white" class="elevation-0 mr-2 mt-2 mb-2">
                <v-img :src="$logo_mini"></v-img>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="blue--text text-capitalize">
                  {{ $store.getters.user.nome }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 10pt" class="grey--text">
                  {{ $moment().locale('pt-br').format('DD/MM/YYYY') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card>


      <v-list dark style="border-radius: 0px" nav dense color="#333" class="pa-1 ma-0">
        <v-subheader class="grey--text">Opções</v-subheader>

        <!-- <v-list-item active-class="menuAtivoHomeCliente" to="/cliente/listar-ticket-cliente">
          <v-avatar>
            <v-icon :color="$route.path === '/cliente/listar-ticket-cliente' ? 'white' : $primaria">
              post_add
            </v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title :class="$route.path === '/cliente/listar-ticket-cliente' ? 'white--text' : 'grey--text'" class="fonteCorpo font-weight-bold" >
              Fale conosco
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
                <v-btn icon>
                  <v-icon>arrow_right</v-icon>
                </v-btn>
              </v-list-item-action>
        </v-list-item> -->

        <v-list-item active-class="menuAtivoHomeCliente" :to="`/cliente/listar-perfil/${$store.getters.user._id}`">
          <v-avatar>
            <v-icon :color="$route.path === '/cliente/listar-perfil/'+$store.getters.user._id ? 'white' : $primaria">
              mdi-account
            </v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title :class="$route.path === '/cliente/listar-perfil/'+$store.getters.user._id ? 'white--text' : 'grey--text'" class="fonteCorpo font-weight-bold" >
              Meu Perfil
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
                <v-btn icon>
                  <v-icon>arrow_right</v-icon>
                </v-btn>
              </v-list-item-action>
        </v-list-item>

        <v-list-item active-class="menuAtivoHomeCliente" to="/cliente/listar-contrato">
          <v-avatar>
            <v-icon :color="$route.path === '/cliente/listar-contrato' ? 'white' : $primaria">
              description
            </v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title :class="$route.path === '/cliente/listar-contrato' ? 'white--text' : 'grey--text'" class="fonteCorpo font-weight-bold" >
              Contratos
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
                <v-btn icon>
                  <v-icon>arrow_right</v-icon>
                </v-btn>
              </v-list-item-action>
        </v-list-item>

        <v-list-item active-class="menuAtivoHomeCliente" to="/cliente/listar-ordem">
          <v-avatar>
            <v-icon :color="$route.path === '/cliente/listar-ordem' ? 'white' : $primaria">
              work
            </v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title :class="$route.path === '/cliente/listar-ordem' ? 'white--text' : 'grey--text'" class="fonteCorpo font-weight-bold" >
              Ordens de Serviço
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
                <v-btn icon>
                  <v-icon>arrow_right</v-icon>
                </v-btn>
              </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="sair" active-class="menuAtivoHomeCliente">
          <v-avatar>
            <v-icon color="red darken-3">swap_horizontal_circle</v-icon>
          </v-avatar>
          <v-list-item-content >
            <v-list-item-title class="fonteCorpo grey--text"> Sair </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
    <v-content>
      <transition name="router-anim" >
        <router-view></router-view>
      </transition>
    </v-content>
  </div>
</template>

<script>
export default {
  props: {
    source: String
  },
  data() {
    return {
      permissao: {},
    }
  },
  computed: {
    drawer() {
      return this.$store.getters.drawer
    },
  },
  methods: {
    sair() {
      sessionStorage.clear()
      this.$router.push('/')
    },
    verificaUsuarioLogado() {
      if(sessionStorage.perfil === undefined) {
        this.$router.push('/')
      }
    }
  },
  created() {
    this.verificaUsuarioLogado()
  }
};
</script>

<style>
#keep .v-navigation-drawer__border {
  display: none;
}

::-webkit-scrollbar {
  width: 2px;
  border-radius: 10px;
  background: rgba(180, 179, 179, 0);
}
::-webkit-scrollbar-thumb {
  background: #F9A825;
  border-radius: 10px;
}

.menuAtivo {
  color: #ffffff;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: bold;
}

  .router-anim-enter-active {
  animation: coming 0.4s;
  animation-delay: 0.4s;
  opacity: 0;
}

.router-anim-leave-active {
  animation: going 0.4s;
}

@keyframes going {
  from {
    scale: 1;
    opacity: 1;
  }
  to {
    scale: 0.7;
    opacity: 0.1;
  }
}

@keyframes coming {
  from {
    scale: 0.7;
    opacity: 0.1;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}
</style>