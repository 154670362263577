<template>
  <Molde title="Financeiro" :options="options" >
    <div style="min-height: 90vh">
        <div class="expande-horizontal semquebra">
            <v-flex class="pa-1"  style="cursor: pointer;" xs12 md4>
				<div style="width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center; background-size: 100% 200px; border-radius: 6px;">
					<v-card color="grey darken-3" hover ripple class="pa-2" style="width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center;" height="150" >
						<v-icon size="40" class="mb-4" color="green"> monetization_on </v-icon>
						<h5 style="text-shadow: 0 0 6px #000, 0 0 5px #00;color: #fff; font-size: 15pt" class="fonteCorpo green--text">{{ $helper.formataSaldo(total_entradas) }}</h5>
						<h5 style="text-shadow: 0 0 6px #000, 0 0 5px #00;color: #fff; font-size: 15pt" class="fonteCorpo text-center grey--text">ENTRADAS</h5>
					</v-card>
				</div>
			</v-flex>
            <v-flex class="pa-1"  style="cursor: pointer;" xs12 md4>
				<div style="width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center; background-size: 100% 200px; border-radius: 6px;">
					<v-card color="grey darken-3" hover ripple class="pa-2" style="width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center;" height="150" >
						<v-icon size="40" class="mb-4" color="red"> monetization_on </v-icon>
						<h5 style="text-shadow: 0 0 6px #000, 0 0 5px #00;color: #fff; font-size: 15pt" class="fonteCorpo red--text">{{ $helper.formataSaldo(total_saidas) }}</h5>
						<h5 style="text-shadow: 0 0 6px #000, 0 0 5px #00;color: #fff; font-size: 15pt" class="fonteCorpo text-center grey--text">SAÍDAS</h5>
					</v-card>
				</div>
			</v-flex>
            <v-flex class="pa-1"  style="cursor: pointer;" xs12 md4>
				<div style="width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center; background-size: 100% 200px; border-radius: 6px;">
					<v-card color="grey darken-3" hover ripple class="pa-2" style="width: 100%; flex-direction: column; display: flex;justify-content: center; align-items: center;" height="150" >
						<v-icon size="40" class="mb-4" color="green"> check </v-icon>
						<h5 style="text-shadow: 0 0 6px #000, 0 0 5px #00;color: #fff; font-size: 15pt" class="fonteCorpo green--text">{{ $helper.formataSaldo(total_saldo) }}</h5>
						<h5 style="text-shadow: 0 0 6px #000, 0 0 5px #00;color: #fff; font-size: 15pt" class="fonteCorpo text-center grey--text">SALDO</h5>
					</v-card>
				</div>
			</v-flex>
        </div>

        <v-flex class="pa-2" xs12>
            <v-text-field :hint="`${dados.length} encontrados.`" v-model="pesquisa" outlined="" label="Pesquise uma descrição" :color="$primaria" append-icon="search"></v-text-field>
        </v-flex>
        <v-simple-table v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
            <template v-slot:default>
            <thead>
                <tr>
                <th><v-icon :color="$primaria">monetization_on</v-icon></th>
                <th>TIPO</th>
                <th>VALOR</th>
                <th>DESCRIÇÃO</th>
                <th>QUANDO</th>
                <th>EXCLUIR</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in dados" :key="item._id" class="animated fadeInUp">
                <td style="width: 20px"> <v-icon :style="`color: ${item.origem === 'entrada' ? '#090' : '#900'}`">monetization_on</v-icon> </td>
                <td style="width: 20px" class="font-weight-bold fonteCorpo">{{ item.origem }}</td>
                <td :style="`color: ${item.origem === 'entrada' ? '#090' : '#900'}`" class="font-weight-bold fonteCorpo"> {{ item.origem === 'entrada' ? '+' : '-' }} R$ {{ item.valor }}</td>
                <td class="font-weight-bold fonteCorpo"> {{ item.descricao }}</td>
                <td class="font-weight-bold fonteCorpo">{{ $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                <td style="width: 20px"> <v-btn outlined class="text-lowercase" color="red" @click="remover(item._id, false)"> excluir</v-btn> </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <Empty v-if="dados.length === 0" ></Empty>
        <v-divider></v-divider>
        <v-list three-line class="pa-0 ma-0" v-if="dados.length > 0 && $vuetify.breakpoint.smAndDown">
            <template v-for="(item, index) in dados">
            <v-list-item class="animated fadeInUp" :key="item._id">
                <v-list-item-content>
                <v-list-item-title class="fonteCorpo" :style="`color: ${item.origem === 'entrada' ? '#090' : '#900'}`"> {{ item.origem === 'entrada' ? '+' : '-' }} R$ {{ item.valor }} </v-list-item-title>
                <v-list-item-subtitle class="fonteCorpo grey--text"> {{ item.descricao }} </v-list-item-subtitle>
                <v-list-item-subtitle class="fonteCorpo grey--text"> {{ $moment(item.created_at).locale('pt-br').format('LLLL') }} </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                <v-icon :color="$secundaria">check</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
            </template>
        </v-list>
    </div>
    <Confirm :open="confirm_dialog" :action="() => this.remover(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" />
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'attach_money',
                nome: 'Adicionar',
                action: () => this.$router.push('/home/criar-financeiro')    
            }
        ],
        dados: [],
        dadosbkp: [],
        entradas: [],
        saidas: [],
        total_entradas: '...',
        total_saidas: '...',
        confirm_dialog: false,
        id_to_excluse: '',
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  methods: {
    async get() {
      const data = await this.$list('financeiro')
      this.dados = data
      this.dadosbkp = data
      this.calculaEntradaSaida(data)
    },
    calculaEntradaSaida(dados) {
        let entrada = []
        let saida = []

        let total_entrada = 0
        let total_saida = 0
        
        dados.map(item => {
            if(item.origem === 'entrada') {
                entrada.push(item)
                total_entrada += parseFloat(item.valor.replace(',', '.'))
            } else {
                saida.push(item)
                total_saida += parseFloat(item.valor.replace(',', '.'))
                
            }
        })

        this.entradas = entrada
        this.saidas = saida
        this.total_entradas = total_entrada
        this.total_saidas = total_saida
        this.total_saldo = total_entrada - total_saida
    },
    async remover(id, confirmacao) {
      if(confirmacao) {
        await this.$del(`financeiro/${id}`)
        await this.get()
      } else {
        this.id_to_excluse = id
        this.confirm_dialog = true
      }
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.descricao.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    }
  },
  async created() {
      await this.get()
  }
}
</script>