<template>
    <div>
        <Molde title="Cadastro de cliente" icon="description" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">

                        <div
                            class="expande-horizontal centraliza"
                        >
                            <v-flex xs12 md4>
                                <v-autocomplete
                                    v-model="dados.cliente_id"
                                    :items="clientes"
                                    item-text="nome"
                                    item-value="_id"
                                    no-data-text="Carregando..."
                                    label="Selecione o cliente"
                                    outlined
                                    dense
                                ></v-autocomplete>
                            </v-flex>
                        </div>

                        <v-flex xs12>
                            <div class="expande-horizontal centraliza comquebra" v-if="dados.cliente_id !== '' ? true : false">
                                <v-btn @click="abre_envio_arquivo('PROJETO')" color="blue" class="ma-1" dark> <v-icon color="white"> upload </v-icon> ENVIAR PROJETO</v-btn>
                                <v-btn @click="abre_envio_arquivo('PROTOCOLO_DE_ENTRADA_CREA')" color="blue" class="ma-1" dark> <v-icon color="white"> upload </v-icon> PROTOCOLO DE ENTRADA CREA</v-btn>
                                <v-btn @click="abre_envio_arquivo('PROTOCOLO_DE_SAIDA_CREA')" color="blue" class="ma-1" dark> <v-icon color="white"> upload </v-icon> PROTOCOLO DE SAÍDA CREA</v-btn>
                                <v-btn @click="abre_envio_arquivo('PROTOCOLO_DE_ENTRADA_CEA')" color="blue" class="ma-1" dark> <v-icon color="white"> upload </v-icon> PROTOCOLO DE ENTRADA CEA</v-btn>
                                <v-btn @click="abre_envio_arquivo('PROTOCOLO_DE_SAIDA_CEA')" color="blue" class="ma-1" dark> <v-icon color="white"> upload </v-icon> PROTOCOLO DE SAÍDA CEA</v-btn>
                            </div>
                        </v-flex>

                        <!-- <div class="expande-horizontal centraliza mt-6">
                            <v-btn
                                @click="save" 
                                class=" white--text ml-2" 
                                color="green">
                                    <span>Salvar Alterações</span>
                            </v-btn>
                        </div> -->


                    </v-form>
                </v-flex>
            </div>  
        </Molde>
        <EnvioArquivo :fechar="() => this.fechar_envio_arquivo()" :link_arquivo="(link) => recebe_link_arquivo(link)" :abrir="abrir_envio_arquivo" />

    </div>
</template>

<script>
export default {
    data() {
        return {
            abrir_envio_arquivo: false,
            documento_em_envio: false,
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {
                cliente_id: '',
            },
            clientes: []
        }
    },
    methods: {
        save() {
            if(this.$refs.form.validate()) {
                this.$store.dispatch('up_charging')
                this.$axios.post('/cliente_documento', this.dados)
                    .then(res => {
                        this.$store.dispatch('down_charging')
                        this.$store.dispatch("snackbar_success", "Enviado com sucesso, confira no cadastro do cliente, na aba DOCUMENTOS RECEBIDOS")
                        this.fechar_envio_arquivo()
                    })
                    .catch(e => {
                        this.$store.dispatch('down_charging')
                        console.log(e)
                    })
                
            }
        },
        abre_envio_arquivo(doc) {
            this.documento_em_envio = doc
            this.abrir_envio_arquivo = true
        },
        fechar_envio_arquivo() {
            this.documento_em_envio = false
            this.abrir_envio_arquivo = false
            this.$store.dispatch("down_charging")
        },
        recebe_link_arquivo(link_imagem) {
            this.dados.url = link_imagem
            this.dados.documento = this.documento_em_envio
            this.save()
        },
    },
    async created() {
        this.clientes = await this.$list("cliente")
    }
}
</script>

<style>
    .arquivo_com_borda {
        display: flex;
        width: 100%;
        height: 300px;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        margin-bottom: 6px;
    }
</style>

