import Criar from '../../../components/home/projeto/Criar'
import Listar  from '../../../components/home/projeto/Listar.vue'
import Visualizar from '../../../components/home/projeto/Visualizar.vue'

export const projeto_home = [
    {
        path: '/home/criar-projeto',
        component: Criar
    },
    {
        path: '/home/listar-projeto',
        component: Listar
    },
    {
        path: '/home/visualizar-projeto/:id',
        component: Visualizar,
        props: true
    },
]

export default {}