<template>
    <div style="min-height: 90vh" class="pa-2">
      <v-flex class="pa-2" xs12>
        <!-- <v-text-field :hint="`${dados.length} dense encontrados.`" dense v-model="pesquisa" outlined="" label="" :color="$primaria" append-icon="search"></v-text-field> -->
      </v-flex>
      <v-simple-table class="item-table" dense v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <th>CLIENTE</th>
              <th>ENDEREÇO</th>
              <th>PERÍODO DE EXECUÇÃO</th>
              <th>DESCRIÇÃO</th>
              <th>STATUS</th>
              <!-- <th>EXCLUIR</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dados" :key="item._id" class="animated fadeInUp">
              <td @click="$router.push(`/home/visualizar-vistoria/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.cliente.nome }}</td>
              <td @click="$router.push(`/home/visualizar-vistoria/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.endereco }}</td>
              <td @click="$router.push(`/home/visualizar-vistoria/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.periodo_inicio }} - {{ item.periodo_fim }}</td>
              <td @click="$router.push(`/home/visualizar-vistoria/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.descricao }}</td>
              <td @click="$router.push(`/home/visualizar-vistoria/${item._id}`)" class="font-weight-bold fonteCorpo">{{ item.status }}</td>
              <!-- <td> <v-btn outlined class="text-lowercase" color="red" @click="remover(item._id, false, item.responsavel._id)"> excluir</v-btn> </td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-else ></Empty>
    </div>
</template>

<script>
export default {
    props: ['cliente_id'],
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'add',
                nome: 'Nova O.S',
                action: () => this.$router.push('/home/criar-ordem')    
            }
        ],
        dados: [],
        dadosbkp: []
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  methods: {
    async get() {
      const data = await this.$list('ordem/cliente/'+this.cliente_id)
      this.dados = data
      this.dadosbkp = data
    },
    async remover(id) {
      await this.$del(`ordem/${id}`)
      await this.get()
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.cliente.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    }
  },
  async created() {
      await this.get()
  }
}
</script>