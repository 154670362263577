import Vue from "vue";
import Router from "vue-router";

/* Views aqui */
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import Cliente from "@/views/Cliente.vue";
import Cadastro from "@/views/Cadastro.vue";
import Orcamento from "@/views/Orcamento.vue";

//home
import { funcionario_home } from './home/funcionario'
import { cliente_home } from './home/cliente'
import { contrato_home } from './home/contrato'
import { orcamento_home } from './home/orcamento'
import { carro_home } from './home/carro'
import { ordem_home } from './home/ordem'
import { material_home } from './home/material'
import { equipamento_home } from './home/equipamento'
import { executar_os_home } from './home/executar_os'
import { permissao_home } from './home/permissao'
import { mapa_home } from './home/mapa'
import { painel_home } from './home/painel'
import { log_home } from './home/log'
import { financeiro_home } from './home/financeiro'
import { posvenda_home } from './home/posvenda'
import { suporte_ticket } from './home/ticket'
import { notificacao_home } from './home/notificacao'
import { despesa_fixa_home } from './home/despesa_fixa'
import { despesa_variavel_home } from './home/despesa_variavel'
import { receita_home } from './home/receita'
import { relatorio_home } from './home/relatorio'
import { despesa_categoria_home } from './home/despesa_categoria'
import { kit_home } from './home/kit'
import { financiamento_home } from './home/financiamento'
import { comparativo_home } from './home/comparativo'
import { unidade_home } from './home/unidade'
import { curso_home } from './home/curso'
import { projeto_home } from './home/projeto'
import { vistoria_home } from './home/vistoria'

//cliente
import { contrato_cliente } from './cliente/contrato'
import { ordem_cliente } from './cliente/ordem'
import { cliente_ticket } from './cliente/ticket'
import { perfil_cliente } from './cliente/perfil'

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "login",
      component: Login
    },
    {
      path: "/orcamento",
      name: "Orçamento",
      component: Orcamento
    },
    {
      path: "/cadastro",
      name: "cadastro",
      component: Cadastro
    },
    {
      path: "/cadastro/:tipo",
      name: "cadastro",
      component: Cadastro,
      props: true
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      children: [
        ...funcionario_home,
        ...cliente_home,
        ...contrato_home,
        ...orcamento_home,
        ...carro_home,
        ...ordem_home,
        ...material_home,
        ...equipamento_home,
        ...executar_os_home,
        ...permissao_home,
        ...mapa_home,
        ...painel_home,
        ...log_home,
        ...financeiro_home,
        ...posvenda_home,
        ...suporte_ticket,
        ...notificacao_home,
        ...despesa_fixa_home,
        ...despesa_variavel_home,
        ...receita_home,
        ...relatorio_home,
        ...despesa_categoria_home,
        ...kit_home,
        ...financiamento_home,
        ...comparativo_home,
        ...unidade_home,
        ...curso_home,
        ...projeto_home,
        ...vistoria_home
      ]
    },
    {
      path: "/cliente",
      name: "cliente home",
      component: Cliente,
      children: [
        ...contrato_cliente,
        ...ordem_cliente,
        ...cliente_ticket,
        ...perfil_cliente
      ]
    },
  ]
});
