<template>
  <div>
    <v-navigation-drawer :style="`background: ${$colors.home_drawer}`" class="elevation-0" v-model="drawer" app clipped width="270">
      <v-card class="elevation-0" style="border-radius: 0px; margin-bottom: 0px;" :color="$colors.home_drawer">
        <div class="semQuebra expande-horizontal">
          <v-list :color="$colors.home_drawer" light style="width: 100%" class="pa-0 ma-0">
            <v-list-item @click="() => {}">
              <v-avatar size="60" color="white" class="elevation-0 mr-2 mt-2 mb-2">
                <v-img :src="$logo_mini"></v-img>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-capitalize" :class="$colors.dark ? 'white--text' : 'grey--text'">
                  {{ $store.getters.user.nome }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-uppercase" style="font-size: 10pt" :class="$colors.dark ? 'white--text' : 'grey--text'">
                  {{ $store.getters.user.tipo}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card>

      <v-list :dark="$colors.dark" style="border-radius: 0px" dense nav color="transparent" class="pa-1 ma-0 elevation-0">
        <v-list-group v-show="verificaPermissao(lista_pai)" :color="$colors.primaria" v-for="(lista_pai, index) in menu" :key="index" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-icon class="mr-2" size="18" :color="$colors.dark ? 'white' : $colors.primaria"> {{ lista_pai.icon }} </v-icon>
              <v-list-item-content>
                <v-list-item-title class="fonteCorpo"> {{ lista_pai.nome }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-expansion-panels dark v-for="(item, index) in  lista_pai.itens" :key="index/3" flat>
            <v-expansion-panel style="background: #333; margin: 2px;">
              <v-expansion-panel-header class="fonteCorpo white--text">
                {{ item.nome }}
              </v-expansion-panel-header>

              <v-expansion-panel-content v-for="(sub_item, index) in item.sub_itens" :key="index/4">
                <h3 class="orange--text"> {{ sub_item.nome }} </h3>

                <v-list-item v-for="(route, index) in sub_item.routes" :key="index/6" active-class="menuAtivoHome" :to="route.path">
                  <v-list-item-content>
                    <v-list-item-title :class="$route.path === route.path ? 'white--text' : 'white--text'" class="fonteCorpo font-weight-bold" >
                      {{ route.nome }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list-group>


        <v-list-item class="mt-6" @click="sair" active-class="menuAtivoHome">
          <v-icon class="mx-2" :color="$colors.danger">swap_horizontal_circle</v-icon>
          <v-list-item-content >
            <v-list-item-title class="fonteCorpo" :class="$colors.dark ? 'white--text' : 'grey--text'"> Sair </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <transition name="router-anim" >
        <router-view></router-view>
      </transition>
    </v-content>
  </div>
</template>

<script>
export default {
  props: {
    source: String
  },
  data() {
    return {
      demo: {
        nome: '',
        itens: [
          {
            nome: '',
            sub_itens: [
              {
                nome: '',
                routes: [
                  {
                    nome: '',
                    path: ''
                  }
                ]
              }
            ]
          }
        ]
      },
      menu: [
        {
          nome: 'Diretoria',
          show: ['diretoria','ativo'],
          icon: 'work',
          itens: [
            {
              nome: 'Notificações',
              show: ['diretoria','notificacao'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Ver',
                      path: '/home/listar-notificacao'
                    },
                    {
                      nome: 'Criar Nova',
                      path: '/home/criar-notificacao'
                    },
                  ]
                }
              ],
              
            },
            {
              nome: 'Visualizações de Mapa',
              show: ['administracao','visualizar_mapa'],
              sub_itens: [
                {
                  nome: 'Fluxo de Mapa',
                  routes: [
                    {
                      nome: 'Mapa de O.S',
                      path: '/home/listar-mapa'
                    }
                  ]
                }
              ]
            },
            {
              nome: 'Departamentos',
              show: ['administracao','departamento'],
              sub_itens: [
                {
                  nome: 'Fluxo',
                  routes: [
                    {
                      nome: 'Cadastrar Departamento',
                      path: '/home/criar-permissao'
                    },
                    {
                      nome: 'Ver Departamentos',
                      path: '/home/listar-permissao'
                    },
                  ]
                }
              ]
            },
            {
              nome: 'Unidades',
              show: ['administracao','unidade'],
              sub_itens: [
                {
                  nome: 'Fluxo de Unidades',
                  routes: [
                    {
                      nome: 'Cadastrar Unidade',
                      path: '/home/criar-unidade'
                    },
                    {
                      nome: 'Ver Todas as Unidades',
                      path: '/home/listar-unidade'
                    },
                  ]
                }
              ]
            },
            // {
            //   nome: 'Relatórios',
            //   sub_itens: [
            //     {
            //       nome: 'Vendas',
            //       routes: [
            //         {
            //           nome: 'Pesquisa Personalizada',
            //           path: '/home/listar-venda-relatorio'
            //         },
            //         {
            //           nome: 'Relatório Da Semana',
            //           path: '/home/listar-venda-relatorio/semana'
            //         },
            //         {
            //           nome: 'Relatório Do Mês',
            //           path: '/home/listar-venda-relatorio/mes'
            //         },
            //         {
            //           nome: 'Relatório Do Ano',
            //           path: '/home/listar-venda-relatorio/ano'
            //         },
            //       ]
            //     }
            //   ]
            // },
          ]
        },
        {
          nome: 'Administração',
          show: ['administracao','ativo'],
          icon: 'score',
          itens: [
            {
              nome: 'Notificações',
              show: ['administracao','notificacao'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Ver',
                      path: '/home/listar-notificacao'
                    },
                    {
                      nome: 'Criar Nova',
                      path: '/home/criar-notificacao'
                    },
                  ]
                }
              ],
              
            },
            {
              nome: 'Atendimento',
              show: ['administracao/atendimento'],
              sub_itens: [
                {
                  nome: 'Clientes',
                  routes: [
                    {
                      nome: 'Listar',
                      path: '/home/listar-cliente'
                    },
                    {
                      nome: 'Cadastrar Novo',
                      path: '/home/criar-cliente'
                    },
                  ]
                },
                {
                  nome: 'Colaboradores',
                  routes: [
                    {
                      nome: 'Listar',
                      path: '/home/listar-funcionario'
                    },
                    {
                      nome: 'Cadastrar Novo',
                      path: '/home/criar-funcionario'
                    },
                  ]
                },
              ]
            },
             {
              nome: 'Receitas',
              show: ['gestao','receita'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Listar Todas',
                      path: '/home/listar-receita'
                    }
                  ]
                }
              ]
            },
            {
              nome: 'Categoria de Despesa',
              show: ['gestao','categoria_despesa'],
              sub_itens: [
                {
                  nome: "",
                  routes: [
                    {
                      nome: 'Ver Categorias',
                      path: '/home/listar-despesa-categoria'
                    },
                    {
                      nome: 'Cadastrar Categoria',
                      path: '/home/criar-despesa-categoria'
                    }
                  ]
                }
              ],
            },
            {
              nome: 'Despesa Fixa',
              show: ['gestao','despesa_fixa'],
              sub_itens: [
                {
                  nome: "",
                  routes: [
                    {
                      nome: 'Listar',
                      path: '/home/listar-despesa-fixa'
                    },
                    {
                      nome: 'Criar Nova',
                      path: '/home/criar-despesa-fixa'
                    },
                  ]
                }
              ],
            },
            {
              nome: 'Despesa Variável',
              show: ['gestao','despesa_variavel'],
              sub_itens: [
                {
                  nome: "",
                  routes: [
                    {
                      nome: 'Listar',
                      path: '/home/listar-despesa-variavel'
                    },
                    {
                      nome: 'Criar Nova',
                      path: '/home/criar-despesa-variavel'
                    },
                  ]
                }
              ],
            },
            {
              nome: 'Comparativo',
              show: ['gestao','comparativo'],
              sub_itens: [
                {
                  nome: "",
                  routes: [
                    {
                      nome: 'Relatório',
                      path: '/home/listar-comparativo'
                    }
                  ]
                }
              ]
            }
          ]
        },
        // {
        //   nome: 'Gestão',
        //   show: ['gestao','ativo'],
        //   icon: 'assignment_turned_in',
        //   itens: [
        //     {
        //       nome: 'Receitas',
        //       show: ['gestao','receita'],
        //       sub_itens: [
        //         {
        //           nome: '',
        //           routes: [
        //             {
        //               nome: 'Listar Todas',
        //               path: '/home/listar-receita'
        //             }
        //           ]
        //         }
        //       ]
        //     },
        //     {
        //       nome: 'Categoria de Despesa',
        //       show: ['gestao','categoria_despesa'],
        //       sub_itens: [
        //         {
        //           nome: "",
        //           routes: [
        //             {
        //               nome: 'Ver Categorias',
        //               path: '/home/listar-despesa-categoria'
        //             },
        //             {
        //               nome: 'Cadastrar Categoria',
        //               path: '/home/criar-despesa-categoria'
        //             }
        //           ]
        //         }
        //       ],
        //     },
        //     {
        //       nome: 'Despesa Fixa',
        //       show: ['gestao','despesa_fixa'],
        //       sub_itens: [
        //         {
        //           nome: "",
        //           routes: [
        //             {
        //               nome: 'Listar',
        //               path: '/home/listar-despesa-fixa'
        //             },
        //             {
        //               nome: 'Criar Nova',
        //               path: '/home/criar-despesa-fixa'
        //             },
        //           ]
        //         }
        //       ],
        //     },
        //     {
        //       nome: 'Despesa Variável',
        //       show: ['gestao','despesa_variavel'],
        //       sub_itens: [
        //         {
        //           nome: "",
        //           routes: [
        //             {
        //               nome: 'Listar',
        //               path: '/home/listar-despesa-variavel'
        //             },
        //             {
        //               nome: 'Criar Nova',
        //               path: '/home/criar-despesa-variavel'
        //             },
        //           ]
        //         }
        //       ],
        //     },
        //     {
        //       nome: 'Comparativo',
        //       show: ['gestao','comparativo'],
        //       sub_itens: [
        //         {
        //           nome: "",
        //           routes: [
        //             {
        //               nome: 'Relatório',
        //               path: '/home/listar-comparativo'
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          nome: 'Técnico',
          show: ['tecnico','ativo'],
          icon: 'build',
          itens: [
            {
              nome: 'Notificações',
              show: ['tecnico','notificacao'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Ver',
                      path: '/home/listar-notificacao'
                    },
                    {
                      nome: 'Criar Nova',
                      path: '/home/criar-notificacao'
                    },
                  ]
                }
              ],
              
            },
            {
              nome: 'Projetos',
              show: ['tecnico','projeto'],
              sub_itens: [
                {
                  // nome: 'Cliente',
                  routes: [
                    {
                      nome: ' Envio e Protocolação',
                      path: '/home/criar-projeto',
                    }
                  ]
                },
              ]
            },
            {
              nome: 'Ordem de Serviço',
              show: ['tecnico','ordem_de_servico'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Gerar O.S',
                      path: '/home/criar-ordem'
,                   },
                    {
                      nome: 'Ver Todas as O.S',
                      path: '/home/listar-ordem'
,                    },
                  ]
                }
                ]
              },
              {
                nome: 'Vistoriar',
                sub_itens: [
                  {
                    nome: '',
                    routes: [
                      {
                        nome: 'Listar',
                        path: '/home/listar-vistoria'
  ,                    },
                    ]
                  }
                ]
              }
            // {
            //   nome: 'Cadastro',
            //   sub_itens: [
            //     // {
            //     //   nome: 'Produto',
            //     //   routes: [
            //     //     {
            //     //       nome: 'Cadastrar',
            //     //       path: '/home/criar-produto',
            //     //     },
            //     //     {
            //     //       nome: 'Ver Cadastrados',
            //     //       path: '/home/listar-produto'
            //     //     }
            //     //   ]
            //     // },
            //     // {
            //     //   nome: 'Matéria Prima',
            //     //   routes: [
            //     //     {
            //     //       nome: 'Cadastrar Nova',
            //     //       path: '/home/criar-material',
            //     //     },
            //     //     {
            //     //       nome: 'Ver Cadastradas',
            //     //       path: '/home/listar-material'
            //     //     }
            //     //   ]
            //     // },
            //     // {
            //     //   nome: 'Equipamentos',
            //     //   routes: [
            //     //     {
            //     //       nome: 'Cadastrar Equipamento',
            //     //       path: '/home/criar-equipamento'
            //     //     },
            //     //     {
            //     //       nome: 'Ver Equipamentos',
            //     //       path: '/home/listar-equipamento'
            //     //     },
            //     //   ]
            //     // },
            //     // {
            //     //   nome: 'Carros',
            //     //   routes: [
            //     //     {
            //     //       nome: 'Cadastrar Novo',
            //     //       path: '/home/criar-carro'
            //     //     },
            //     //     {
            //     //       nome: 'Ver Todos',
            //     //       path: '/home/listar-carro'
            //     //     }
            //     //   ]
            //     // }
            //   ]
            // }
          ]
        },
        {
          nome: 'Operacional',
          show: ['operacional','ativo'],
          icon: 'rule',
          itens: [
            {
              nome: 'Notificações',
              show: ['operacional','notificacao'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Ver',
                      path: '/home/listar-notificacao'
                    },
                    {
                      nome: 'Criar Nova',
                      path: '/home/criar-notificacao'
                    },
                  ]
                }
              ],
              
            },
            {
              nome: 'Executor de O.S',
              show: ['operacional','executar_os'],
              sub_itens: [
                {
                  nome: 'Execução de O.S',
                  routes: [
                    {
                      nome: 'Minhas O.S',
                      path: '/home/listar-executar_os'
                    }
                  ]
                }
              ],
            }
          ]
        },
        {
          nome: 'Vendas',
          show: ['venda','ativo'],
          icon: 'mdi-basket',
          itens: [
            // {
            //   nome: 'Orçamento',
            //   sub_itens: [
            //     {
            //       nome: 'Fluxo de Orçamento',
            //       routes: [
            //         {
            //           nome: 'Criar Orçamento',
            //           path: '/home/criar-orcamento'
            //         },
            //         {
            //           nome: 'Orçamentos Realizados',
            //           path: '/home/listar-orcamento'
            //         },
            //         {
            //           nome: 'Kits KwP (Arranjos)',
            //           path: '/home/listar-kit'
            //         },
            //         {
            //           nome: 'Cadastrar Novo Kit',
            //           path: '/home/criar-kit'
            //         },
            //         {
            //           nome: 'Tipos de Financiamento',
            //           path: '/home/listar-financiamento'
            //         },
            //         {
            //           nome: 'Cadastrar Tipo de Financiamento',
            //           path: '/home/criar-financiamento'
            //         },
            //       ]
            //     }
            //   ]
            // },
            {
              nome: 'Notificações',
              show: ['vendas','notificacao'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Ver',
                      path: '/home/listar-notificacao'
                    },
                    {
                      nome: 'Criar Nova',
                      path: '/home/criar-notificacao'
                    },
                  ]
                }
              ],
              
            },
            {
              nome: 'Proposta',
              show: ['venda','proposta'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Listar',
                      path: '/home/listar-contrato'
                    },
                    {
                      nome: 'Criar Nova',
                      path: '/home/criar-contrato'
                    },
                    // {
                    //   nome: 'Convertidos',
                    //   path: '/home/listar-convertido' // uma proposta que virou uma venda
                    // },
                    // {
                    //   nome: 'Comissões',
                    //   path: '/home/listar-comissao' // uma proposta que gerou uma comissão
                    // },
                  ]
                }
              ]
            },
            {
              nome: 'Kit Fotovoltaico',
              show: ['venda','kit_fotovoltaico'],
              sub_itens: [
                {
                  nome: '',
                  routes: [
                    {
                      nome: 'Cadastrar Novo',
                      path: '/home/criar-kit',
                    },
                    {
                      nome: 'Listar',
                      path: '/home/listar-kit',
                    },
                  ]
                }
              ]
            },
            // {
            //   nome: 'Pós-Venda',
            //   sub_itens: [
            //     {
            //       nome: 'Fluxo',
            //       routes: [
            //         {
            //           nome: 'Visitas Agendadas',
            //           path: '/home/listar-pos-venda'
            //         },
            //         {
            //           nome: 'Agendar Manualmente',
            //           path: '/home/criar-pos-venda'
            //         }
            //       ]
            //     }
            //   ]
            // },
          ]
        },
      ],
      permissao: {
        diretoria: {
            ativo: false,
            visualizar_mapa: false,
            departamento: false,
            unidade: false
        },
        administracao: {
            ativo: false,
            atendimento: false
        },
        gestao: {
            ativo: false,
            receita: false,
            categoria_despesa: false,
            despesa_fixa: false,
            despesa_variavel: false,
            comparativo: false
        },
        tecnico: {
            ativo: false,
            projeto: false,
            ordem_de_servico: false,
            vistoriar: false,
        },
        operacional: {
            ativo: false,
            notificacao: false,
            executar_os: false,
        },
        venda: {
            ativo: false,
            proposta: false,
            kit_fotovoltaico: false,
        },
      }
    }
  },
  computed: {
    drawer() {
      return this.$store.getters.drawer
    },
  },
  methods: {
    verificaPermissao(menu) {
      let pedaco = this.permissao
      
      menu.show.map(item => {
        pedaco = pedaco[item]
        return pedaco
      })

      return pedaco
    },
    sair() {
      sessionStorage.clear()
      this.$store.dispatch('set_token', '')
      this.$store.dispatch('set_perfil', {})
      this.$router.push('/')
    },

    async verificaUsuarioLogado() {
      if(sessionStorage.perfil === undefined) {
        this.$router.push('/')
      } else {
        this.permissao = await this.$show(`permissao/${JSON.parse(sessionStorage.perfil).user.permissao_id}`)
      }
    }
  },
  mounted() {
    const self = this
    cordova.plugins.firebase.messaging.requestPermission()
      .then(function() {
        cordova.plugins.firebase.messaging.getToken()
          .then(function(token) {
              self.$axios.put(`/funcionario/${self.$store.getters.user._id}`, {
                token_push: token,
                plataforma: navigator.userAgent
              })
                .then(() => {
                  self.$store.dispatch("snackbar_success", 'Sucesso')
                })
                .catch(e => {
                  self.$store.dispatch("snackbar_success", 'erro'+e)
                })
          })
          .catch(e => {
            self.$store.dispatch("snackbar_success", 'erro no messag'+e)
          })
    })
    .catch(e => {
      self.$store.dispatch("snackbar_success", 'erro no messag'+e)
    })
    // navigator.geolocation.getCurrentPosition()
  },
  created() {
    this.verificaUsuarioLogado()
  }
};
</script>

<style>
#keep .v-navigation-drawer__border {
  display: none;
}

::-webkit-scrollbar {
  width: 2px;
  border-radius: 10px;
  background: rgba(180, 179, 179, 0);
}
::-webkit-scrollbar-thumb {
  background: #F9A825;
  border-radius: 10px;
}

.menuAtivo {
  color: #ffffff;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: bold;
}

  .router-anim-enter-active {
  animation: coming 0.4s;
  animation-delay: 0.4s;
  opacity: 0;
}

.router-anim-leave-active {
  animation: going 0.4s;
}

@keyframes going {
  from {
    scale: 1;
    opacity: 1;
  }
  to {
    scale: 0.7;
    opacity: 0.1;
  }
}

@keyframes coming {
  from {
    scale: 0.7;
    opacity: 0.1;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}
</style>