<template>
  <v-dialog width="800" transition="slide-x-transition" elevation-0 persistent v-model="abrir">
    <Molde title="Anexar Documento" :options="options" icon="arrow_back" :action="() => this.fechar()">
      <div class="pa-1">
        <v-form ref="form">
            <div v-for="(item, index) in documentos" :key="index" class="animated flixInX expande-horizontal semquebra">
              <v-flex class="pa-1" xs8>
                <v-text-field ref="documento" clearable :rules="[v => !!v || 'Preencha este campo primeiro']" hint="ex: Descrição do documento" label="Nome do documento" outlined :color="$primaria" v-model="documento"></v-text-field>
              </v-flex>

              <v-flex xs4 class="pa-1">
                <input type="file" @change="uploadArquivo"/>
              </v-flex>
            </div>
        </v-form>
        <div class="pb-3 expande-horizontal centraliza">
          <v-btn @click="addNovoDocumento" class="mr-4 white--text" small :color="$primaria" rounded>
            Adicionar outro documento
            <v-icon>add</v-icon>
          </v-btn>
          <v-btn class="white--text" small rounded color="green">
            Já terminei, enviar
            <v-icon>check</v-icon>
          </v-btn>
        </div>
      </div>
    </Molde>
  </v-dialog>
</template>

<script>
  import { mask } from "vue-the-mask";
  import { storage } from '../../../../services/Firebase'
  
  export default {
    props: ['id', 'abrir', 'fechar'],
    directives: {
      mask
    },
    data() {
      return {
        documentos: [
          {
            documento: '',
            url: '',
            status: ''
          }
        ],
        options: [
          {
            icon: 'send',
            nome: "Enviar documentos",
            action: () => {}
          }
        ]
      };
    },
    methods: {
      uploadArquivo(evt) {
        this.$store.dispatch('up_charging')
        evt.stopPropagation();
        evt.preventDefault();
        var file = evt.target.files[0];

        var metadata = {
          'contentType': file.type
        };

        const self = this

        storage.ref('images').child(file.name).put(file, metadata).then(function(snapshot) {
          console.log('Uploaded', snapshot.totalBytes, 'bytes.');
          console.log('File metadata:', snapshot.metadata);
          // Let's get a download URL for the file.
          snapshot.ref.getDownloadURL().then(function(url) {
            // [START_EXCLUDE]
            self.url = url
            self.enviarDocumento()
            //this.pagarCota()
            // [END_EXCLUDE]
          });
        }).catch(function(error) {
          // [START onfailure]
          console.error('Upload failed:', error);
          // [END onfailure]
        });
      },

      enviarDocumento() {
        if(this.$refs.form.validate()) {
            const data = {
              url: this.url,
              documento: this.documento,
              contrato_id: this.id
            }
    
            this.$axios.post("/contrato_documento", data)
              .then(() => {
                this.$store.dispatch('down_charging')
                this.$store.dispatch('snackbar_success', 'O documento foi enviado e o formulário limpo para o envio de um novo documendo, ao terminar o envio de documentos, clique em fechar.')
                this.documento = ''
                this.url = ''
              })
              .catch(() => {
                this.$store.dispatch('down_charging')
                this.$store.dispatch('snackbar_error', 'Erro, tente novamente')
                this.$store.dispatch('fecha_pagamento')
              })
        }
      },

      addNovoDocumento() {
        this.documentos.push({
          documento: '',
          url: '',
          status: ''
        })
      }
    }
  };
</script>