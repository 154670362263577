<template>
    <div>
        <Molde title="Visualizar O.S." icon="description" :options="options">
            <v-tabs show-arrows :background-color="$primaria" color="white" v-model="tab">
                <v-tab>Informações da O.S.</v-tab>
                <!-- <v-tab @click="get(`ordem_material`, id)">Matéria Prima</v-tab>
                <v-tab @click="get('ordem_equipamento', id)">Equipamento</v-tab>
                <v-tab @click="get('ordem_carro', id)">Carro</v-tab>
                <v-tab @click="get('ordem_funcionario', id)">Funcionário</v-tab>
                <v-tab @click="get('ordem_diario_bordo', id)">Andamento</v-tab>
                <v-tab>Horário de início e encerramento</v-tab> -->
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <div class="expande-horizontal centraliza">
                        <v-flex xs12 md6 class="pa-3 ">
                            <v-form ref="form">
                                <div class="expande-horizontal semquebra">
                                    <v-autocomplete
                                        dense
                                        :rules="[v => !!v || 'Preencha aqui']"
                                        class="mr-2 ml-2"
                                        :color="$primaria"
                                        outlined
                                        v-model="responsavel"
                                        :items="funcionario"
                                        label="Selecionar Instalador"
                                        return-object
                                        placeholder="ex: Ricardo"
                                        item-text="nome"
                                    />
                                </div>

                                <div class="expande-horizontal semquebra">
                                    <v-autocomplete
                                        dense
                                        :rules="[v => !!v || 'Selecione um cliente']"
                                        class="mr-2 ml-2"
                                        :color="$primaria"
                                        outlined
                                        v-model="cliente_selecionado"
                                        :items="cliente"
                                        label="Cliente"
                                        return-object
                                        placeholder="ex: Michel"
                                        item-text="nome"
                                    />
                                </div>

                                <v-flex xs12>
                                    <v-text-field :rules="[v => !!v || 'Preencha aqui']" dense :color="$primaria" class="mr-2 ml-2" v-model="dados.endereco" outlined required label="Endereço completo" />
                                </v-flex>
                                
                                <div class="expande-horizontal item-table mt-3 mb-3 pa-2 coluna">
                                    <h3 class="text-center mb-2">Período de Execução</h3>
                                    
                                    <div class="expande-horizontal">
                                        <v-flex xs12>
                                            <v-text-field :rules="[v => !!v || 'Preencha aqui']" dense v-mask="['##/##/####']" :color="$primaria" class="mr-2 ml-2" v-model="dados.periodo_inicio" outlined required label="Inicial" />
                                        </v-flex>

                                        <v-flex xs12>
                                            <v-text-field :rules="[v => !!v || 'Preencha aqui']" dense v-mask="['##/##/####']" :color="$primaria" class="mr-2 ml-2" v-model="dados.periodo_fim" outlined required label="Final" />
                                        </v-flex>
                                    </div>
                                </div>

                                <div class="expande-horizontal semquebra">
                                    <v-textarea :color="$primaria" :rules="[v => !!v || 'Preencha aqui']" class="mr-2 ml-2" v-model="dados.descricao" outlined required label="Descrição da O.S" />
                                </div>

                                <!-- <v-btn @click="save" class="text-capitalize white--text ml-2" color="orange"> SALVAR </v-btn> -->
                            </v-form>
                        </v-flex>
                    </div>
                </v-tab-item>

            </v-tabs-items>
            
        </Molde>
    </div>
</template>

<script>
export default {
    props: ['id'],
    data() {
        return {
            tab: 0,
            options: [
                // {
                //     icon: 'check',
                //     nome: 'Solicitar Vistoria',
                //     action: () => this.add_diario_de_bordo = true
                // },
                // {
				// 	icon: 'add',
				// 	nome: 'Carro',
				// 	action: () => this.add_carro = true
				// },
                // {
				// 	icon: 'add',
				// 	nome: 'Matéria Prima',
				// 	action: () => this.add_material = true
				// },
                // {
				// 	icon: 'add',
				// 	nome: 'Equipamento',
				// 	action: () => this.add_equipamento = true
				// },
                // {
				// 	icon: 'add',
				// 	nome: 'Funcionário',
				// 	action: () => this.add_funcionario = true
				// },
                    // {
                    //     icon: "save",
                    //     nome: "Salvar Alterações",
                    //     action: () => this.save()
                    // }
            ],
            add_material: false,
            add_equipamento: false,
            add_carro: false,
            add_funcionario: false,
            add_diario_de_bordo: false,

            ordem_material: [],
            ordem_equipamento: [],
            ordem_carro: [],
            ordem_funcionario: [],
            ordem_diario_bordo: [],

            dados: {
                data: this.formatDate(new Date().toISOString().substr(0, 10))
            },
            cliente: [],
            cliente_selecionado: '',
            funcionario: [],

            responsavel: {},
            date: new Date().toISOString().substr(0, 10),
            menu: false,
        }
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
    watch: {
        cliente_selecionado(val) {
            this.dados.cliente = val
            this.dados.cliente_id = val._id
        },
        responsavel(val) {
            this.dados.responsavel = val
            this.dados.responsavel_id = val._id
        },
        date (val) {
            this.dados.data = this.formatDate(this.date)
        },
    },
    methods: {
        async get(collection, id) {
            this[`${collection}`] = await this.$list(`${collection}/${id}`)
        },
        async save() {
            if(this.$refs.form.validate()) {
                await this.$update(`ordem/${this.id}`, this.dados)
                this.$router.go(-1)
            }
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
    },
    async created() {
        const dados = await this.$show(`ordem/${this.id}`)
        this.dados = dados

        this.cliente = await this.$list('cliente')
        this.funcionario = await this.$list('funcionario')

        this.cliente_selecionado = dados.cliente
        this.responsavel = dados.responsavel
    }
}
</script>

