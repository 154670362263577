import moment from 'moment'
import 'moment/locale/pt-br'

class Helper {
  
    filterSaldo(val) {
        let valor_total = 0
        
        if(val.length > 0) {
          val.map(item => {
            if(item.valor > 0) {
              valor_total = parseFloat(valor_total) + parseFloat(item.valor)
            } else if(item.valor < 0) {
  
                const va = item.valor.toString().split('')
              let novo_menos = ''
              va.map((i, index) => {
                  if(index !== 0) {
                      novo_menos = novo_menos + i
                  }
              })
              valor_total = `${parseFloat(valor_total) - parseFloat(novo_menos)}`
  
            }
          })
        }
  
        return parseFloat(valor_total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    filterSaldoBruto(val) {
      let valor_total = 0
        
      if(val.length > 0) {
        val.map(item => {
          if(item.valor > 0) {
            valor_total = parseFloat(valor_total) + parseFloat(item.valor)
          } else if(item.valor < 0) {

              const va = item.valor.toString().split('')
            let novo_menos = ''
            va.map((i, index) => {
                if(index !== 0) {
                    novo_menos = novo_menos + i
                }
            })
            valor_total = `${parseFloat(valor_total) - parseFloat(novo_menos)}`

          }
        })
      }

      return parseFloat(valor_total)
    }
    
    filtroDePontos (pontos) {
      let total_de_pontos = 0

      pontos.map(ponto => {
        total_de_pontos += ponto.quantidade_de_pontos
      })

      return total_de_pontos

    }

    formataSaldo(saldo) {
      const saldo_convert = parseFloat(saldo)
      return saldo_convert.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }
    
    saldo_formatado(saldo) {
      return saldo.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }

    somaSaldos(saldo_rendimento, saldo_rede) {
      const rendimento = parseFloat(this.filterSaldoBruto(saldo_rendimento))
      const rede = parseFloat(this.filterSaldoBruto(saldo_rede))
      const saldo_somado = rendimento + rede
      return parseFloat(saldo_somado).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    proximoPagamento() {
      const hora = moment(`21:00`, "HH:mm").locale('pt-br').fromNow();
      const dia_de_hoje = moment().locale('pt-br').format('dddd')
      console.log('dia de hoje', dia_de_hoje)
      if(dia_de_hoje === 'Sábado') {
        return 'Em 2 dias'
      } else if(dia_de_hoje === 'Domingo') {
        return 'Em 1 dia'
      } else { 
        return hora
      }
    }
    
    // porcentagem até o pagamento
    porcentagemPagamento() {
      const base = moment().locale('pt-br').format('DDMMYYYY')
      // 1 dia em ms = 86400000
      const pagamento_hoje = moment(`${base}21:00`, "DDMMYYYYHH:mm").locale('pt-br').format('x')
      

      const um_por_cento = 86400000 / 100
      const ms_que_falta_ate_21_de_hoje = 86400000 - (pagamento_hoje - moment().locale('pt-br').format('x'))

      const porcentagem = ms_que_falta_ate_21_de_hoje / um_por_cento

      return porcentagem

    }

    filtroTetoCashBack(cotas) {
      let quantidade = 0
      if(cotas.length > 0) {
        cotas.map(i => {
            if(i.status === 'ativa' || i.status === 'ativando') {
              quantidade += parseInt(i.quantidade)
            }})
      }
      const valor_teto = quantidade * 200
      return parseFloat(valor_teto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    relatorioTotalInvestido(cotas) {
      let quantidade = 0

      if(cotas.length > 0) {
          cotas.map(i => {
            if(i.quantidade !== undefined) {
              quantidade += parseInt(i.quantidade)
            }
        })
      }

      const valor_teto = quantidade * 50
      return parseFloat(valor_teto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    relatorioTotalRendimento(cotas) {
          let quantidade = 0
            
          if(cotas.length > 0) {
            cotas.map(i => {
              if(i.quantidade !== undefined) {
                quantidade += parseInt(i.quantidade)
              }
          })
        }
        //o sistema vai tender 160%, então o total_que_sera_rendido equivale 1% do valor inicial e o total_que_sera_rendido_final é esse 1% multiplicado por 160, equivalente aos (160%)
        const total_que_sera_rendido = (quantidade * 50) / 100
        const total_que_sera_rendido_final = total_que_sera_rendido * 160
  
        return parseFloat(total_que_sera_rendido_final).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    relatorioTotalSacado(saque) {
      let valor_total = 0
        
        if(saque.length > 0) {
          saque.map(item => {
            valor_total = parseFloat(valor_total) + parseFloat(item.valor)
          })
        }
  
        return parseFloat(valor_total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    
    relatorioTaxaSaque(saque) {
      let valor_total = 0
        
        if(saque.length > 0) {
          saque.map(item => {
            valor_total = parseFloat(valor_total) + parseFloat(item.valor)
          })
        }

        const total_taxa = (valor_total / 100) * 5
  
        return parseFloat(total_taxa).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    filtroTetoCashBackRestante(dados, cotas) {
      let valor_total = 0

      if(dados.length > 0) {
          dados.map(item => {
              if(item.valor > 0) {
                valor_total = parseFloat(valor_total) + parseFloat(item.valor)
              }
          })
      }

      let quantidade = 0
      if(cotas.length > 0) {
        cotas.map(i => {
            if(i.status === 'ativa' || i.status === 'ativando') {
              quantidade += parseInt(i.quantidade)
            }})
      }
      const valor_teto = quantidade * 200

      let valor_final = 0

      const calculo = parseFloat(valor_teto) - parseFloat(valor_total)

      if(calculo <= 0) {
        valor_final = 0
      } else {
        valor_final = calculo
      }

      return valor_final
    }
    
    calculaTetoUsado(extratos_rede, cotas) {
      let valor_ganho_de_rede_atual = 0
        
      if(extratos_rede.length > 0) {
        extratos_rede.map(item => {
          if(item.valor > 0) {
            valor_ganho_de_rede_atual = parseFloat(valor_ganho_de_rede_atual) + parseFloat(item.valor)
          }
        })
      }

      //valor total ok
      let quantidade = 0

      if(cotas.length > 0) {
        cotas.map(i => {
            if(i.status === 'ativa' || i.status === 'ativando') {
              quantidade += parseInt(i.quantidade)
            }})
      }
      const valor_teto = quantidade * 200

      //valor_teto ok

      const valor_final = () => {
        if(valor_ganho_de_rede_atual > valor_teto ) {
          return valor_teto
        } else {
          return valor_ganho_de_rede_atual
        }
      }

      return valor_final()
    }
}

export default Helper