// import Criar from '../../../components/cliente/ordem/Criar'
import Listar  from '../../../components/cliente/ordem/Listar.vue'
import Visualizar from '../../../components/cliente/ordem/Visualizar.vue'

export const ordem_cliente = [
    // {
    //     path: '/cliente/criar-ordem',
    //     component: Criar
    // },
    {
        path: '/cliente/listar-ordem',
        component: Listar
    },
    {
        path: '/cliente/visualizar-ordem/:id',
        component: Visualizar,
        props: true
    },
]

export default {}