<template>
    <div>
        <Molde title="Nova O.S." icon="description" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <div class="expande-horizontal semquebra">
                            <v-autocomplete
                                dense
                                :rules="[v => !!v || 'Selecione um cliente']"
                                class="mr-2 ml-2"
                                :color="$primaria"
                                outlined
                                v-model="cliente_selecionado"
                                :items="cliente"
                                label="Cliente"
                                return-object
                                placeholder="ex: Michel"
                                item-text="nome"
                            />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-autocomplete
                                dense
                                :rules="[v => !!v || 'Selecione um responsável pela ordem']"
                                class="mr-2 ml-2"
                                :color="$primaria"
                                outlined
                                v-model="responsavel"
                                :items="funcionario"
                                label="Funcionário responsável por executar a ordem"
                                return-object
                                placeholder="ex: Ricardo"
                                item-text="nome"
                            />
                        </div>
                        
                        <v-flex xs12>
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.endereco" outlined required label="Endereço" />
                        </v-flex>

                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-mask="['##/##/####']" v-model="dados.data" outlined required label="Dia que a ordem será executada" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-mask="['##:##']" v-model="dados.hora" outlined required label="Hora" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.contato" outlined label="Obs" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.modulos" outlined required label="Quantidade de módulos" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.marca" outlined required label="Marca/Modelo" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.inversores" outlined required label="Inversores" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.stringbox" outlined required label="String Box" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.mppts" outlined required label="Mppt's" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.telhado" outlined required label="Telhado" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.arranjos" outlined required label="Arranjos" />
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.cabeamento" outlined required label="Cabeamento" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.disjuntor" outlined required label="Disjuntor" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.canaleta" outlined required label="Canaleta" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.eletroduto" outlined required label="Eletroduto" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.haste_terra" outlined required label="Haste Terra" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.inclinacao" outlined required label="Inclinação" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.localizacao" outlined required label="Localização" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.condutor" outlined required label="Condutor" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.compras" outlined required label="Compras" />
                        </div>
 
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.saida" outlined required label="Saída" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.chegada" outlined required label="Chegada" />
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.km_saida_adtina" outlined required label="KM saída adtina" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.km_saida_cliente" outlined required label="KM saída cliente" />
                        </div>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.km_chegada_adtina" outlined required label="KM chegada adtina" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.km_chegada_cliente" outlined required label="KM chegada cliente" />
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.horario_saida_adtina" outlined required label="Horário saída adtina" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.horario_saida_cliente" outlined required label="Horário saída cliente" />
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.horario_chegada_adtina" outlined required label="Horário chegada adtina" />
                            <v-text-field dense :color="$primaria" class="mr-2 ml-2" v-model="dados.horario_chegada_cliente" outlined required label="Horário chegada cliente" />
                        </div>

                        <div class="expande-horizontal semquebra">
                            <v-textarea :color="$primaria" class="mr-2 ml-2" v-model="dados.ajudantes" outlined required label="Ajudantes" />
                        </div>

                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Concluir <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    directives: {
        mask
    },
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {},
            cliente: [],
            cliente_selecionado: '',
            funcionario: '',
            responsavel: ''
        }
    },
    watch: {
        cliente_selecionado(val) {
            this.dados.cliente = val
            this.dados.cliente_id = val._id
        },
        responsavel(val) {
            this.dados.responsavel = val
            this.dados.responsavel_id = val._id
        },
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$create('ordem', this.dados)
                this.$router.go(-1)
            }
        },
    },
    async created() {
        this.cliente = await this.$list('cliente')
        this.funcionario = await this.$list('funcionario')
    }
}
</script>

