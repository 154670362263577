<template>
  <Molde title="Despesas Variáveis" :options="options" >
    <template slot="pesquisa_molde">
      <v-flex class="pt-6 px-2" md3>
        <v-text-field outlined dense flat :hint="`${dados.length} encontrados.`" v-model="pesquisa" label="Faça uma busca" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>
    </template>
    <div style="min-height: 90vh" class="pa-2">
      <v-flex v-if="$vuetify.breakpoint.smAndDown" xs12>
        <v-text-field dense :hint="`${dados.length} encontrados.`" v-model="pesquisa" outlined="" label="Faça uma busca" :color="$primaria" append-icon="search"></v-text-field>
      </v-flex>

      <!-- <v-flex xs12>
        <div class="expande-horizontal mb-3">
          <v-flex xs12 md3>
            <v-card style="background: linear-gradient(to right, #11998e, #38ef7d);" dark height="100" outlined>
              <div class="expande-horizontal centraliza coluna" style="height: 100px">
                <h1>{{ dados | total }}</h1>
                <span class="fonteCorpo" style="font-size: 9pt;">TOTAL DESPESA/MÊS</span>
              </div>
            </v-card>
          </v-flex>
        </div>
      </v-flex> -->
      <v-simple-table dense class="item-table" v-if="dados.length > 0 && !$vuetify.breakpoint.smAndDown" >
        <template v-slot:default>
          <thead>
            <tr>
              <!-- <th></th> -->
              <th>NOME</th>
              <th>TIPO</th>
              <th>ÚLTIMO VALOR PAGO</th>
              <th>ÚLTIMO PAGAMENTO</th>
              <!-- <th>VER</th> -->
              <!-- <th>EXCLUIR</th> -->
            </tr>
          </thead>
          <tbody>
            <tr style="cursor: pointer;" @click="$router.push(`/home/visualizar-despesa-variavel/${item._id}`)" v-for="(item, index) in dados" :key="item._id" :class="index % 2 === 0 ? 'bg-table' : '' " class="animated fadeInUp item-table">
              <!-- <td style="width: 20px;" :class="item.selected ? 'bg-selected' : ''">
                <input type="checkbox" x-small color="white" v-model="dados[index].selected" />
              </td> -->

              <td class="font-weight-bold fonteCorpo">{{ item.nome }}</td>
              <td class="font-weight-bold fonteCorpo">{{ item.categoria.nome }}</td>
              <td class="font-weight-bold fonteCorpo green--text"> {{ item.ultimo_valor_pago ? item.ultimo_valor_pago : 'Aguardando' }}</td>
              <td class="font-weight-bold fonteCorpo">
                <span v-if="item.data_ultimo_pagamento">
                  {{ $moment(item.data_ultimo_pagamento, 'x').format('DD/MM/YYYY [às] HH:mm') }}
                </span>
              </td>
              <!-- <td> <v-btn small outlined class="text-lowercase" :color="$primaria" > visualizar</v-btn> </td> -->
              <!-- <td> <v-btn small outlined class="text-lowercase" @click.prevent="remover(item._id, false)"> Excluir</v-btn> </td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Empty v-if="dados.length === 0" ></Empty>
      <v-list three-line class="pa-0 ma-0" v-if="dados.length > 0 && $vuetify.breakpoint.smAndDown">
        <template v-for="(item, index) in dados">
          <v-list-item class="animated fadeInUp item-table" @click="$router.push(`/home/visualizar-despesa-variavel/${item._id}`)" :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonteCorpo" style="color: #444"> {{ item.nome }} </v-list-item-title>
              <v-list-item-subtitle> {{ item.categoria.nome }} </v-list-item-subtitle>
              <v-list-item-subtitle class="green--text font-weight-bold"> {{ item.ultimo_valor_pago ? item.ultimo_valor_pago : 'Aguardando' }} </v-list-item-subtitle>
              <v-list-item-subtitle> Vence dia {{ item.dia_do_vencimento }}. </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <Confirm :open="confirm_dialog" :action="() => this.remover(this.id_to_excluse, true)" :close="() => this.confirm_dialog = false" />
    </div>
  </Molde>
</template>

<script>
export default {
  data() {
    return {
        pesquisa: '',
        options: [
            {
                icon: 'add',
                nome: 'Cadatrar Nova',
                action: () => this.$router.push('/home/criar-despesa-variavel')    
            }
        ],
        dados: [],
        dadosbkp: [],
        confirm_dialog: false,
        id_to_excluse: '',
    }
  },
  watch: {
    pesquisa(val) {
      this.pesquisar(val)
    }
  },
  filters: {
      filterBlock(val) {
        if(parseInt(val) === 1) {
          return 'bloqueado'
        } else {
          return 'ativo'
        }
      },
      filterSaldo(val) {
        return parseFloat(val).toFixed(2)
      },
      total(val) {
        let tot = 0
        val.map(item => {
          const valor = parseFloat(item.valor)
          tot += valor
        })
        return tot.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      }
  },
  methods: {
    async remover(id, confirmacao) {
      if(confirmacao) {
        await this.$del(`despesavariavel/${id}`)
        this.get()
      } else {
        this.confirm_dialog = true
        this.id_to_excluse = id
      }
    },
    pesquisar(val) {
      const filtro = val

      if(val === '') {
        this.dados  = this.dadosbkp
      } else {
        const filtroTotal = this.dadosbkp
        let filtrados = []
        let filtroUpperCase = filtro.toUpperCase()
        filtroTotal.map(item => {
          let dadoUpperCase = item.nome.toUpperCase()
          if (!!dadoUpperCase.match(filtroUpperCase)) {
            filtrados.push(item)
          }
        })
        this.dados = filtrados
      }
    },
    get() {
      this.$store.dispatch('up_charging')

      this.$axios.get("/despesavariavel")
        .then(res => {
          this.dados = res.data
          this.dadosbkp = res.data
          this.$store.dispatch('down_charging')
        })
        .catch(e => {
            this.$store.dispatch('down_charging')
            this.$store.dispatch('snackbar_info', e.response.data.message)
        })
    }
  },
  created() {
    this.get()
  }
}
</script>
