import ListarTicket from '../../../components/home/ticket/Listar.vue'
import VisualizarTicket from '../../../components/home/ticket/Visualizar.vue'

export const suporte_ticket = [
  {
    path: '/home/listar-ticket',
    component: ListarTicket
  },
  {
    path: '/home/visualizar-ticket/:id',
    component: VisualizarTicket,
    props: true
  },
] 

export default {}