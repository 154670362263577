<template>
    <div>
        <Molde title="Novo Equipamento" icon="description" :options="options">
            <div class="expande-horizontal">
                <v-flex xs12 class="pa-3 ">
                    <v-form ref="form">
                        <v-flex xs12>
                            <v-text-field append-icon="description" :color="$primaria" class="mr-2 ml-2" v-model="dados.nome" outlined required  label="Nome" ></v-text-field>
                        </v-flex>
                        
                        <div class="expande-horizontal semquebra">
                            <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.marca" outlined required  label="Marca" ></v-text-field>
                            <v-text-field :color="$primaria" class="mr-2 ml-2" v-model="dados.modelo" outlined required  label="Modelo" ></v-text-field>
                            <v-text-field :color="$primaria" class="mr-2 ml-2" v-mask="['##/##/####']" v-model="dados.aquisicao" outlined required  label="Data da aquisição" ></v-text-field>
                        </div>


                        <v-btn @click="save" class="text-capitalize white--text ml-2" color="green"> Concluir <v-icon>check</v-icon> </v-btn>
                    </v-form>
                </v-flex>
            </div>  
        </Molde>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
    directives: {
        mask
    },
    data() {
        return {
            options: [
                {
                    icon: "save",
                    nome: "Concluir cadastro",
                    action: () => this.save()
                }
            ],
            dados: {},
        }
    },
    methods: {
        async save() {
            if(this.$refs.form.validate()) {
                await this.$create('equipamento', this.dados)
                this.$router.go(-1)
            }
        },
    }
}
</script>

