import CriarTicketCliente from '../../../components/cliente/ticket/Criar.vue'
import ListarTicketCliente from '../../../components/cliente/ticket/Listar.vue'
import VisualizarTicketCliente from '../../../components/cliente/ticket/Visualizar.vue'

export const cliente_ticket = [
  {
    path: '/cliente/criar-ticket-cliente',
    component: CriarTicketCliente
  },
  {
    path: '/cliente/listar-ticket-cliente',
    component: ListarTicketCliente
  },
  {
    path: '/cliente/visualizar-ticket-cliente/:id',
    component: VisualizarTicketCliente,
    props: true
  },
]

export default {}