import Criar from '@/components/home/despesa_categoria/Criar'
import Listar from '@/components/home/despesa_categoria/Listar'
import Visualizar from '@/components/home/despesa_categoria/Visualizar'

export const despesa_categoria_home = [
    {
        path: '/home/criar-despesa-categoria',
        component: Criar
    },
    {
        path: '/home/listar-despesa-categoria',
        component: Listar
    },
    {
        path: '/home/visualizar-despesa-categoria/:id',
        component: Visualizar,
        props: true
    },
]

export default {}